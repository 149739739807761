import React from 'react'
import { injectIntl } from 'react-intl';
import { Button, Divider, Modal, message, Form, Input, Checkbox, Radio } from 'antd';
/**
 * 会员管理页面
 */

class CreateForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;

    return (
      <Form>
        <Form.Item label='角色'>
          {getFieldDecorator('roles', {
          })(
            <Radio.Group options={[
              { label: '不选择', value: '' },
              { label: '管理员', value: 'MANAGER' },
              { label: '操作员', value: 'OPERATOR' },
            ]} />,
          )}
        </Form.Item>
      </Form>
    )
  }

}

const WrappedForm = Form.create({ name: 'form' })(CreateForm);

export default injectIntl(WrappedForm)