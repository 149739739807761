
import MemberList from './index'
import UserDetail from './detail'
import Path from 'routes/path'

const routes = [
    {
        id: 'member_list',
        name: 'member_list',
        component: MemberList,
        path: Path.pathname['MEMBER_LIST'],
        key: 'member_list',
    },
    {
        id: 'user_detail',
        name: 'user_detail',
        component: UserDetail,
        path: Path.pathname['USER_DETAIL'],
        key: 'user_detail',
        parentKey: 'member_list'
    },
]

export default {
    routes
}