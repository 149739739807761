import axios from 'axios'
import { notification, message } from 'antd'
import config from '../config'
import LocalStorageUtil from 'utils/localStorageUtil'
import utils from 'utils/util'
// import 'utils/mock'

let loadingTimer = ''
let instance = null
let setLoadingTimer = isLoading => {
  if (loadingTimer) clearTimeout(loadingTimer)
  loadingTimer = setTimeout(() => {
    if (!isLoading && instance) {
      instance()
      instance = null
    }
    if (isLoading && !instance) {
      instance = message.loading('loading', 0)
    }
  }, 200)
}

axios.interceptors.request.use(config => {
  // if (!config.disableLoading) {
  //   setLoadingTimer(true)
  // }
  let url = config.url
  if (config.params) {
    url += '?'
    for (let key in config.params) {
      if (config.params[key] && config.params[key] instanceof Array) {
        for (let param in config.params[key]) {
          url += key + '=' + encodeURI(config.params[key][param]) + '&'
        }
      } else {
        if (config.params[key] !== undefined) {
          url += key + '=' + encodeURI(config.params[key]) + '&'
        }
      }
    }
    url = url.substring(0, url.length - 1)

    url = url.replace(/%20/g, '+')

    config.noEncode && (url = url.replace(/"/g, '%22').replace(/'/g, '%27'))
  }

  config.headers['content-type'] = 'application/json'

  var token = new LocalStorageUtil().getAuth()
  if (!token && utils.getCookie('token')) {
    token = decodeURIComponent(utils.getCookie('token'))
  }
  // config.headers['Authorization'] = 'Basic bWFpZGluZzpTIzQxNTRiVW5zeWg='
  config.headers['Authorization'] = token // 'Bearer "0123456789ABCDEF"'

  return Promise.resolve(config)
}, function (error) {
  setLoadingTimer(false)
  return Promise.reject(error)
})

axios.interceptors.response.use(response => {
  setLoadingTimer(false)
  const token = response.headers['x-auth-token']
  const privileges = response.headers['x-auth-privilege']
  if (privileges) {
    new LocalStorageUtil().setPrivileges(privileges)
  }
  if (token) {
    new LocalStorageUtil().setAuth(token)
    var cookie_token = token
    new LocalStorageUtil().setCookie('token', cookie_token)
  }
  return response
}, (error) => {
  setLoadingTimer(false)
  if (error.response && error.response.status === 401) {
    ['auth'].forEach(i => {
      new LocalStorageUtil().clearStorage(i)
    })
    window.location.href = '/#/login'
  } else if (error.response && error.response.status === 403) {
    window.location.href = '/#/mkt/403'
  } else if (error.response && error.response.data && error.response.data.message) {
    if (!(error.config && error.config.ignoreErrorCodes && error.config.ignoreErrorCodes.indexOf(error.response.data.code) !== -1)) {
      // notification.error({ message: 'Tips' || error.response.status, description: error.response.data.message, duration: 3 })
    }
  }
  return Promise.reject(error)
})

var init = function (config) {
  axios.defaults.baseURL = config.env === 'localhost' ? config.api_url : ''
}

// http拦截
init(config || window.config || {})
