import React from 'react';
import echarts from 'echarts/lib/echarts';

import 'echarts/lib/component/title';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/legend';
import 'echarts/lib/component/graphic';
import 'echarts/lib/chart/line';
import 'echarts/lib/chart/pie';
import 'echarts/lib/chart/bar';

class ReactEcharts extends React.Component {

  getLatestSevenDays = () => {
    const temp = [];
    for (let i = 0; i < 7; i++) {
      const time = new Date(new Date().setDate((new Date().getDate() + i) - 7));
      const year = time.getFullYear();
      const month = `0${time.getMonth() + 1}`.slice(-2);
      const strDate = `0${time.getDate()}`.slice(-2);
      temp.push(`${year}-${month}-${strDate}`)
    }
    return temp;
  }

  componentDidMount() {
    this.myChart = echarts.init(this.chartContainer);
    this.initCharts(this.props.options)

    window.addEventListener("resize", () => {
      this.myChart.resize()
    })

  }

  initCharts = (options) => {
    this.myChart.setOption(options, true)
  }

  componentWillReceiveProps(nextProps) {
    this.initCharts(nextProps.options)
  }

  render() {
    const { style } = this.props
    return (
      <div>
        <div ref={e => this.chartContainer = e} style={Object.assign({}, { width: '100%', height: 600 }, style)} />
      </div>
    )
  }

}

export default ReactEcharts