import React from 'react'
import { injectIntl } from 'react-intl';
import { Form, Card, Tag } from 'antd';
import SearchType from 'utils/searchType';
import DictUtil from 'utils/dictUtil';
import Util from 'utils/util';
import ParamsDetail from 'components/ParamsDetail'

class DetailForm extends React.Component {

  _rebuildFields = (fields) => {
    return fields.filter(field => field.name !== 'action' && field.name !== 'log' && field.name !== 'monitor' && field.name !== 'key' && !field.hideInDetail)
  }

  _renderText = (field, record) => {
    let type = field.type || ''
    let name = field.name || ''

    if (name === 'real_name') {
      let text = Util.getRecordValue(record, name)
      return text || record.nick_name || record.user_id
    }
    if (name === 'user.real_name') {
      let text = Util.getRecordValue(record, name)
      return text || record.user.nick_name || record.user.id
    }

    if (field.render) {
      return field.render(record[name], record)
    }

    if (type && type.indexOf(SearchType.KEY_COMMON_ENMU) !== -1) {
      const Dict = new DictUtil().getDict(this.props)
      let dictName = type.split('-')[1];
      let dict = Dict[dictName]
      let tmpValues = [];
      let tempText = String(Util.getRecordValue(record, name));
      if (tempText) {
        let values = tempText.split(',');
        values.forEach(value => {
          for (let index in dict) {
            if (value === String(dict[index].id)) {
              tmpValues.push(dict[index].name);
              break;
            }
          }
        })
      }
      return tmpValues.length === 0 ? '-' : <span>
        {field.showTag ? tmpValues.map((val, index) => <Tag color='blue' key={index}>{val}</Tag>) : tmpValues.join(',')}
      </span>;
    }

    if (type === 'running_time') {
      return Util.runtimeFormat(record.runtime_info && record.runtime_info.start_time, record.runtime_info && record.runtime_info.end_time, true)
    }

    if (type === SearchType.KEY_DATE) {
      let text = Util.getRecordValue(record, name)
      return Util.timeFormat(text, type);
    }

    if (type === SearchType.KEY_DATE_TIME) {
      let text = Util.getRecordValue(record, name)
      return Util.timeFormat(text, type);
    }

    if (type === SearchType.KEY_CUSTOM_OPTION) {
      return field.options.find(i => i.value === String(Util.getRecordValue(record, name)))
        ? field.options.find(i => i.value === String(Util.getRecordValue(record, name))).name
        : '-'
    }

    if (name === 'size') {
      return Util.sizeFormat(Util.getRecordValue(record, name))
    }

    return Util.getRecordValue(record, name)
  }

  render() {
    const formItemLayout = {
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
    };
    const { record = {}, fields = [] } = this.props;
    return (
      <Form {...formItemLayout} size="small">
        {record.job_param || record.builtin_job_param ? this._renderParamsInfo(record) : this._renderBasicItems()}
      </Form>
    );
  }

  _renderBasicInfo = () => {
    const { intl, fields, record = {} } = this.props
    const { formatMessage } = intl
    return (
      <div>
        <Card size='small' title={formatMessage({ id: 'base_info' })} bordered={false}>
          {this._renderBasicItems()}
        </Card>
      </div>
    )
  }

  _renderBasicItems = () => {
    const { intl, fields, record = {} } = this.props
    const { formatMessage } = intl
    return (
      <Card size='small' bordered={false}>
        {this._rebuildFields(fields)
          // .filter(field => field.hide)
          .map((field, index) => {
            const text = this._renderText(field, record)
            return (<Form.Item key={index} label={field.desc} style={{ marginBottom: 0 }}>
              {
                field.render && field.showRender
                  ? field.render(Util.getRecordValue(record, field.name), record)
                  : <span className="ant-form-text">{text === 0 ? 0 : (text || '-')}</span>
              }
            </Form.Item>)
          })}
      </Card>
    )

  }

  _renderParamsInfo = (values) => {
    const { intl } = this.props;
    const { formatMessage } = intl;
    return (
      <div>
        {this._renderBasicInfo()}
        <Card size='small' title={formatMessage({ id: 'parameter_setting' })} bordered={false}>
          {this._renderParamsItems(values)}
        </Card>
      </div >
    )
  }

  _renderParamsItems = (values) => {
    return <ParamsDetail {...this.props} values={values} />
  }

}

const WrappedDetailForm = Form.create({ name: 'detail_form' })(DetailForm);

export default injectIntl(WrappedDetailForm)