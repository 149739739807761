import React from 'react'
import { injectIntl } from 'react-intl';
import { Button, Modal, message } from 'antd';
import service from '../../service'
import CreateNewForm from '../../formCreate'

/**
 * 已预约列表页面
 */

class Reservations extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isReload: false,
      visible: false,
      createVisible: false,
    }
  }

  render() {
    return (
      <div>
        <Button type='primary' key='create' onClick={() => this.handleShowCreate()}>添加无预约患者消费记录</Button>
        {this.renderCreateModal()}
      </div>
    )
  }

  handleShowCreate = () => {
    this.setState({ createVisible: true })
  }

  handleCreateFormRef = formRef => {
    this.createFormRef = formRef;
  };

  renderCreateModal = () => {
    const { intl } = this.props;
    const { formatMessage } = intl;
    return (
      <Modal
        width={650}
        title='添加无预约患者消费记录'
        visible={this.state.createVisible}
        onOk={this.handleCreateOk}
        onCancel={this.handleCreateCancel}
      >
        <CreateNewForm wrappedComponentRef={this.handleCreateFormRef} {...this.props} />
      </Modal>
    )
  }

  handleCreateOk = () => {
    const { callback } = this.props
    const { record } = this.state
    this.createFormRef.props.form.validateFields((err, values) => {
      console.log('values: ', values);
      // return
      if (!err) {
        service.saveNoReservationIncomes(values).then(data => {
          message.success('记录消费成功')
          this.setState({ isReload: true })
          this.handleCreateCancel()
          callback && callback()
        })
      }
    })
  }

  handleCreateCancel = () => {
    this.setState({ createVisible: false, record: {} })
    this.createFormRef.props.form.resetFields()
  }
}

export default injectIntl(Reservations)