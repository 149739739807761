import React from 'react'
import { injectIntl } from 'react-intl';
import moment from 'moment';
import { Button, Divider, Modal, Tag } from 'antd';
import TablePage from 'components/TablePage'
import TextMore from 'components/TextMore'
import SearchType from 'utils/searchType'
import service from 'modules/Partner/service'
import productCategoryService from 'modules/ProductCategory/service'
import Path from 'routes/path'

/**
 * 商品管理页面
 */

class Product extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isReload: false,
    }
  }

  componentDidMount() {
  }

  render() {
    const { intl } = this.props;
    const { formatMessage } = intl;
    const { isReload } = this.state;

    const fields = [
      { name: 'key', desc: '', render: (text, record, index) => index + 1, width: 50 },
      {
        name: 'user.real_name', desc: '姓名/电话', query: true, key: 'keyword',
        render: (text, record) => {
          return (
            <>
              {text || record.user.nick_name || record.user.id}
              <span style={{ padding: '0 10px' }}></span>
              {record.user && record.user.phone ? <Tag>{record.user && record.user.phone}</Tag> : null}
            </>
          )
        }, showRender: true
      },
      {
        name: 'super_partner.real_name', desc: '上级志愿者',
        render: (text, record) => text || (record.super_partner && (record.super_partner.nick_name || record.super_partner.id)), showRender: true
      },
      { name: 'join_time', desc: formatMessage({ id: 'join_time' }), type: SearchType.KEY_DATE_TIME, sorter: true },
    ]

    const insertBtns = []
    return (
      <div>
        {/* <div className="content-header">
          <h2>{formatMessage({ id: 'partner_list' })}</h2>
        </div> */}
        <div>
          <TablePage
            {...this.props}
            size='small'
            rowKey={(record, index) => `${record.user_id}_${index}`}
            name={formatMessage({ id: 'member' })}
            fields={fields}
            insertBtns={insertBtns}
            {...service}
            isReload={isReload}
            rowSelection={{
              type: 'radio'
            }}
          />
        </div>
      </div>
    )
  }

}

export default injectIntl(Product)