import React from 'react';
import { Spin, Icon } from 'antd';
import LocalStorageUtil from 'utils/localStorageUtil'
import './index.css';

/**
 * loading页面
 */

class LoadingPage extends React.Component {

  componentWillMount() {
    const auth = new LocalStorageUtil().getAuth()
    this.props.history && this.props.history.push('/')
  }

  componentWillReceiveProps(nextProps) {
    const auth = new LocalStorageUtil().getAuth()
    nextProps.history && nextProps.history.push('/')
  }

  render() {
    const icon = <Icon type="loading" style={{ fontSize: 50 }} spin />;
    return (
      <div className='loading-page'>
        <div className='loading-icon'>
          <Spin indicator={icon} />
        </div>
      </div>
    )
  }

}

export default LoadingPage