const pathname = {
  HOME: '/home',
  LOG: '/log',
  USER: '/user',
  Error403: '/403',
  Error404: '/404',
  Error500: '/500',
  USERS: '/users',
  USER_DETAIL: '/user_detail',
  ORDER: '/order',
  ORDER_STATISTICS: '/order_statistics',
  COUPON: '/coupon',
  MEMBER_LIST: '/member_list',
  MEMBER_PLAN: '/member_plan',
  MEMBER_BENEFITS: '/member_benefits',
  MEMBER_STATISTICS: '/member_statistics',

  PRODUCT_LIST: '/product_list',
  PRODUCT_CREATE: '/product_create',
  PRODUCT_EDIT: '/product_edit',
  PRODUCT_CATEGORY: '/product_category',

  POINT_LIST: '/point_list',
  POINT_CREATE: '/point_create',
  POINT_EDIT: '/point_edit',
  POINT_RECORD: '/point_record',
  POINT_STATISTICS: '/point_statistics',
  POINT_RULES: '/point_rules',

  USER_POINT: '/user_point',
  SPREAD_STATISTICS: '/spread_statistics',

  NEWS_LIST: '/news_list',
  NEWS_CREATE: '/news_create',
  NEWS_EDIT: '/news_edit',
  NEWS_DETAIL: '/news_detail',
  NEWS_CATEGORY: '/news_category',

  USER_LIST: '/user_list',
  RESERVATION: '/reservation_list',
  RESERVATION_LATEST: '/reservation_latest_list',
  PARTNER: '/partner',
  INCOMES: '/incomes',
  PARTNER_INCOMES: '/partner_incomes',

  PRE_RESERVATION: '/pre_reservation',
}

const getPathname = (props, propsPath) => {
  const { location = {} } = props
  const { pathname } = location
  if (pathname && pathname.indexOf('/mkt') !== -1) {
    return `/mkt${propsPath}`
  } else {
    return propsPath
  }
}

export default {
  pathname,
  getPathname
}