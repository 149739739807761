import React from 'react'
import { injectIntl } from 'react-intl';
import moment from 'moment';
import { Button, Divider, Modal, message } from 'antd';
import TablePage from 'components/TablePage'
import TextMore from 'components/TextMore'
import SearchType from 'utils/searchType'
import service from 'modules/UserList/service'
import productCategoryService from 'modules/ProductCategory/service'
import Path from 'routes/path'

/**
 * 商品管理页面
 */

class Product extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isReload: false,
    }
  }

  componentDidMount() {
  }

  render() {
    const { intl } = this.props;
    const { formatMessage } = intl;
    const { isReload } = this.state;

    const fields = [
      { name: 'key', desc: '', render: (text, record, index) => index + 1, width: 50 },
      { name: 'real_name', desc: formatMessage({ id: 'real_name' }), showDetail: true, form: { type: 'input' } },
      // { name: 'first_recommend_user', desc: formatMessage({ id: 'first_recommend_user' }), form: { type: 'input' } },
      // { name: 'second_recommand_user', desc: formatMessage({ id: 'second_recommand_user' }), form: { type: 'input' } },
      { name: 'register_time', desc: formatMessage({ id: 'register_time' }), type: SearchType.KEY_DATE_TIME },
      // {
      //   name: 'member_join_time', desc: formatMessage({ id: 'indate' }), showRender: true,
      //   render: (text, record) => `${moment(text).format('YYYY/MM/DD')} - ${moment(record.member_expire_time).format('YYYY/MM/DD')}`
      // },
    ]

    const insertBtns = []
    return (
      <div>
        {/* <div className="content-header">
          <h2>{formatMessage({ id: 'user_list' })}</h2>
        </div> */}
        <TablePage
          {...this.props}
          size='small'
          rowKey={(record, index) => `${record.id}_${index}`}
          name={formatMessage({ id: 'user' })}
          fields={fields}
          insertBtns={insertBtns}
          {...service}
          isReload={isReload}
          rowSelection={{
            type: 'radio'
          }}
        />
      </div>
    )
  }

}

export default injectIntl(Product)