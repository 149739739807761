
import Index from './index'
import Path from 'routes/path'

const routes = [
    {
        id: 'reservation_latest',
        name: 'reservation_latest',
        component: Index,
        path: Path.pathname['RESERVATION_LATEST'],
        key: 'reservation_latest',
    },
]

export default {
    routes
}