import React, { useState } from 'react'
import { injectIntl } from 'react-intl';
import { Button, Divider, Modal, message, Input } from 'antd';
import TablePage from 'components/TablePage'
import TextMore from 'components/TextMore'
import SearchType from 'utils/searchType'
import service from './service'
import Path from 'routes/path'

/**
 * 会员管理页面
 */

class Monitor extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isReload: false,
    }
  }

  onSaveCb = () => {
    this.setState({
      isReload: true
    }, () => {
      this.setState({
        isReload: false
      })
    })
  }

  render() {
    const { intl } = this.props;
    const { formatMessage } = intl;
    const { isReload } = this.state;
    const fields = [
      { name: 'key', desc: '', render: (text, record, index) => index + 1, width: 50 },
      { name: 'name', desc: formatMessage({ id: 'name' }), showDetail: true, width: '50%' },
      // { name: 'channel', desc: formatMessage({ id: 'code' }) },
      { name: 'point', desc: formatMessage({ id: 'point' }), render: (text, record) => <EditPoint value={text} record={record} onSaveCb={this.onSaveCb} />, showRender: true },
    ]

    const dataSource = [
      { name: '每日签到', channel: 'DAILY_CHECK_IN', point: 0 },
      { name: '连续签到', channel: 'UNINTERRUPTED_CHECK_IN', point: 0 },
      { name: '购买会员计划', channel: 'MEMBER_PLAN_PURCHASE', point: 0, unit: '元/积分' },
      { name: '购买商品', channel: 'PRODUCT_PURCHASE', point: 0, unit: '元/积分' },
      { name: '注册赠送', channel: 'REGISTRATION', point: 0 },
      { name: '推荐用户', channel: 'RECOMMEND_USER', point: 0 },
    ]

    const insertBtns = []
    return (
      <div>
        <div className="content-header">
          <h2>{formatMessage({ id: 'point_rules' })}</h2>
        </div>
        <div className="content-body">
          <TablePage
            rowKey='id'
            name={formatMessage({ id: 'point_rules' })}
            dataSource={dataSource}
            fields={fields}
            insertBtns={insertBtns}
            {...service}
            loadData={service.getDatas}
            isReload={isReload}
          />
        </div>
      </div>
    )
  }

}

const EditPoint = (props) => {
  const { value, record, onSaveCb } = props
  const [editable, setEditable] = useState(false)
  const [val, setVal] = useState(value)
  const onChange = (e) => {
    setVal(e.target.value)
  }
  const onSave = () => {
    service.saveData({
      "channel": record.channel,
      "id": record.id,
      "point": val
    }).then(data => {
      setEditable(false)
      onSaveCb && onSaveCb()
    })
  }
  return editable
    ? <>
      <Input.Group compact>
        <Input onChange={onChange} value={val} style={{ width: 200 }} />
        <Button onClick={onSave}>保存</Button>
        <Button onClick={() => setEditable(false)}>取消</Button>
      </Input.Group>
    </>
    : <span onClick={() => setEditable(true)}>{value + (record.unit || '积分')}</span>
}

export default injectIntl(Monitor)