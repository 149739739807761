let SearchType = {}

/**
 * 类型标记：云服务提供商类型
 */
SearchType.KEY_CLOUD_PRIVIDER_TYPE = 'dic-CloudProviderType';

/**
 * 类型标记：开发环境状态
 */
SearchType.KEY_JOB_STATUS = 'dic-JobStatus';

/**
 * 类型标记：开发调测环境状态
 */
SearchType.KEY_DEV_KEV_STATUS = 'dic-JobStatus';

/**
 * 类型标记：充值状态
 */
SearchType.KEY_CHARGE_STATUS = 'dic-CHARGE_STATUS';

/**
 * 类型标记：Nodebook类型
 */
SearchType.KEY_NOTEBOOK_TYPE = 'dic-DevEnvType';

/**
 * 类型标记：VisualToolType类型
 */
SearchType.KEY_VISUAL_TOOL_TYPE = 'dic-VisualToolType';

/**
 * 类型标记：SourceType类型
 */
SearchType.KEY_SOURCE_TYPE = 'dic-SourceType';

/**
 * 类型标记：AppScenarioType类型
 */
SearchType.KEY_APP_SCENARIO_TYPE = 'dic-AppScenarioType';

/**
 * 类型标记：StorageType类型
 */
SearchType.KEY_STORAGE_TYPE = 'dic-StorageType';

/**
 * 类型标记：EngineType类型
 */
SearchType.KEY_ENGINE_TYPE = 'dic-EngineType';

/**
 * 类型标记：DeviceType类型
 */
SearchType.KEY_DEVICE_TYPE = 'dic-DeviceType';

/**
 * 类型标记：RenderType类型
 */
SearchType.KEY_RENDER_TYPE = 'dic-RenderType';

/**
 * 类型标记：JobType类型
 */
SearchType.KEY_JOB_TYPE = 'dic-JobType';

/**
 * 类型标记：ScheduleType类型
 */
SearchType.KEY_SCHEDULE_TYPE = 'dic-ScheduleType';

/**
 * 类型标记：DevEnvType类型
 */
SearchType.KEY_DEV_ENV_TYPE = 'dic-DevEnvType';

/**
 * 类型标记：TrainingJobType类型
 */
SearchType.KEY_TRAINING_JOB_TYPE = 'dic-TrainingJobType';

/**
 * 类型标记：InferenceSvcType类型
 */
SearchType.KEY_INFERENCE_SVC_TYPE = 'dic-InferenceSvcType';

/**
 * 类型标记：支付状态
 */
SearchType.KEY_PAY_TYPE = 'dic-PAY_TYPE';

/**
 * 类型标记：使用量类型
 */
SearchType.KEY_USAGE_TYPE = 'dic-USAGE_TYPE';

/**
 * 类型标记：日期
 */
SearchType.KEY_DATE = 'date';

/**
 * 类型标记：日期范围
 */
SearchType.KEY_DATE_RANGE = 'date_range';

/**
 * 类型标记：时间范围
 */
SearchType.KEY_DATE_TIME_RANGE = 'date_time_range';

/**
 * 类型标记：自定义select
 */
SearchType.KEY_CUSTOM_OPTION = 'custom_option';

/**
 * 类型标记：自定义tabs
 */
SearchType.KEY_CUSTOM_TABS = 'custom_tabs';

/**
 * 类型标记：日期(yyyy-MM-dd hh:mm:ss)
 */
SearchType.KEY_DATE_TIME = 'date_time';

SearchType.KEY_COMMON_ENMU = 'dic-'

export default SearchType