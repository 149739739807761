import React from 'react'
import { injectIntl } from 'react-intl';
import moment from 'moment';
import { Button, Divider, Modal, message } from 'antd';
import TablePage from 'components/TablePage'
import SearchType from 'utils/searchType'
import service from './service'
import MemberPlanService from '../MemberLevel/service'
import Path from 'routes/path'

/**
 * 会员管理页面
 */

class Monitor extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isReload: false,
      options: []
    }
  }

  componentDidMount() {
    MemberPlanService.getDatas().then(data => {
      this.setState({
        options: data.data.map(i => ({ name: i.name, value: i.id }))
      })
    })
  }

  render() {
    const { intl } = this.props;
    const { formatMessage } = intl;
    const { isReload, options } = this.state;

    const fields = [
      { name: 'key', desc: '', render: (text, record, index) => index + 1, width: 50 },
      {
        name: 'member_plan.id', desc: formatMessage({ id: 'member_plan' }), query: true, key: 'member_plan_id', type: SearchType.KEY_CUSTOM_OPTION,
        options, form: { type: 'select' }
      },
      { name: 'real_name', desc: formatMessage({ id: 'buyer' }), showDetail: true, form: { type: 'input' } },
      // { name: 'first_recommend_user', desc: formatMessage({ id: 'first_recommend_user' }), form: { type: 'input' } },
      // { name: 'second_recommand_user', desc: formatMessage({ id: 'second_recommand_user' }), form: { type: 'input' } },
      { name: 'register_time', desc: formatMessage({ id: 'register_time' }), type: SearchType.KEY_DATE_TIME },
      {
        name: 'member_join_time', desc: formatMessage({ id: 'indate' }), showRender: true,
        render: (text, record) => `${moment(text).format('YYYY/MM/DD')} - ${moment(record.member_expire_time).format('YYYY/MM/DD')}`
      },
      {
        name: 'action', desc: formatMessage({ id: 'action' }), width: 250, listActions: true,
        render: (text, record) => {
          return [
            // <a>{record.status === formatMessage({ id: 'enable' }) ? formatMessage({ id: 'disable' }) : formatMessage({ id: 'enable' })}</a>
          ]
        }
      },
    ]

    const insertBtns = []
    return (
      <div>
        <div className="content-header">
          <h2>{formatMessage({ id: 'member_list' })}</h2>
        </div>
        <div className="content-body">
          <TablePage
            rowKey={(record, index) => `${record.user_id}_${index}`}
            name={formatMessage({ id: 'member' })}
            fields={fields}
            insertBtns={insertBtns}
            {...service}
            // loadData={service.getDatas}
            isReload={isReload}
            // showCreate
            // showEdit
            showDelete
          />
        </div>
      </div>
    )
  }

}

export default injectIntl(Monitor)