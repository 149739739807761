import React from 'react';
import { Upload, Icon, message, Modal } from 'antd';
import service from './service';

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

class UploadCover extends React.Component {

  state = {
    loading: false,
    upConfig: {},
    fileList: [],
    previewVisible: false,
    previewImage: '',
  };

  componentDidMount() {
    this.setState({
      fileList: this.props.value ? this.props.value.map((i, d) => {
        const nameArr = i.split('/')
        return {
          uid: d + 1,
          name: nameArr[nameArr.length - 1],
          status: 'done',
          url: i
        }
      }) : []
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value) {
      const { fileList } = this.state
      this.setState({
        fileList: nextProps.value.map((i, d) => {
          const nameArr = i.split('/')
          return {
            uid: fileList.find(j => j.url === i) ? fileList.find(j => j.url === i).uid : d + 1,
            name: nameArr[nameArr.length - 1],
            status: 'done',
            url: i
          }
        })
      })
    }
  }

  fetchUploadToken = async (file) => {
    const res = await service.getToken()
    if (res.data.token) {
      this.setState({
        uploadToken: res.data.token,
        fileKey: file.name,
        upConfig: res.data
      })
    }
  }

  getUploadToken = () => {
    const { fileKey } = this.state
    const arr = fileKey.split('.')
    const ext = arr[arr.length - 1]
    const rnd = +new Date() + '_' + Math.random().toString(36).slice(-6)
    return {
      token: this.state.uploadToken,
      key: [rnd, ext].join('.')
    }
  }

  // getBase64 = (img, callback) => {
  //   const reader = new FileReader();
  //   reader.addEventListener('load', () => callback(reader.result));
  //   reader.readAsDataURL(img);
  // }

  beforeUpload = async (file) => {
    let tag = false;
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('请选择JPG，PNG文件！');
      return tag;
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error('图片大小不能超过5MB！');
      return tag;
    }
    tag = isJpgOrPng && isLt2M;
    await this.fetchUploadToken(file)
    tag = true;
    return tag;
  }

  handleChange = (info) => {
    let { upConfig, fileList } = this.state;
    const { schema, domain, bucket, token, provider } = upConfig || {}
    const { onChange } = this.props
    if (info.file.status === 'uploading') {
      this.setState({
        loading: true,
        fileList: info.fileList
      });
    }
    if (info.file.status === 'done') {
      const imageUrl = `${schema}://${domain}/${info.file.response.key}`
      fileList = fileList.map(i => {
        if (i.uid === info.file.uid)
          i.url = imageUrl
        return i
      })
      this.setState({
        loading: false,
        fileList: fileList
      }, () => {
        onChange && onChange(this.state.fileList.map(i => i.url))
      })
    }
    if (info.file.status === 'removed') {
      this.setState({
        fileList: info.fileList
      }, () => {
        onChange && onChange(this.state.fileList.map(i => i.url))
      });
    }
  };

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  };

  render() {
    const { intl } = this.props;
    const { formatMessage } = intl;
    const { imageUrl, loading, upConfig, previewVisible, previewImage, fileList } = this.state;
    const { schema, domain, bucket, token, provider } = upConfig || {}
    const uploadButton = (
      <div>
        <Icon type={loading ? 'loading' : 'plus'} />
        <div className="ant-upload-text">{formatMessage({ id: 'upload' })}</div>
      </div>
    );
    return (
      <>
        <Upload
          // name="cover"
          listType="picture-card"
          className="avatar-uploader"
          // action={`${schema}://${domain}/${bucket}`}
          fileList={fileList}
          action="https://up.qbox.me"
          data={() => this.getUploadToken()}
          beforeUpload={this.beforeUpload}
          onChange={this.handleChange}
          onPreview={this.handlePreview}
          accept=".png, .jpeg, .jpg"
        >
          {fileList.length >= 5 ? null : uploadButton}
        </Upload >
        <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </>
    )
  }

}

export default UploadCover;