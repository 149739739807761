import React from 'react'
import { injectIntl } from 'react-intl';
import { Table } from 'antd';
import service from './service'
import { useState, useEffect } from 'react';

/**
 * 分享医疗志愿者管理页面
 */
const Incomes = (props) => {
  const { record } = props;
  const { user } = record || {};
  const { id } = user || {}
  const { intl } = props;
  const { formatMessage } = intl;
  const [dataSource, setDataSource] = useState([])

  const columns = [
    { title: formatMessage({ id: 'income' }), dataIndex: 'income', key: 'income' },
    { title: formatMessage({ id: 'income_date' }), dataIndex: 'date', key: 'date' },
  ]

  useEffect(() => {
    const fetchData = async () => {
      const res = await service.getIncomes(id)
      setDataSource(res.data.map((i, d) => ({ ...i, key: d })))
    }
    if (!id) return
    fetchData()
  }, [id])

  return (
    <>
      <Table
        size='small'
        dataSource={dataSource}
        columns={columns}
        pagination={false}
      />
    </>
  )
}


export default injectIntl(Incomes)