import React from 'react'
import { Form } from 'antd';
import Util from 'utils/util';

class ParamsDetail extends React.Component {

  render() {
    const { values } = this.props;
    return (
      <div>
        {this._renderValues(values)}
      </div>
    );
  }

  _renderValues = (values) => {
    const { intl } = this.props;
    const { formatMessage } = intl;
    const fields = [
      { id: 'dataset_source', key: 'dataset_source' },
      { id: 'algorithm_source', key: 'algorithm_source' },
      { id: 'cmd_line_parameter', key: 'cmd_params' },
      { id: 'env_var', key: 'env_variables', type: 'obj' },
      { id: 'train_output_path', key: 'output_url' },
      { id: 'job_log_path', key: 'log_config.log_url' },
      { id: 'resource_pool', key: 'resource_config.resource_pool_display_name' },
      // { id: 'node_num', key: 'resource_config.node_count' }
    ]
    return (
      <div>
        {fields.map((field, index) => {
          var value = Util.getRecordValue(values, field.key)
          value = this._handleSpecialField(field, values, value)
          return <Form.Item key={index} label={formatMessage({ id: field.id })} style={{ marginBottom: 0 }}>{value}</Form.Item>
        })}
      </div>
    )
  }

  _handleSpecialField = (field, values = {}, value) => {
    const id = field.id
    if (!id) return
    switch (id) {
      case 'dataset_source':
        const datasetType = values.dataset && values.dataset.source_type
        return this._handleDatasetField(datasetType, values)
      case 'algorithm_source':
        const algorithmType = values.algorithm && values.algorithm.source_type
        return this._handleAlgorithmField(algorithmType, values)
      case 'cmd_line_parameter':
        const argumentsType = values.program_config && values.program_config.arguments_type
        return this._handleCmdLineField(argumentsType, values)
      case 'env_var':
        let env_var_map = values.program_config && values.program_config.env_var_map
        let env_var_arr = []
        for (var i in env_var_map) {
          env_var_arr.push(`--${i}=${env_var_map[i]}`)
        }
        return env_var_arr.join(' ')
      default:
        return value
    }
  }

  _handleDatasetField = (type, values) => {
    const { intl } = this.props;
    const { formatMessage } = intl;
    const { dataset = {} } = values
    if (!type) return
    return dataset.dataset_url
  }

  _handleAlgorithmField = (type, values) => {
    const { intl } = this.props;
    const { formatMessage } = intl;
    const { algorithm } = values
    return (
      <div>
        <p style={{ marginBottom: 0 }}>{[
          formatMessage({ id: 'save_path' }),
          algorithm.algorithm_url].join(' ')}</p>
        <p style={{ marginBottom: 0 }}>{[formatMessage({ id: 'startup_file' }),
        algorithm.algorithm_boot_file
        ].join(' ')}</p>
      </div>
    )
  }

  _handleCmdLineField = (type, values) => {
    const { intl } = this.props;
    const { formatMessage } = intl;
    const { program_config } = values
    switch (type) {
      case 3:
        let arguments_map = program_config.arguments_map
        let arguments_arr = []
        for (var i in arguments_map) {
          arguments_arr.push(`--${i}=${arguments_map[i]}`)
        }
        return [
          // formatMessage({ id: 'ArgumentsType_KeyValuePair' }),
          arguments_arr.join(' ')
        ].join(' ')
      case 1:
        return [
          // formatMessage({ id: 'ArgumentsType_String' }),
          program_config.arguments_string].join(' ')
      case 2:
        return [
          // formatMessage({ id: 'ArgumentsType_Array' }),
          (program_config.arguments_array || []).join(' ')].join(' ')
      default:
        return ''
    }
  }
}

export default ParamsDetail
