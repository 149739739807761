import 'braft-editor/dist/index.css'
import './index.css'
import React from 'react'
import BraftEditor from 'braft-editor'
import { ContentUtils } from 'braft-utils'
import { ImageUtils } from 'braft-finder'
import { Upload, Icon } from 'antd'
import UploadImage from './components/UploadImage'
import UploadVideo from './components/UploadVideo'

export default class BasicDemo extends React.Component {

  state = {
    editorState: BraftEditor.createEditorState(''), // 设置编辑器初始内容
    outputHTML: '<p></p>'
  }

  componentDidMount() {
    this.isLivinig = true
    // 3秒后更改编辑器内容
    setTimeout(this.setEditorContentAsync, 1000)
  }

  componentWillUnmount() {
    this.isLivinig = false
  }

  handleChange = (editorState) => {
    const { onChange } = this.props;
    this.setState({
      editorState: editorState,
      outputHTML: editorState.toHTML()
    })
    onChange && onChange(editorState.toHTML())
  }

  setEditorContentAsync = () => {
    this.isLivinig && this.setState({
      editorState: BraftEditor.createEditorState(this.props.value)
    })
  }

  uploadHandler = (url, type) => {

    // if (!param.file) {
    //   return false
    // }

    this.setState({
      editorState: ContentUtils.insertMedias(this.state.editorState, [{
        type: type || 'IMAGE',
        url: url // URL.createObjectURL
      }])
    })

  }

  preview = () => {

    if (window.previewWindow) {
      window.previewWindow.close()
    }

    window.previewWindow = window.open()
    window.previewWindow.document.write(this.buildPreviewHtml())
    window.previewWindow.document.close()

  }

  buildPreviewHtml() {

    return `
      <!Doctype html>
      <html>
        <head>
          <title>Preview Content</title>
          <style>
            html,body{
              height: 100%;
              margin: 0;
              padding: 0;
              overflow: auto;
              background-color: #f1f2f3;
            }
            .container{
              box-sizing: border-box;
              width: 1000px;
              max-width: 100%;
              min-height: 100%;
              margin: 0 auto;
              padding: 30px 20px;
              overflow: hidden;
              background-color: #fff;
              border-right: solid 1px #eee;
              border-left: solid 1px #eee;
            }
            .container img,
            .container audio,
            .container video{
              max-width: 100%;
              height: auto;
            }
            .container p{
              white-space: pre-wrap;
              min-height: 1em;
            }
            .container pre{
              padding: 15px;
              background-color: #f1f1f1;
              border-radius: 5px;
            }
            .container blockquote{
              margin: 0;
              padding: 15px;
              background-color: #f1f1f1;
              border-left: 3px solid #d1d1d1;
            }
          </style>
        </head>
        <body>
          <div class="container">${this.state.editorState.toHTML()}</div>
        </body>
      </html>
    `

  }

  render() {

    const { editorState, outputHTML } = this.state
    const controls = [
      // 'bold',
      // 'italic',
      // 'underline',
      // 'text-color',
      // // 'separator',
      // // 'link',
      // 'separator',
      // // 'media'
    ];
    const excludeControls = [
      // 'font-size',
      // 'font-family',
      // 'line-height',
      // 'letter-spacing',
      // 'text-color',
      // 'bold',
      // 'italic',
      // 'underline',
      // 'strike-through',
      // 'superscript',
      // 'subscript',
      // 'remove-styles',
      // 'emoji',
      // 'text-align',
      // 'text-indent',
      // 'link',
      // 'headings',
      // 'list-ul',
      // 'list-ol',
      // 'blockquote',
      // 'code',
      // 'hr',
      'media',
      // 'clear',
      // 'undo',
      // 'redo',
      // 'separator',


      // 'letter-spacing',
      // 'line-height',
      // 'clear',
      // 'headings',
      // 'list-ol',
      // 'list-ul',
      // 'remove-styles',
      // 'superscript',
      // 'subscript',
      // 'hr',
      // 'text-align',
      // 'media',
      // 'emoji'
    ]
    const extendControls = [
      {
        key: 'custom-button',
        type: 'button',
        text: '预览',
        onClick: this.preview
      },
      {
        key: 'antd-uploader',
        type: 'component',
        component: (
          <UploadImage {...this.props} onChange={this.uploadHandler} />
        )
      },
      {
        key: 'antd-uploader-video',
        type: 'component',
        component: (
          <UploadVideo {...this.props} onChange={this.uploadHandler} />
        )
      },
    ]
    return (
      <div className="editor-wrapper">
        <BraftEditor
          value={editorState}
          // controls={controls}
          excludeControls={excludeControls}
          extendControls={extendControls}
          onChange={this.handleChange}
        />
      </div>
    )

  }

}