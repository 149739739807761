
import Coupon from './index'
import Path from 'routes/path'

const routes = [
    {
        id: 'coupon',
        name: 'coupon',
        component: Coupon,
        path: Path.pathname['COUPON'],
        key: 'coupon',
    },
]

export default {
    routes
}