import React from 'react'
import { injectIntl } from 'react-intl';
import { Button, Divider, Modal, message, Icon, Tag } from 'antd';
import TablePage from 'components/TablePage'
import TextMore from 'components/TextMore'
import SearchType from 'utils/searchType'
import service from './service'
import Path from 'routes/path'
import CreateForm from './incomes'
import SelectUser from 'components/SelectUser'
import ChangeSuperPartner from './components/ChangeSuperPartner'

/**
 * 已预约列表页面
 */

class Partner extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isReload: false,
      visible: false,
    }
  }

  render() {
    const { intl } = this.props;
    const { formatMessage } = intl;
    const { isReload } = this.state;

    const fields = [
      { name: 'key', desc: '', render: (text, record, index) => index + 1, width: 50 },
      {
        name: 'user.real_name', desc: '姓名/电话', query: true, key: 'keyword',
        render: (text, record) => {
          return (
            <>
              {text || record.user.nick_name || record.user.id}
              <span style={{ padding: '0 10px' }}></span>
              {record.user && record.user.phone ? <Tag>{record.user && record.user.phone}</Tag> : null}
            </>
          )
        }, showRender: true
      },
      {
        name: 'super_partner.real_name', desc: '上级志愿者',
        render: (text, record) => {
          return (
            <>
              {
                record.super_partner ? <code style={{ marginRight: '10px', padding: '5px', backgroundColor: '#eee' }}>
                  {text || record.super_partner.nick_name || record.super_partner.id || '无'}
                </code>
                  : <></>
              }

              <ChangeSuperPartner {...this.props} handleRefresh={this.handleRefresh} create={service.changeSuperPartner} record={record} title={<Icon type='edit' />} />

            </>
          )
        },
        showRender: true
      },
      { name: 'join_time', desc: formatMessage({ id: 'join_time' }), type: SearchType.KEY_DATE_TIME, sorter: true },
      // {
      //   name: 'action', desc: formatMessage({ id: 'action' }), width: 250, listActions: true,
      //   render: (text, record) => {
      //     return [
      //       <a onClick={() => this.handleShow(record)}>{formatMessage({ id: 'income_detail' })}</a>,
      //       <SelectUser record={record} title={formatMessage({ id: 'add_sec_partner' })} create={service.createData} handleRefresh={this.handleRefresh} {...this.props} />,
      //     ]
      //   }
      // },
    ]

    const insertBtns = [
      <Button type='primary' key="create"><SelectUser create={service.createData} handleRefresh={this.handleRefresh} {...this.props} /></Button>
    ]
    return (
      <div>
        <div className="content-header">
          <h2>{formatMessage({ id: 'partner_list' })}</h2>
        </div>
        <div className="content-body">
          <TablePage
            rowKey={record => record.user.id}
            name={formatMessage({ id: 'partner' })}
            fields={fields}
            insertBtns={insertBtns}
            {...service}
            // loadData={service.getDatas}
            isReload={isReload}
          />
        </div>
        {this.renderModal()}
      </div>
    )
  }

  handleRefresh = () => {
    this.setState({ isReload: true })
  }

  handleShow = (record) => {
    this.setState({ visible: true, record })
  }

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  renderModal = () => {
    const { intl } = this.props;
    const { formatMessage } = intl;
    return (
      <Modal
        title={formatMessage({ id: 'income_detail' })}
        visible={this.state.visible}
        // onOk={this.handleOk}
        onCancel={this.handleCancel}
        footer={[
          <Button key='close' onClick={this.handleCancel}>{formatMessage({ id: 'close' })}</Button>
        ]}
      >
        <CreateForm wrappedComponentRef={this.saveFormRef} record={this.state.record} {...this.props} />
      </Modal>
    )
  }

  handleOk = () => {
    this.setState({ visible: false })
  }

  handleCancel = () => {
    this.setState({ visible: false })
  }
}

export default injectIntl(Partner)