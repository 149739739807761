
import List from './index'
import Create from './create'
import Detail from './detail'
import Path from 'routes/path'

const routes = [
    {
        id: 'news_list',
        name: 'news_list',
        component: List,
        path: Path.pathname['NEWS_LIST'],
        key: 'news_list',
    },
    {
        id: 'news_create',
        name: 'news_create',
        component: Create,
        path: Path.pathname['NEWS_CREATE'],
        key: 'news_create',
        parentKey: 'news_list'
    },
    {
        id: 'news_edit',
        name: 'news_edit',
        component: Create,
        path: Path.pathname['NEWS_EDIT'],
        key: 'news_edit',
        parentKey: 'news_list'
    },
    {
        id: 'news_detail',
        name: 'news_detail',
        component: Detail,
        path: Path.pathname['NEWS_DETAIL'],
        key: 'news_detail',
        parentKey: 'news_list'
    },
]

export default {
    routes
}