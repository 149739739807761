import React from 'react'
import { injectIntl } from 'react-intl';
import moment from 'moment';
import { Button, Divider, Modal, message, Tag } from 'antd';
import TablePage from 'components/TablePage'
import TextMore from 'components/TextMore'
import SearchType from 'utils/searchType'
import service from 'modules/Partner/service'
import productCategoryService from 'modules/ProductCategory/service'
import Path from 'routes/path'

/**
 * 商品管理页面
 */

class Product extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isReload: false,
    }
  }

  componentDidMount() {
  }

  render() {
    const { intl } = this.props;
    const { formatMessage } = intl;
    const { isReload } = this.state;

    const fields = [
      { name: 'key', desc: '', render: (text, record, index) => index + 1, width: 50 },
      {
        name: 'user.real_name', desc: '姓名/电话', query: true, key: 'keyword',
        render: (text, record) => {
          return (
            <>
              {text || record.user.nick_name || record.user.id}
              <span style={{ padding: '0 10px' }}></span>
              {record.user && record.user.phone ? <Tag>{record.user && record.user.phone}</Tag> : null}
            </>
          )
        }, showRender: true
      },
      // { name: 'first_recommend_user', desc: formatMessage({ id: 'first_recommend_user' }), form: { type: 'input' } },
      // { name: 'second_recommand_user', desc: formatMessage({ id: 'second_recommand_user' }), form: { type: 'input' } },
      { name: 'join_time', desc: formatMessage({ id: 'join_time' }), type: SearchType.KEY_DATE_TIME, sorter: true },
      // {
      //   name: 'member_join_time', desc: formatMessage({ id: 'indate' }), showRender: true,
      //   render: (text, record) => `${moment(text).format('YYYY/MM/DD')} - ${moment(record.member_expire_time).format('YYYY/MM/DD')}`
      // },
    ]

    const insertBtns = []
    return (
      <div>
        {/* <div className="content-header">
          <h2>{formatMessage({ id: 'user_list' })}</h2>
        </div> */}
        <div className="content-body">
          <TablePage
            {...this.props}
            size='small'
            rowKey={(record, index) => `${record.user_id}_${index}`}
            name={formatMessage({ id: 'user' })}
            fields={fields}
            insertBtns={insertBtns}
            {...service}
            isReload={isReload}
            rowSelection={{
              type: 'radio'
            }}
          />
        </div>
      </div>
    )
  }

}

export default injectIntl(Product)