import React, { useState, useEffect } from 'react';
import userService from 'modules/UserList/service'
import { QueryClient, QueryClientProvider, useQuery } from "react-query";

const queryClient = new QueryClient();

export default function App(props) {
  return (
    <QueryClientProvider client={queryClient}>
      <UserLabel {...props} />
    </QueryClientProvider>
  );
}

const UserLabel = (props) => {
  const { id } = props
  const { isLoading, error, data = {}, isFetching } = useQuery(["user", id],
    async () => {
      const data = await userService.getDataDetail(id)
      return data.data
    }
  );

  if (error) {
    return id
  }

  return (
    <>
      {data.real_name || data.nick_name || data.id}
    </>
  )
}