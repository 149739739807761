import React, { Component } from 'react'
import { addLocaleData, IntlProvider } from 'react-intl'
import { LocaleProvider } from 'antd'
import localesAntd from './modules/antd'
import moment from 'moment'
import 'moment/locale/zh-cn'
import Util from 'utils/util'
import LocalStorageUtil from 'utils/localStorageUtil'
const defaultLang = 'zh-CN'
// 检查浏览器是否支持intl，不支持则采用IntlPolyfill
if (!global.Intl) {
  global.Intl = require('intl')
  require('./modules/intl')
}
let localesProject = require('./modules')
let localesReactIntl = require('./modules/react-intl')

Object.values(localesReactIntl).map(localesReactIntlItem => addLocaleData(localesReactIntlItem))
addLocaleData({ locale: 'zh-CN', parentLocale: 'zh' })
addLocaleData({ locale: 'en-US', parentLocale: 'en' })

moment.locale('zh-cn')

export default class Intl extends Component {

  resovleLocale = () => {
    let locale = Util.getQueryString('locale')
    // 语言优先级
    //   1. 从 url query string 中取
    //   2. 从 localstorage 中拿
    //   3. 从 this.props.locale 中拿
    //   4. 从 浏览器 navigator.languages 中拿
    //   5. 从 浏览器 UI 语言中拿
    const localstorageLocale = new LocalStorageUtil().getLocale()
    if (localstorageLocale && !locale) {
      locale = localstorageLocale
    }

    if (!locale) {
      let languages = global.navigator.languages || [global.navigator.language || global.navigator.userLanguage || defaultLang]
      locale = this.props.locale || languages[0]
    }
    if (!localesProject[locale]) {
      locale = defaultLang
    }
    new LocalStorageUtil().setLocale(locale)
    return locale
  }

  render() {
    const locale = this.resovleLocale()
    return (
      <IntlProvider locale={locale} key={defaultLang} messages={localesProject[locale]}>
        <LocaleProvider locale={localesAntd[locale]}>
          {this.props.children}
        </LocaleProvider>
      </IntlProvider>
    )
  }
}
