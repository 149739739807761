import React from 'react'
import { injectIntl } from 'react-intl';
import { message } from 'antd';
import { Route, Redirect } from 'react-router'
import { HashRouter, Switch } from 'react-router-dom'
import Frame from '../components/layouts/frame'
import FrameMenu from '../components/layouts/frameMenu'
import Login from '../modules/Login/index'
import LoadingPage from 'modules/LoadingPage'
import Error403 from 'modules/ErrorPage/403'
import LocalStorageUtil from 'utils/localStorageUtil'
import utils from 'utils/util'

import commonService from 'modules/NewsCategory/service'
import userService from 'modules/Login/service'

const Routes = (props) => {
  return (
    <HashRouter>
      <Switch>
        <Route exact path="/" render={() => verify()} />
        <Route path="/login" component={Login} />
        <Route path="/loading" component={LoadingPage} />
        <Route path="/mkt" component={(e) => FrameMenuLayout(e, props)} />
        <Route component={e => FrameLayout(e, props)} />
      </Switch>
    </HashRouter>
  )
}

const FrameMenuLayout = (e, props) => {
  let routeRegistry = props.routeRegistry
  return (
    <FrameMenu {...e} {...props} routeRegistry={routeRegistry}>
      {
        routeRegistry && routeRegistry.map((route, index) => {
          // route.permission && !utils.hasPermission(route.permission) ? Error403 : route.component
          return <Route {...props} {...route} key={route.key} path={`${e.match.url}${route.path}`} component={route.component} />
        })
      }
    </FrameMenu>
  )
}

const FrameLayout = (e, props) => {
  let routeRegistry = props.routeRegistry
  return (
    <Frame {...e} {...props} routeRegistry={routeRegistry}>
      {
        routeRegistry && routeRegistry.map((route, index) => {
          return <Route key={route.key} path={route.path} component={route.component} />
        })
      }
    </Frame>
  )
}

const verify = () => {

  const auth = new LocalStorageUtil().getAuth()
  var token = auth && encodeURIComponent(auth)
  if (!token)
    return <Redirect to="/login" />
  else {
    return <Redirect to="/mkt/home" />
  }
}

const checkToken = async () => {
  const [err, data] = await utils.ha(commonService.getDatas())
  return [err, data]
}

class App extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      isChecked: false,
    }
  }

  componentWillMount() {
    this.checkToken()
  }

  checkToken = () => {
    userService.getUserInfo().then(data => {
      const { roles = [] } = data.data;
      new LocalStorageUtil().setUserInfo(JSON.stringify(data.data))
      this.setState({ isChecked: true })
      if (!roles || !roles.length) {
        ['auth'].forEach(i => {
          new LocalStorageUtil().clearStorage(i)
        })
        window.location.href = '/#/login'
      }
    }).catch(error => {
      this.setState({ isChecked: true })
      // if (window.location.href.indexOf('/login') !== -1) {
      //   this.setState({ isChecked: true })
      // }
    })
  }

  render() {
    return this.state.isChecked ? <Routes {...this.props} /> : <LoadingPage />
  }
}

export default injectIntl(App)