import React from 'react'
import { injectIntl } from 'react-intl';
import { Button, Divider, Modal, message } from 'antd';
import TablePage from 'components/TablePage'
import TextMore from 'components/TextMore'
import SearchType from 'utils/searchType'
import service from './service'
import Path from 'routes/path'

/**
 * 优惠券管理页面
 */

class Monitor extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isReload: false,
    }
  }

  render() {
    const { intl } = this.props;
    const { formatMessage } = intl;
    const { isReload } = this.state;

    const fields = [
      { name: 'coupon_id', desc: 'Id', hide: true },
      { name: 'name', desc: '名称', showDetail: true, query: true },
      { name: 'number', desc: '数量' },
      { name: 'integral', desc: '兑换所需积分' },
      { name: 'status', desc: '状态', query: true },
      { name: 'description', desc: '描述', render: (text) => <TextMore text={text} maxLength={10} /> },
      { name: 'create_time', desc: formatMessage({ id: 'create_time' }), type: SearchType.KEY_DATE_TIME, sorter: true },
      { name: 'end_time', desc: '到期时间', type: SearchType.KEY_DATE_TIME, sorter: true },
      {
        name: 'action', desc: formatMessage({ id: 'action' }),
        render: (text, record) => (
          <span>
            <a>编辑</a>
            <Divider type="vertical" />
            <a>删除</a>
          </span>
        )
      },
    ]

    const insertBtns = []
    return (
      <div>
        <div className="content-header">
          <h2>优惠券管理</h2>
        </div>
        <div className="content-body">
          <TablePage
            rowKey='coupon_id'
            name={formatMessage({ id: 'cluster' })}
            fields={fields}
            insertBtns={insertBtns}
            loadData={service.getDatas}
            isReload={isReload}
            detailTitle='会员详情'
          />
        </div>
      </div>
    )
  }

}

export default injectIntl(Monitor)