import React from 'react';
import { Chart, Interval, Tooltip, Line, Point } from 'bizcharts';

const BizCharts = (props) => {
  const { datas = [] } = props
  return (
    <div style={{ padding: 40 }}>
      <Chart
        height={600}
        autoFit
        // forceFit
        data={datas}
        // interactions={['active-region']}
        // padding="auto"
        padding={
          [10, 20, 50, 40]
        }
        scale={{
          value: {
            min: 0
          }
        }}
      >
        {/* <Interval position="group_name*value" /> */}
        <Line position="group_name*value" />
        <Point position="group_name*value" />
        {/* <Tooltip shared /> */}
      </Chart>
    </div>
  )
}

export default BizCharts 