import React from 'react'
import { injectIntl } from 'react-intl';
import { Button, Divider, Modal, message } from 'antd';
import TablePage from 'components/TablePage'
import SearchType from 'utils/searchType'
import service from './service'
import Path from 'routes/path'

/**
 * 会员管理页面
 */

class Monitor extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isReload: false,
    }
  }

  render() {
    const { intl } = this.props;
    const { formatMessage } = intl;
    const { isReload } = this.state;

    const fields = [
      { name: 'key', desc: '', render: (text, record, index) => index + 1, width: 50 },
      { name: 'vip', desc: '会员计划', showDetail: true, query: true, type: SearchType.KEY_CUSTOM_OPTION, options: ['VIP1', 'VIP2', 'VIP3', 'VIP4', 'VIP5', 'VIP6', 'VIP7'].map(i => ({ name: i, value: i })) },
      {
        name: 'description', desc: '权益内容', width: '50%', showRender: true, render: (text) => {
          return text.map((i, d) => {
            return <p style={{ marginBottom: 0 }} key={d}>权益{d + 1}. {i}</p>
          })
        }
      },
      {
        name: 'action', desc: formatMessage({ id: 'action' }), width: 250,
        render: (text, record) => (
          <span>
            <a>编辑</a>
          </span>
        )
      },
    ]

    const insertBtns = []
    return (
      <div>
        <div className="content-header">
          <h2>会员权益</h2>
        </div>
        <div className="content-body">
          <TablePage
            rowKey='user_id'
            name={formatMessage({ id: 'cluster' })}
            fields={fields}
            insertBtns={insertBtns}
            loadData={service.getDatas}
            isReload={isReload}
            detailTitle='会员详情'
          />
        </div>
      </div>
    )
  }

}

export default injectIntl(Monitor)