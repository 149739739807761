
import PointList from './index'
import Create from './create'
import Path from 'routes/path'

const routes = [
    {
        id: 'point_list',
        name: 'point_list',
        component: PointList,
        path: Path.pathname['POINT_LIST'],
        key: 'point_list',
    },
    {
        id: 'point_create',
        name: 'point_create',
        component: Create,
        path: Path.pathname['POINT_CREATE'],
        key: 'point_create',
        parentKey: 'point_list'
    },
    {
        id: 'point_edit',
        name: 'point_edit',
        component: Create,
        path: Path.pathname['POINT_EDIT'],
        key: 'point_edit',
        parentKey: 'point_list'
    },
]

export default {
    routes
}