import 'core-js/es/map';
import 'core-js/es/set';
import 'babel-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import Intl from './i18n/intl'
import { ConfigProvider } from 'antd'

import Route from "./routes/routeRegistry";

import App from './routes/index';
import config from './config'
import 'utils/http'
window.config = config
const antdConfig = {
  autoInsertSpaceInButton: false,
  getPopupContainer: () => document.getElementById('content'),
  getTooltipPopupContainer: () => document.getElementById('content')
}

ReactDOM.render(
  <Intl>
    <ConfigProvider {...antdConfig}>
      <App routeRegistry={Route.routeRegistry} />
    </ConfigProvider>
  </Intl>,
  document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
