import moment from 'moment';
import SearchType from 'utils/searchType';
import LocalStorageUtil from 'utils/localStorageUtil'
import { base64Decode } from 'utils/base64'

let localesProject = require('i18n/modules')

const getQueryString = (name) => {
  let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
  let r = window.location.search.substr(1).match(reg) ||
    window.location.hash.substring((window.location.hash.search(/\?/)) + 1).match(reg);
  if (r != null) {
    return unescape(r[2]);
  }
  return null
}

const getStrQuery = (str, name) => {
  let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
  let r = str.substring((str.search(/\?/)) + 1).match(reg);
  if (r != null) {
    return unescape(r[2]);
  }
  return null
}

const getParam = (type, key) => {
  const regExp = new RegExp(`[?&#]{1}${key}=(.*?)([&/#]|$)`)
  const value = window.location[type].match(regExp)
  return value && value[1]
}

const setParam = (type, keyValueMap) => {
  if (!(type === 'search' || type === 'hash')) return
  let str = `${window.location[type]}`
  for (const key of Object.keys(keyValueMap)) {
    const value = keyValueMap[key]
    if (getParam(type, key)) {
      const regExp = new RegExp(`(.*)([#&?]${key}=)(.*?)($|&.*)`)
      const array = str.match(regExp)
      if (value === '') {
        array.splice(2, 2)
      } else {
        array[3] = value
      }
      array.shift()
      str = array.join('')
    } else {
      str = str && str.indexOf('?') !== -1
        ? `${str}&${key}=${value}`
        : `${str}?${key}=${value}`
    }
  }
  window.location[type] = str
}

const getCookie = (name) => {
  let arr, reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)')
  if (arr = document.cookie.match(reg)) {
    return unescape(arr[2])
  } else {
    return null
  }
}

const createQueryString = (query, allowSameKey) => {
  const queryArr = []

  for (let key in query) {
    if (query.hasOwnProperty(key)) {
      const value = query[key]

      if (typeof value === 'object' && allowSameKey) {
        value.forEach(v => {
          queryArr.push(`${key}=${v}`)
        })
      } else {
        queryArr.push(`${key}=${value}`)
      }
    }
  }
  return queryArr.length ? `?${queryArr.join('&')}` : ''
}

const createRandomName = (prefix) => {
  return `${prefix}-${Math.random().toString(36).slice(-4)}`
}

const keypairsArrayToString = (arr) => {
  // arr = [{key:1,value:2},...]
  if (!arr || !arr.length) return
  return arr.map(i => `--${i.key}=${i.value}`).join(' ')
}

const keypairsArrayToMap = (arr) => {
  // arr = [{key:1,value:2},...]
  if (!arr || !arr.length) return null
  var obj = {}
  arr.forEach(i => {
    obj[i.key] = i.value
  })
  return obj
}

const keypairsStringToArray = (str) => {
  // str = '--var1=123 --var2=456'
  if (!str) return
  return str.split(' ').map(i => {
    var arr = i.split('=')
    return {
      key: arr[0].replace('--', ''),
      value: arr[1]
    }
  })
}

const keypairsMapToArray = (obj) => {
  if (!obj || typeof obj !== 'object') return
  var arr = []
  for (var i in obj) {
    arr.push({ key: i, value: obj[i] })
  }
  return arr
}

const getRecordValue = (record, fieldName) => {
  if (!record || !fieldName) return
  var name = fieldName
  if (name && name.indexOf('.') !== -1) {
    var nameArr = name.split('.')
    var s = nameArr.reduce((pre, cur, index, nameArr) => {
      if (typeof pre === 'object') return pre && pre[cur]
      return record[pre] && record[pre][cur]
    })
    if (typeof s === 'string' || typeof s === 'number' || typeof s === 'object') return s
  }
  return record[fieldName]
}

const getTimeString = (t) => {
  return t < 10 ? '0' + t : t
}

const getSecToHMS = (t) => {
  if (!t) return
  t = t * 1
  var obj = {
    h: t / 3600,
    m: t / 60 % 60,
    s: t % 60
  }
  var arr = []
  for (var i in obj) {
    arr.push(getTimeString(Math.floor(obj[i])))
  }
  return arr.join(':')
}

const sizeFormat = (size) => {
  size = parseInt(size)
  if (!size && size !== 0) return '-'

  if (size / 1024 / 1024 / 1024 / 1024 >= 1) {
    return `${(size / 1024 / 1024 / 1024 / 1024).toFixed(2)} TB`
  }

  if (size / 1024 / 1024 / 1024 >= 1) {
    return `${(size / 1024 / 1024 / 1024).toFixed(2)} GB`
  }

  if (size / 1024 / 1024 >= 1) {
    return `${(size / 1024 / 1024).toFixed(2)} MB`
  }

  if (size / 1024 >= 1) {
    return `${(size / 1024).toFixed(2)} KB`
  }

  return `${size} Byte(s)`
}

const localPathPrefix = '/mnt/oss/omai-job/';
const s3PathPrefix = 's3://';

const transformS3ToLocal = (path) => {
  if (!path) return
  return path.replace(s3PathPrefix, localPathPrefix)
}

const transformLocalToS3 = (path) => {
  if (!path) return
  return path.replace(localPathPrefix, s3PathPrefix)
}

const timeFormat = (time, type) => {
  if (!time || time === 'null') return '-'
  switch (type) {
    case SearchType.KEY_DATE:
      return moment(time).format('YYYY-MM-DD');
    case SearchType.KEY_DATE_TIME:
      return moment(time).format('YYYY-MM-DD HH:mm:ss')
    default:
      return moment(time).format('YYYY-MM-DD HH:mm:ss')
  }
}

const runtimeFormat = (startTime, endTime, tag) => {
  if (!startTime) return '-'
  if (!endTime && !tag) return '-'
  return getSecToHMS(Math.abs((endTime ? moment(endTime) : moment()).diff(startTime, 'seconds'))) || '-'
}

const getUrl = (protocalType, host, port, path) => {
  if (!protocalType && !host) return
  return `${protocalType.toLowerCase()}://${host}${port ? ':' + port : ''}/${path || ''}`
}

const hasPermission2 = (permission) => {
  if (!permission) return false
  const userInfo = new LocalStorageUtil().getUserInfo()
  let userInfoObj = {}
  try {
    userInfoObj = JSON.parse(userInfo)
  } catch (e) {
    console.log(e)
  }
  let userPermissions = []

  userInfoObj.roles && userInfoObj.roles.forEach(i => {
    if (i.privileges && i.privileges.length) {
      userPermissions.push(...i.privileges)
    }
  })
  if (typeof permission === 'object') {
    let tag = false
    permission.forEach(i => {
      if (userPermissions.includes(i)) {
        tag = true
      }
    })
    return tag
  }
  return userPermissions.includes(permission)
}

const hasPermission = (permission) => {
  if (!permission) return false
  const privileges = new LocalStorageUtil().getPrivileges() || '[]'
  let privilegesArr = []
  try {
    privilegesArr = JSON.parse(privileges)
    // privilegesArr = JSON.parse(base64Decode(privileges))
  } catch (e) {
    console.log(e)
  }
  if (typeof permission === 'object') {
    let tag = false
    permission.forEach(i => {
      if (privilegesArr.includes(i)) {
        tag = true
      }
    })
    return tag
  }
  return privilegesArr.includes(permission)
}

const getDomain = (name) => {
  const componentsStr = localStorage['components']
  let components = []
  try {
    components = JSON.parse(componentsStr)
  } catch (err) {

  }
  const component = components.find(i => i.name === name) || {}
  if (!component.domain) return
  if (component.domain.indexOf('http') !== -1 || component.domain.indexOf('https') !== -1) {
    return component.domain
  } else {
    return `http${component.useSSL === '1' ? 's' : ''}://${component.domain}`
  }
}

const getLocale = (props) => {
  const defaultLang = 'zh-CN'
  let locale = getQueryString('locale')
  const localstorageLocale = new LocalStorageUtil().getLocale()
  if (localstorageLocale && !locale) {
    locale = localstorageLocale
  }

  if (!locale) {
    let languages = global.navigator.languages || [global.navigator.language || global.navigator.userLanguage || defaultLang]
    locale = props.locale || languages[0]
  }
  if (!localesProject[locale]) {
    locale = defaultLang
  }
  new LocalStorageUtil().setLocale(locale)
  return locale
}

/**
 * 获取最近六个月时间列表
 */
const getMonths = () => {
  let arr = []
  for (var i = 0; i < 6; i++) {
    const time = moment().month(moment().month() - i) //.startOf('month')
    const year = moment(time).year()
    const month = moment(time).month()
    const label = `${year}/${month + 1}`
    arr.push({
      label: label,
      value: label,
      range: [time.startOf('month').format('YYYY-MM-DD HH:mm:ss'), (i === 0 ? moment() : time.endOf('month')).format('YYYY-MM-DD HH:mm:ss')]
    })
  }
  return arr
}

// async error handle
const ha = promise => {
  return promise.then(data => [null, data]).catch(err => [err, void 0])
}

export default {
  getQueryString,
  getStrQuery,
  getCookie,
  createQueryString,
  createRandomName,
  getParam,
  setParam,
  keypairsArrayToString,
  keypairsStringToArray,
  getRecordValue,
  getSecToHMS,
  keypairsMapToArray,
  keypairsArrayToMap,
  sizeFormat,
  transformS3ToLocal,
  transformLocalToS3,
  timeFormat,
  runtimeFormat,
  getUrl,
  hasPermission,
  getDomain,
  getLocale,
  getMonths,
  ha
}
