import React from 'react';
import { injectIntl } from 'react-intl';
import { Form, Icon, Input, Button, Checkbox, Spin, message } from 'antd';
import service from './service'
import './index.css'
import Path from 'routes/path'
import LocalStorageUtil from 'utils/localStorageUtil'
import { base64Encode } from 'utils/base64'
import qrcodeImg from 'theme/img/icon_qrcode.png'
import inputImg from 'theme/img/icon_input.png'

class NormalLoginForm extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      type: 'input',
      error_code: ''
    }
    this.time = 0
  }

  componentDidMount() {
    // this.check()
  }

  check = () => {
    clearInterval(this.time)
    this.time = setInterval(() => {
      service.check().then(data => {
        this.setState({ error_code: '' })
        if (data.data.success) {
          this.loginSuccessCallback(data)
        } else {
          if (data.data.error_code === '404') {
            this.setState({ error_code: '404' })
            clearInterval(this.time)
          } else {

          }

        }

      })
    }, 3000)
  }

  handleChangeType = () => {
    const { type } = this.state
    if (type === 'input') {
      this.setState({ error_code: '' })
      this.check()
    } else {
      clearInterval(this.time)
    }
    this.setState({ type: type === 'input' ? 'qrcode' : 'input' })
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.login(values).then(this.loginSuccessCallback).catch(this.loginErrorCallback)
      }
    });
  };

  loginSuccessCallback = (data) => {
    const { intl } = this.props;
    const { formatMessage } = intl;
    const { token_type, token, success } = data.data
    if (!success) return
    new LocalStorageUtil().setAuth(`${token_type} ${token}`)
    this.setState({ loading: false })
    this.checkToken(() => {
      message.success(formatMessage({ id: 'login_success' }))
      window.location.href = '#/mkt/home'
    })
    // this.props.history.push(`/mkt${Path.getPathname(this.props, Path.pathname['HOME'])}`)
  }

  checkToken = (callback) => {
    service.getUserInfo().then(data => {
      const { roles = [] } = data.data;
      new LocalStorageUtil().setUserInfo(JSON.stringify(data.data))
      this.setState({ isChecked: true })
      if (!roles || !roles.length) {
        message.error('没有权限');
        ['auth'].forEach(i => {
          new LocalStorageUtil().clearStorage(i)
        })
        window.location.href = '/#/login'
        return
      }
      callback()
    }).catch(error => {
      // this.setState({ isChecked: true })
      // if (window.location.href.indexOf('/login') !== -1) {
      //   this.setState({ isChecked: true })
      // }
      callback()
    })
  }

  loginErrorCallback = (error) => {
    const { intl } = this.props;
    const { formatMessage } = intl;
    this.setState({ loading: false })
    message.error((error.response && error.response.data.message) || formatMessage({ id: 'login_error' }))
  }

  login = (params) => {
    this.setState({
      loading: true
    })
    return service.login(params)
  }

  componentWillUnmount() {
    clearInterval(this.time)
  }

  render() {
    const { intl } = this.props;
    const { formatMessage } = intl;
    const { getFieldDecorator } = this.props.form;
    const { loading = false, type, error_code } = this.state
    return (
      <div className="login-box">
        <Spin spinning={loading}>
          <div className="login-inner">
            <h2 className="login-title">{formatMessage({ id: 'user_login' })}</h2>
            <div className="login-check" style={{ backgroundImage: `url(${type === 'input' ? qrcodeImg : inputImg})` }} onClick={() => this.handleChangeType()}></div>
            {
              type === 'qrcode'
                ? <>
                  <div className="qrcode-box">
                    <img className='qrcode' alt="" src='/api/v1/auth/qrcode.png' />
                    {error_code === '404' ? <p className='qrcode-tip'>二维码已失效，请刷新页面</p> : null}
                  </div>
                  <p style={{ textAlign: 'center' }}>打开小程序，扫一扫登录</p>
                </>
                : <Form onSubmit={this.handleSubmit} className="login-form">
                  <Form.Item>
                    {getFieldDecorator('username', {
                      rules: [{ required: true, message: formatMessage({ id: 'message_input' }) + formatMessage({ id: 'username' }) }],
                      initialValue: ''
                    })(
                      <Input
                        prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder={formatMessage({ id: 'username' })}
                      />,
                    )}
                  </Form.Item>
                  <Form.Item>
                    {getFieldDecorator('password', {
                      rules: [{ required: true, message: formatMessage({ id: 'message_input' }) + formatMessage({ id: 'password' }) }],
                      initialValue: ''
                    })(
                      <Input
                        prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                        type="password"
                        placeholder={formatMessage({ id: 'password' })}
                      />,
                    )}
                  </Form.Item>
                  {/* <Form.Item>
                {getFieldDecorator('remember', {
                  valuePropName: 'checked',
                  initialValue: true,
                })(<Checkbox>{formatMessage({ id: 'remember_username' })}</Checkbox>)}
                <a className="login-form-forgot">{formatMessage({ id: 'register' })}</a>
                <a className="login-form-forgot">{formatMessage({ id: 'forget_password' })}</a>
              </Form.Item> */}
                  <Checkbox defaultChecked={true}>{formatMessage({ id: 'remember_username' })}</Checkbox>
                  <Button type="primary" size="large" htmlType="submit" className="login-form-button">{formatMessage({ id: 'login' })}</Button>
                  <a>{formatMessage({ id: 'register' })}</a>
                </Form>
            }
          </div>
        </Spin>
      </div>
    );
  }
}

const WrappedNormalLoginForm = Form.create({ name: 'normal_login' })(NormalLoginForm);

export default injectIntl(WrappedNormalLoginForm)