
import Integral from './index'
import Path from 'routes/path'

const routes = [
    {
        id: 'integral',
        name: 'integral',
        component: Integral,
        path: Path.pathname['USER_POINT'],
        key: 'user_point',
    },
]

export default {
    routes
}