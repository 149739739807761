
import Product from './index'
import Path from 'routes/path'

const routes = [
    {
        id: 'product',
        name: 'product',
        component: Product,
        path: Path.pathname['PRODUCT_CATEGORY'],
        key: 'product_category',
    },
]

export default {
    routes
}