import React from 'react'
import { injectIntl } from 'react-intl';
import moment from 'moment';
import { Button, Divider, Modal, message } from 'antd';
import TablePage from 'components/TablePage'
import SearchType from 'utils/searchType'
import service from './service'
import MemberPlanService from '../MemberLevel/service'
import Path from 'routes/path'
import CreateForm from './form'
import SelectPartner from 'modules/Reservations/components/SelectPartner'

/**
 * 会员管理页面
 */

class Monitor extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isReload: false,
      options: [],
      visible: false,
      record: {}
    }
  }

  handleShow = (record) => {
    this.setState({ visible: true, record })
  }

  render() {
    const { intl } = this.props;
    const { formatMessage } = intl;
    const { isReload, options } = this.state;

    const fields = [
      { name: 'key', desc: '', render: (text, record, index) => index + 1, width: 50 },
      {
        name: 'real_name', desc: formatMessage({ id: 'real_name' }), showDetail: true, key: 'keyword', query: true, form: { type: 'input', formKey: 'real_name' }, showRender: true,
        render: (text, record) => {
          return text || record.nick_name || record.id
        }
      },
      { name: 'phone', desc: '手机号', form: { type: 'input', rules: [] } },
      { name: 'sn', desc: '身份证号', hide: true, form: { type: 'input', rules: [] } },
      {
        name: 'first_recommend_user.real_name', desc: '推荐人', form: { type: 'custom', formKey: 'first_recommend_user_id' }, showRender: true,
        customRender: <SelectPartner {...this.props} />,
        render: (text, record) => {
          return text || (record.first_recommend_user && (record.first_recommend_user.nick_name || record.first_recommend_user.id)) || '-'
        }
      },
      {
        name: 'roles', desc: formatMessage({ id: 'roles' }), render: (text) => text.map(i => ({
          'OPERATOR': '操作员',
          'MANAGER': '管理员'
        })[text])
      },
      { name: 'register_time', desc: formatMessage({ id: 'register_time' }), type: SearchType.KEY_DATE_TIME },

      {
        name: 'action', desc: formatMessage({ id: 'action' }), width: 150, listActions: true,
        render: (text, record) => {
          return [
            <a onClick={() => this.handleShow(record)}>{formatMessage({ id: 'role_set' })}</a>
          ]
        }
      },
    ]

    const insertBtns = []
    return (
      <div>
        <div className="content-header">
          <h2>{formatMessage({ id: 'user_list' })}</h2>
        </div>
        <div className="content-body">
          <TablePage
            rowKey={(record, index) => `${record.id}_${index}`}
            name={formatMessage({ id: 'user' })}
            fields={fields}
            insertBtns={insertBtns}
            {...service}
            isReload={isReload}
            showCreate
          />
        </div>
        {this.renderModal()}
      </div>
    )
  }

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  renderModal = () => {
    const { intl } = this.props;
    const { formatMessage } = intl;
    return (
      <Modal
        title={formatMessage({ id: 'role_set' })}
        visible={this.state.visible}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
      >
        <CreateForm wrappedComponentRef={this.saveFormRef} {...this.props} />
      </Modal>
    )
  }

  handleOk = () => {
    const { record } = this.state
    this.formRef.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ visible: false })
        service.setRole(record.id, values.roles).then(data => {
          message.success('设置角色成功')
          this.setState({ isReload: true })
        })
      }
    })
  }

  handleCancel = () => {
    this.setState({ visible: false })
  }

}

export default injectIntl(Monitor)