import React from 'react';
import { Upload, Icon, message } from 'antd';
import service from './service';

class UploadVideo extends React.Component {

  state = {
    loading: false,
    upConfig: {}
  };

  componentDidMount() {
    this.setState({
      imageUrl: this.props.value
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value) {
      this.setState({ imageUrl: nextProps.value })
    }
  }

  fetchUploadToken = async (file) => {
    const res = await service.getToken()
    if (res.data.token) {
      this.setState({
        uploadToken: res.data.token,
        fileKey: file.name,
        upConfig: res.data
      })
    }
  }

  getUploadToken = () => {
    const { fileKey } = this.state
    const arr = fileKey.split('.')
    const ext = arr[arr.length - 1]
    const rnd = +new Date() + '_' + Math.random().toString(36).slice(-6)
    return {
      token: this.state.uploadToken,
      key: [rnd, ext].join('.')
    }
  }

  getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  beforeUpload = async (file) => {
    let tag = false;
    const isJpgOrPng = file.type === 'video/mp4' // || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('请选择MP4文件！');
      return tag;
    }
    const isLt2M = file.size / 1024 / 1024 < 5 * 100;
    if (!isLt2M) {
      message.error('视频大小不能超过500MB！');
      return tag;
    }
    tag = isJpgOrPng && isLt2M;
    await this.fetchUploadToken(file)
    tag = true;
    return tag;
  }

  handleChange = (info) => {

    const { upConfig } = this.state;
    const { schema, domain, bucket, token, provider } = upConfig || {}
    const { onChange } = this.props

    if (info.file.status === 'uploading') {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === 'done') {
      const imageUrl = `${schema}://${domain}/${info.file.response.key}`
      this.setState({
        loading: false,
        imageUrl
      })
      onChange && onChange(imageUrl, 'VIDEO')
    }
  };

  render() {
    const { intl } = this.props;
    const { formatMessage } = intl;
    const { imageUrl, loading, upConfig } = this.state;
    const { schema, domain, bucket, token, provider } = upConfig || {}
    return (
      <Upload
        // name="cover"
        // listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        // action={`${schema}://${domain}/${bucket}`}
        action="https://up.qbox.me"
        data={() => this.getUploadToken()}
        beforeUpload={this.beforeUpload}
        onChange={this.handleChange}
        accept=".mp4"
      >
        <button type="button" className="control-item button upload-button" data-title="插入视频">
          <Icon type="play-square" theme="filled" />
        </button>
        {/* {imageUrl ? <img src={imageUrl} alt="cover" style={{ width: '100%' }} /> : <div>
          <Icon type={loading ? 'loading' : 'plus'} />
          <div className="ant-upload-text">{formatMessage({ id: 'upload' })}</div>
        </div >} */}
      </Upload >
    )
  }

}

export default UploadVideo;