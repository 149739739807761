import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Row, Col, Card, Icon, Divider, Typography, List, Avatar, Spin, message } from 'antd';
import Path from 'routes/path'
import utils from 'utils/util';
import './index.css'

const { Paragraph } = Typography;

class Home extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      data: [],
      loading: false
    }
  }

  render() {
    const { intl } = this.props;
    const { formatMessage } = intl;
    const { data, loading } = this.state;
    const gridStyle = {
      width: '50%'
    }

    const memberGroup = [
      { name: '会员计划', path: '/mkt/member_plan' },
      { name: '会员列表', path: '/mkt/member_list' },
      { name: '会员统计', path: '/mkt/member_statistics' },
    ]

    const productGroup = [
      { name: '商品分类', path: '/mkt/product_category' },
      { name: '商品列表', path: '/mkt/product_list' },
    ]

    const integralGroup = [
      { name: '积分商城', path: '/mkt/point_list' },
      { name: '兑换记录', path: '/mkt/point_record' },
      { name: '兑换统计', path: '/mkt/point_statistics' },
      { name: '用户积分', path: '/mkt/user_point' },
      { name: '积分规则', path: '/mkt/point_rules' },
    ]

    const orderGroup = [
      { name: '支付记录', path: '/mkt/order' },
      { name: '支付统计', path: '/mkt/order_statistics' },
    ]

    const spreadGroup = [
      { name: '推广统计', path: '/mkt/spread_statistics' },
    ]

    const groups = [
      { name: '会员管理', children: memberGroup },
      { name: '商品管理', children: productGroup },
      { name: '积分管理', children: integralGroup },
      { name: '财务中心', children: orderGroup },
      { name: '推广管理', children: spreadGroup },
    ]

    return (
      <div>
        <Row gutter={20}>
          <Col span={16} xs={24} md={16}>
            {
              groups.map((item, index) => {
                return (
                  <Card title={item.name} style={{ marginBottom: 20 }} key={index}>
                    {
                      item.children.map((i, d) => {
                        return (
                          <Card.Grid style={gridStyle} key={d}>
                            <Row>
                              <Col span={6}>
                                <Icon style={{ fontSize: 40 }} type='setting' theme="twoTone" />
                              </Col>
                              <Col span={18}>
                                <h3><Paragraph ellipsis={{ rows: 1 }}>{i.name}</Paragraph></h3>
                                <p>{i.name}</p>
                              </Col>
                            </Row>
                            <div style={{ textAlign: 'right' }}>
                              <Link to={i.path}>{formatMessage({ id: 'enter' })}</Link>
                            </div>
                          </Card.Grid>
                        )
                      })
                    }
                  </Card>
                )
              })
            }
          </Col>
          <Col span={8} xs={24} md={8}>
            {
              groups.map((item, index) => {
                return (
                  <Card title={item.name} style={{ marginBottom: 20 }} key={index}>
                    <Row className="quick_link">
                      {
                        item.children.map((i, d) => {
                          return (
                            <Col span={8} key={d}><Paragraph ellipsis={{ rows: 1 }}><Link to={i.path} title={i.name}>{i.name}</Link></Paragraph></Col>
                          )
                      })
                    }
                    </Row>
                  </Card>
                )
              })
            }
          </Col>
        </Row>
      </div>
    )
  }

  getPath = (pathId) => {
    return Path.getPathname(this.props, Path.pathname[pathId])
  }

  getJobName = (jobType) => {
    const { intl } = this.props
    const { formatMessage } = intl
    const obj = {
      DevEnv: 'development_environment',
      TrainingJob: 'training_job',
      VisualTool: 'visual_tool',
      InferenceSvc: 'inference_service',
    }
    return formatMessage({ id: obj[jobType] })
  }

  getJobPathId = (jobType) => {
    const obj = {
      DevEnv: 'NOTEBOOK',
      TrainingJob: 'TRAIN',
      VisualTool: 'VISUAL',
      InferenceSvc: 'INFERENCE',
    }
    return this.getPath(obj[jobType])
  }

  renderOld() {
    const { intl } = this.props;
    const { formatMessage } = intl;
    return (
      <div>
        <Row gutter={20}>
          <Col span={12}>
            <Card>
              <Row>
                <Col span={6}>
                  <Icon style={{ fontSize: 50 }} type='pie-chart' />
                </Col>
                <Col span={18}>
                  <h3>{formatMessage({ id: 'business_management' })}</h3>
                  <Divider />
                  <p>您可以通过此功能对后台运维</p>
                  <p>进行相关页面的配置管理。</p>
                </Col>
              </Row>
              <div style={{ textAlign: 'right' }}>
                <Link to={'/payment_set'}>{formatMessage({ id: 'enter' })}</Link>
              </div>
            </Card>
          </Col>
          <Col span={12}>
            <Card>
              <Row>
                <Col span={6}>
                  <Icon style={{ fontSize: 50 }} type='area-chart' /></Col>
                <Col span={18}>
                  <h3>{formatMessage({ id: 'user_management' })}</h3>
                  <Divider />
                  <p>用户管理是对系统用户进行创建，修改和删除，</p>
                  <p>还可以对用户进行重置密码。</p>
                </Col>
              </Row>
              <div style={{ textAlign: 'right' }}>
                <Link to={'/user'}>{formatMessage({ id: 'enter' })}</Link>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }

}

export default injectIntl(Home)