import axios from 'axios'
import config from 'config'

const baseURL = config.resource_url

/**
 * 获取数据列表
 * @param {*} params 
 */

const getDatas = (params) => {
    return axios.get('/v1/sys/point_rules', {
        params 
    })
}

/**
 * 创建数据
 * @param {*} body 
 */

const saveData = (body) => {
    return axios.post('/v1/sys/point_rules', body)
}

export default {
    getDatas,
    saveData
}