import React from 'react'
import { injectIntl } from 'react-intl';
import { Button, Alert, Modal, message } from 'antd';
import TablePage from 'components/TablePage'
import ChargeList from './components/ChargeList'
import SearchType from 'utils/searchType'
import service from './service'
import Path from 'routes/path'
import CreateForm from './form'
import CreateNewForm from './formCreate'
import UserLabel from 'components/UserLabel'

/**
 * 已预约列表页面
 */

class Reservations extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isReload: false,
      visible: false,
      createVisible: false,
    }
  }

  render() {
    const { intl } = this.props;
    const { formatMessage } = intl;
    const { isReload } = this.state;

    const fields = [
      { name: 'key', desc: '', render: (text, record, index) => index + 1, width: 50 },
      {
        name: 'real_name', desc: '患者姓名', key: 'keyword', query: true,
        // render: (text) => {
        //   return text && (text.real_name || text.nick_name || text.id)
        // }, showRender: true
      },
      { name: 'date', desc: formatMessage({ id: 'reservation_date' }), query: true, type: SearchType.KEY_DATE },
      { name: 'phone', desc: '患者电话' },
      { name: 'user_id', desc: '提交志愿者', render: (text) => <UserLabel id={text} /> },
      // {
      //   name: 'status', desc: formatMessage({ id: 'status' }), render: text => {
      //     return {
      //       'ACTIVE': '可使用',
      //       'EXPIRED': '已过期',
      //       'COMPLETED': '已使用',
      //       'REFUNDED': '已取消',
      //       'REFUNDING': '取消中',
      //     }[text] || '-'
      //   }
      // },
      // { name: 'create_time', desc: formatMessage({ id: 'create_time' }), type: SearchType.KEY_DATE_TIME, sorter: true },
      {
        name: 'action', desc: formatMessage({ id: 'action' }), width: 250, listActions: true,
        render: (text, record) => {
          return [
            <a onClick={() => this.handleShow(record)}>记录消费</a>,
            <ChargeList {...this.props} record={record} />
          ]
        }
      },
    ]

    const insertBtns = [
    ]
    return (
      <div>
        <div className="content-header">
          <h2>已登记患者就诊信息</h2>
          <Alert
            message="请注意！！！"
            description="以下数据为志愿者登记的患者就诊信息，请注意判断真伪之后再进行消费记录！"
            type="info"
          />
        </div>
        <div className="content-body">
          <TablePage
            rowKey='id'
            name={formatMessage({ id: 'reservation' })}
            fields={fields}
            insertBtns={insertBtns}
            {...service}
            // loadData={service.getDatas}
            isReload={isReload}
          // addFilter={{
          //   type: 'PARTNER'
          // }}
          />
        </div>
        {this.renderModal()}
        {this.renderCreateModal()}
      </div>
    )
  }

  handleShow = (record) => {
    this.setState({ visible: true, record })
  }

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  renderModal = () => {
    const { intl } = this.props;
    const { formatMessage } = intl;
    const { record } = this.state;
    return (
      <Modal
        width={650}
        title='记录消费'
        visible={this.state.visible}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
      >
        <CreateForm wrappedComponentRef={this.saveFormRef} {...this.props} record={record} />
      </Modal>
    )
  }

  handleOk = () => {
    const { record } = this.state
    this.formRef.props.form.validateFields((err, values) => {
      if (!err) {
        service.saveIncomes(record.id, {
          ...values,
          recommend_partner_id: record.user_id
        }).then(data => {
          if (data.data.success) {
            message.success('记录消费成功')
            this.setState({ isReload: true })
            this.handleCancel()
          } else {
            message.error('记录消费失败')
          }
        })
      }
    })
  }

  handleCancel = () => {
    this.setState({ visible: false, record: {} })
    this.formRef.props.form.resetFields()
  }

  handleShowCreate = () => {
    this.setState({ createVisible: true })
  }

  handleCreateFormRef = formRef => {
    this.createFormRef = formRef;
  };

  renderCreateModal = () => {
    const { intl } = this.props;
    const { formatMessage } = intl;
    return (
      <Modal
        title='添加无预约用户消费记录'
        visible={this.state.createVisible}
        onOk={this.handleCreateOk}
        onCancel={this.handleCreateCancel}
      >
        <CreateNewForm wrappedComponentRef={this.handleCreateFormRef} {...this.props} />
      </Modal>
    )
  }

  handleCreateOk = () => {
    const { record } = this.state
    this.createFormRef.props.form.validateFields((err, values) => {
      if (!err) {
        service.saveIncomes(record.id, values).then(data => {
          message.success('记录消费成功')
          this.setState({ isReload: true })
          this.handleCancel()
        })
      }
    })
  }

  handleCreateCancel = () => {
    this.setState({ createVisible: false, record: {} })
    this.createFormRef.props.form.resetFields()
  }
}

export default injectIntl(Reservations)