import React from 'react';
import { Select } from 'antd';
import DictUtil from 'utils/dictUtil'

const { Option } = Select

class DictSelect extends React.Component {

  _getItems = (dictName) => {
    const Dict = new DictUtil().getDict(this.props)
    return Dict[dictName] || []
  }

  _createOptions = (items = []) => {
    let options = [];
    items.forEach(d => {
      options.push(
        <Option key={d.id} value={d.id}>{d.name}</Option>
      )
    })
    return options;
  }

  _handleChange = (value) => {
    const { onChange } = this.props
    onChange && onChange(value)
  }

  render() {
    const { dictName = '', placeholder, intl } = this.props
    const { formatMessage } = intl
    const items = this._getItems(dictName)
    const options = this._createOptions(items);
    const style = Object.assign({}, { width: 200 }, this.props.style)
    return (
      <div>
        <Select style={style} placeholder={placeholder} onChange={this._handleChange} getPopupContainer={() => document.getElementById('content')}>
          <Option value=''>{formatMessage({ id: 'all' })}</Option>
          {options}
        </Select>
      </div>
    )
  }

}

export default DictSelect;
