
function setCookie(key, value) {
  var Days = 30;
  var exp = new Date();
  exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 30);
  document.cookie = key + "=" + escape(value) + ";expires=" + exp.toGMTString();
}

function getAllCookies() {
  var cookies = document.cookie.split(/;\s/g);
  var cookieObj = {};
  cookies.forEach(function (item) {
    var key = item.split('=')[0];
    cookieObj[key] = item.split('=')[1];
  });
  return cookieObj;
}

function getCookie(key) {
  var arr, reg = new RegExp("(^| )" + key + "=([^;]*)(;|$)");
  if (arr === document.cookie.match(reg))
    return unescape(arr[2]);
  else
    return null;
}

function clearCookie(key) {
  setCookie(key, "");
}

function clearAllCookie() {
  var keys = Object.keys(getAllCookies());
  keys.forEach(key => {
    clearCookie(key)
  })
}

function setLocalStorage(key, value) {
  localStorage[key] = value
}

function getLocalStorage(key) {
  return localStorage[key]
}

function clearLocalStorage(key) {
  localStorage.removeItem(key)
}

function clearAllLocalStorage() {
  localStorage.clear()
}

function setStorage(key, value) {
  window.localStorage ? setLocalStorage(key, value) : setCookie(key, value)
}

function getStorage(key) {
  return window.localStorage ? getLocalStorage(key) : getCookie(key)
}

function clearStorage(key) {
  window.localStorage ? clearLocalStorage(key) : clearCookie(key)
}

function clearAllStorage() {
  window.localStorage ? clearAllLocalStorage() : clearAllCookie()
}

class LocalStorageUtil {

  setPageSize(value) {
    setStorage('pageSize', value)
  }

  getPageSize() {
    return Number(getStorage('pageSize')) || 10
  }

  setLocale(value) {
    setStorage('locale', value)
  }

  getLocale() {
    return getStorage('locale')
  }

  setAuth(value) {
    setStorage('auth', value)
  }

  getAuth() {
    return getStorage('auth')
  }

  setUserInfo(value) {
    setStorage('userInfo', value)
  }

  getUserInfo() {
    return getStorage('userInfo')
  }

  setPrivileges(value) {
    setStorage('privileges', value)
  }

  setCookie(key, value) {
    return setCookie(key, value)
  }

  getPrivileges() {
    return getStorage('privileges')
  }

  setStorage(key, value) {
    return setStorage(key, value)
  }

  getStorage(key) {
    return getStorage(key)
  }

  clearStorage(key) {
    clearStorage(key)
  }

  clearAllStorage() {
    clearAllStorage()
  }

  clearAllCookie() {
    clearAllCookie()
  }

}

export default LocalStorageUtil;