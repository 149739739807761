
import SpreadStatistics from './index'
import Path from 'routes/path'

const routes = [
    {
        id: 'spread_statistics',
        name: 'spread_statistics',
        component: SpreadStatistics,
        path: Path.pathname['SPREAD_STATISTICS'],
        key: 'spread_statistics',
    },
]

export default {
    routes
}