import React from 'react'
import { injectIntl } from 'react-intl';
import { Button, Divider, Modal, message } from 'antd';
import TablePage from 'components/TablePage'
import TextMore from 'components/TextMore'
import SearchType from 'utils/searchType'
import service from './service'
import Path from 'routes/path'

/**
 * 会员管理页面
 */

class Monitor extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isReload: false,
    }
  }

  render() {
    const { intl } = this.props;
    const { formatMessage } = intl;
    const { isReload } = this.state;

    const fields = [
      { name: 'key', desc: '', render: (text, record, index) => index + 1, width: 50 },
      // { name: 'id', desc: 'id', hide: true },
      {
        name: 'user.real_name', desc: formatMessage({ id: 'real_name' }), sorter: true, showDetail: true, key: 'keyword', query: true, render: (text, record) => {
          return text || record.user.nick_name || record.user.id
        }, showRender: true
      },
      { name: 'first_recommend_count', desc: formatMessage({ id: 'first_recommend_user' }), sorter: true },
      { name: 'second_recommend_count', desc: formatMessage({ id: 'second_recommand_user' }), sorter: true },
      { name: 'user.register_time', desc: formatMessage({ id: 'register_time' }), type: SearchType.KEY_DATE_TIME, sorter: true },
      // {
      //   name: 'action', desc: formatMessage({ id: 'action' }), width: 250,
      //   render: (text, record) => (
      //     <span>
      //       <a>查看详情</a>
      //     </span>
      //   )
      // },
    ]

    const insertBtns = []
    return (
      <div>
        <div className="content-header">
          <h2>{formatMessage({ id: 'spread_statistics' })}</h2>
        </div>
        <div className="content-body">
          <TablePage
            rowKey={(record) => record.user.id }
            name={formatMessage({ id: 'spread_statistics' })}
            fields={fields}
            insertBtns={insertBtns}
            {...service}
            // loadData={service.getDatas}
            isReload={isReload}
          />
        </div>
      </div>
    )
  }

}

export default injectIntl(Monitor)