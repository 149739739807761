import React from 'react'
import { injectIntl } from 'react-intl';
import { Button, Divider, Modal, message, Spin, Empty } from 'antd';
import TablePage from 'components/TablePage'
import SearchType from 'utils/searchType'
import service from './service'
import memberService from 'modules/MemberLevel/service'
import Path from 'routes/path'
import ReactEcharts from './components/ReactEcharts'
import BizCharts from './components/BizCharts'
import SimpleSearch from 'components/SimpleSearch';

/**
 * 会员统计页面
 */

class Monitor extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      datas: [],
      loading: false,
      memberPlans: [],
      items: []
    }
  }

  componentDidMount() {
    this.getDatas()
  }

  getDatas = async (params) => {
    this.setState({ loading: true })
    const datas = await service.getDatas()
    const memberPlans = await memberService.getDatas()
    this.setState({
      datas: datas.data,
      memberPlans: memberPlans.data,
      loading: false,
      items: memberPlans.data
        .sort((a, b) => a.human_price * 1 - b.human_price * 1)
        .map(i => {
          return {
            ...i,
            count: (datas.data.find(j => j.group_by === i.id) ? datas.data.find(j => j.group_by === i.id).count : 0)
          }
        })
    })
  }

  render() {
    const { intl } = this.props;
    const { formatMessage } = intl;
    const { datas = [], loading, items = [] } = this.state;
    let total = 0;
    datas.forEach(a => {
      total += a.count
    })
    const fields = [
      { name: 'time', desc: formatMessage({ id: 'time_period' }), query: true, type: SearchType.KEY_DATE_TIME_RANGE }
    ]
    return (
      <div>
        <div className="content-header">
          <h2>{formatMessage({ id: 'member_statistics' })}</h2>
        </div>
        <div className="content-body">
          <Spin spinning={loading}>
            <div style={{ overflow: 'hidden' }}>
              <span>{formatMessage({ id: 'total_number' }, { total })}</span>
              <div style={{ float: 'right' }}>
                <SimpleSearch {...this.props} fields={fields} handleSearch={this.getDatas} />
              </div>
            </div>
            {
              datas.length
                ? <BizCharts datas={items} />
                : <div style={{ padding: '100px 0' }}><Empty /></div>
            }
          </Spin>
        </div>
      </div>
    )
  }

  renderOld = () => {
    const { datas = [], loading, memberPlans = [] } = this.state;
    const options = {
      color: ['#3398DB'],
      tooltip: {
        show: true,
        trigger: 'axis',
        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
          type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
        }
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        // data: datas.map(i => memberPlans.find(j => i.group_by === j.id).name), 
        data: memberPlans.map(i => i.name)
      },
      yAxis: {
        type: 'value',
        min: 0,
        max: function (value) { return value.max < 10 ? 10 : value.max },
        minInterval: 1
      },
      series: [{
        type: 'bar',
        // data: datas.map(i => i.count),
        barWidth: '100px',
        data: memberPlans.map(i => datas.find(j => j.group_by === i.id) ? datas.find(j => j.group_by === i.id).count : 0),
        itemStyle: {
          normal: {
            label: {
              show: true,  //开启显示
              position: 'top',  //在上方显示
              textStyle: {  //数值样式
                color: 'black',
                fontSize: 16
              }
            }
          }
        },
      }]
    };
    return (
      <ReactEcharts options={options} />
    )
  }

}

export default injectIntl(Monitor)