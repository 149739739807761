import React from "react";
import DragM from "dragm";

class DragTitle extends React.Component {
  updateTransform = transformStr => {
    for (var i = 0, len = this.modalDom.length; i < len; i++) {
      this.modalDom[i].style.transform = transformStr
      this.modalDom[i].style['-ms-transform'] = transformStr
    }
  };
  componentDidMount() {
    this.modalDom = this.props.className
      ? document.getElementsByClassName(this.props.className)[0].getElementsByClassName("ant-modal-content")
      : document.getElementsByClassName("ant-modal-content")
  }
  componentWillUnmount() {
    for (var i = 0, len = this.modalDom.length; i < len; i++) {
      this.modalDom[i].style.transform = ''
      this.modalDom[i].style['-ms-transform'] = ''
    }
  }
  render() {
    const { title } = this.props;
    return (
      <DragM updateTransform={this.updateTransform}>
        <div>{title}</div>
      </DragM>
    );
  }
}

export default DragTitle