import React, { forwardRef, useState } from 'react';
import { Modal, Button, message } from 'antd';
import DragTitle from 'components/DragTitle'
import ProductList from './components/ProductList'

const SelectProduct = (props) => {
  const { intl, onChange, record = {} } = props
  const { formatMessage } = intl
  const [visible, setVisible] = useState(false)
  const [value, setValue] = useState({})

  const handleOk = () => {
    if (!(value && value.id)) {
      message.error('请选择产品')
      return
    }
    onChange && onChange(value.id)
    setVisible(false)
  }

  const onSelect = (vals) => {
    setValue(vals && vals[0])
  }
  return (
    <div>
      <span style={{ display: 'inline-block', marginRight: 10 }}>{(value && value.title) || (record.product && record.product.title)}</span>
      <a onClick={() => setVisible(true)}>{formatMessage({ id: 'select_product' })}</a>
      <Modal
        width={1000}
        title={<DragTitle title={formatMessage({ id: 'select_product' })} className='detail_modal' />}
        visible={visible}
        onOk={handleOk}
        onCancel={() => setVisible(false)}
        footer={[
          <Button key='cancel' onClick={() => setVisible(false)}>{formatMessage({ id: 'cancel' })}</Button>,
          <Button key='ok' onClick={() => handleOk()} type='primary' style={{ marginLeft: 10 }}>{formatMessage({ id: 'ok' })}</Button>,
        ]}
        wrapClassName='detail_modal'
      >
        <ProductList {...props} onSelect={onSelect} />
      </Modal>
    </div>
  )
}

export default forwardRef((props, ref) => <SelectProduct {...props} />)