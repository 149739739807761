import React from 'react'
import { injectIntl } from 'react-intl';
import { Button, Divider, Modal, message } from 'antd';
import TablePage from 'components/TablePage'
import ChargeList from './components/ChargeList'
import SearchType from 'utils/searchType'
import service from './service'
import Path from 'routes/path'
import CreateForm from './form'

/**
 * 已预约列表页面
 */

class Reservations extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isReload: false,
      visible: false,
    }
  }

  render() {
    const { intl } = this.props;
    const { formatMessage } = intl;
    const { isReload } = this.state;

    const fields = [
      { name: 'key', desc: '', render: (text, record, index) => index + 1, width: 50 },
      {
        name: 'user', desc: formatMessage({ id: 'real_name' }), render: (text) => {
          return text && (text.real_name || text.nick_name || text.id)
        }, showRender: true
      },
      { name: 'date', desc: formatMessage({ id: 'reservation_date' }), type: SearchType.KEY_DATE },
      { name: 'complete_time', desc: formatMessage({ id: 'create_time' }), type: SearchType.KEY_DATE_TIME, sorter: true },
      {
        name: 'action', desc: formatMessage({ id: 'action' }), width: 250, listActions: true,
        render: (text, record) => {
          return record.status === 'COMPLETED' || !record.status
            ? [
              <a onClick={() => this.handleShow(record)}>记录消费</a>,
              <ChargeList {...this.props} record={record} />
            ]
            : []
        }
      },
    ]

    const insertBtns = []
    return (
      <div>
        <div className="content-header">
          <h2>{formatMessage({ id: 'reservaction_today' })}</h2>
        </div>
        <div className="content-body">
          <TablePage
            rowKey='id'
            name={formatMessage({ id: 'reservation' })}
            fields={fields}
            insertBtns={insertBtns}
            {...service}
            // loadData={service.getDatas}
            isReload={isReload}
            autoRefresh
          />
        </div>
        {this.renderModal()}
      </div>
    )
  }

  handleShow = (record) => {
    this.setState({ visible: true, record })
  }

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  renderModal = () => {
    const { intl } = this.props;
    const { formatMessage } = intl;
    const { record } = this.state;
    return (
      <Modal
        title='记录消费'
        visible={this.state.visible}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
      >
        <CreateForm wrappedComponentRef={this.saveFormRef} {...this.props} record={record} />
      </Modal>
    )
  }

  handleOk = () => {
    const { record } = this.state
    this.formRef.props.form.validateFields((err, values) => {
      if (!err) {
        service.saveIncomes(record.id, values).then(data => {
          message.success('记录消费成功')
          this.setState({ isReload: true })
          this.handleCancel()
        })
      }
    })
  }

  handleCancel = () => {
    this.setState({ visible: false, record: {} })
    this.formRef.props.form.resetFields()
  }
}

export default injectIntl(Reservations)