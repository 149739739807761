
import Order from './index'
import Path from 'routes/path'

const routes = [
    {
        id: 'order',
        name: 'order',
        component: Order,
        path: Path.pathname['ORDER'],
        key: 'order',
    },
]

export default {
    routes
}