import React, { forwardRef, useState } from 'react';
import { Modal, Button, message } from 'antd';
import DragTitle from 'components/DragTitle'
import List from './components/List'

const ChangeSuperPartner = (props) => {
  const { intl, onChange, record = {}, create, handleRefresh } = props
  const { formatMessage } = intl
  const [visible, setVisible] = useState(false)
  const [value, setValue] = useState({})

  const handleOk = () => {
    if (!(value && value.user && value.user.id)) {
      message.error('请选择用户')
      return
    }
    create && create(record && record.user && record.user.id, {
      "recommend_user_id": value.user.id,
      // "user_id": value.id
    }).then(data => {
      message.success('修改上级志愿成功')
      setVisible(false)
      handleRefresh && handleRefresh()
    }).catch(error => {
      message.error('修改上级志愿失败')
    })
  }

  const onSelect = (vals) => {
    setValue(vals && vals[0])
  }
  return (
    <>
      <a onClick={() => setVisible(true)}>{props.title || formatMessage({ id: 'add_partner' })}</a>
      <Modal
        width={800}
        title={<DragTitle title='修改上级志愿者' className='detail_modal' />}
        visible={visible}
        onOk={handleOk}
        onCancel={() => setVisible(false)}
        footer={[
          <Button key='cancel' onClick={() => setVisible(false)}>{formatMessage({ id: 'cancel' })}</Button>,
          <Button key='ok' onClick={() => handleOk()} type='primary' style={{ marginLeft: 10 }}>{formatMessage({ id: 'ok' })}</Button>,
        ]}
        wrapClassName='detail_modal'
      >
        <List {...props} onSelect={onSelect} />
      </Modal>
    </>
  )
}

export default forwardRef((props, ref) => <ChangeSuperPartner {...props} />)