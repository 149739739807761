
// import protojs from '../../src/proto/proto.js';

class DictUtil {

  getDict(props) {
    if (!props || !protojs) return
    return this.getProto(props)
  }

  getProtoArray(props, key) {
    return this.getProto(props)[key]
  }

  getProto(props) {
    var obj = protojs
    var newObj = {}
    Object.keys(obj).forEach(i => {
      var arr = this.getArrayFromObject(obj[i]['Enum'] ? obj[i]['Enum'] : obj[i])
      const { intl } = props;
      const { formatMessage } = intl;
      arr = arr.map(a => {
        var id = `${i}_${a.id}`
        var msg = formatMessage({ id })
        return {
          ...a,
          name: msg !== id ? msg : a.id
        }
      })
      newObj[i] = arr
    })
    return newObj
  }

  getArrayFromObject(obj) {
    var arr = []
    Object.keys(obj).forEach(i => {
      arr.push({ value: obj[i], id: i })
    })
    arr = arr.sort((a, b) => a.id - b.id).filter(i => i.value)
      .filter(i => !(i.id === 'NFS' || i.id === 'Local'))  // 去除StorageType 中的NFS和LOCAL
    return arr
  }

}

export default DictUtil;