import React from 'react'
import { injectIntl } from 'react-intl';
import { Button, Divider, Modal, message } from 'antd';
import TablePage from 'components/TablePage'
import TextMore from 'components/TextMore'
import SearchType from 'utils/searchType'
import service from './service'
import productService from '../ProductList/service'
import Path from 'routes/path'

/**
 * 积分商城页面
 */

class PointProduct extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isReload: false,
      productOptions: []
    }
  }

  componentDidMount() {
    productService.getDatas().then(data => {
      this.setState({ productOptions: data.data.items.map(i => ({ name: i.title, value: i.id })) })
    })
  }

  render() {
    const { intl } = this.props;
    const { formatMessage } = intl;
    const { isReload, productOptions } = this.state;
    const options = [
      { value: 'ONLINE', name: formatMessage({ id: 'status_online' }) },
      { value: 'OFFLINE', name: formatMessage({ id: 'status_offline' }) },
      // { value: 3, name: '初始' }
    ]

    const fields = [
      { name: 'key', desc: '', render: (text, record, index) => index + 1, width: 50 },
      { name: 'product.cover_image', desc: formatMessage({ id: 'cover' }), hide: true, render: (text) => <img style={{ width: 300 }} src={text} alt='封面' />, showRender: true, cardCover: true },
      { name: 'title', desc: formatMessage({ id: 'name' }), showDetail: true, query: true, cardTitle: true, form: { type: 'input' } },
      { name: 'price', desc: formatMessage({ id: 'price' }), form: { type: 'inputNumber' }, cardContent: true, render: (text) => `${text || 0}积分`, showRender: true },
      { name: 'display_price', desc: formatMessage({ id: 'display_price' }), form: { type: 'inputNumber' }, cardDeleteContent: true, render: (text) => `${text || 0}积分`, showRender: true },
      { name: 'product.id', desc: formatMessage({ id: 'product' }), hide: true, hideInDetail: true, key: 'product_id', options: productOptions, form: { type: 'select' } },
      {
        name: 'status', desc: formatMessage({ id: 'status' }), query: true, type: SearchType.KEY_CUSTOM_TABS, showRender: true, options, form: { type: 'select' },
        render: (text) => options.find(i => i.value === text) && options.find(i => i.value === text).name
      },
      { name: 'create_time', desc: formatMessage({ id: 'create_time' }), type: SearchType.KEY_DATE_TIME },
      {
        name: 'action', desc: formatMessage({ id: 'action' }), width: 250, listActions: true,
        render: (text, record) => {
          return [
            <a onClick={() => this.props.history.push(`/mkt/point_edit?id=${record.id}`)}>{formatMessage({ id: 'edit' })}</a>,
            <a onClick={() => this.handleProduct(record)}>{record.status === 'ONLINE' ? formatMessage({ id: 'offline' }) : formatMessage({ id: 'online' })}</a>
          ]
        }
      },
    ]

    const insertBtns = [<Button key='create' type='primary' icon='plus' onClick={() => this.props.history.push('/mkt/point_create')}>{formatMessage({ id: 'create' })}</Button>]
    return (
      <div>
        <div className="content-header">
          <h2>{formatMessage({ id: 'point_list' })}</h2>
        </div>
        <div className="content-body">
          <TablePage
            rowKey='id'
            name={formatMessage({ id: 'point_product' })}
            fields={fields}
            {...service}
            insertBtns={insertBtns}
            loadData={service.getDatas}
            isReload={isReload}
            showTabs
            tabsStyle={{
              marginTop: '-165px',
              marginBottom: '125px'
            }}
            // cardActions={[
            //   <a>{Math.random() > 0.5 ? '上架' : '下架'}</a>,
            //   <a>编辑</a>,
            //   <a>删除</a>,
            // ]}
            // showCreate
            // showEdit
            showDelete
            cardType='point'
          />
        </div>
      </div>
    )
  }

  handleProduct = ({ id, status }) => {
    const { intl } = this.props;
    const { formatMessage } = intl;
    Modal.confirm({
      title: formatMessage({ id: 'tip' }),
      content: formatMessage({ id: 'confirm_tip' }, { status: formatMessage({ id: status === 'ONLINE' ? 'offline' : 'online' }) }),
      onOk: () => {
        service[status === 'ONLINE' ? 'offlineData' : 'onlineData'](id).then(data => {
          message.success(`${formatMessage({ id: status === 'ONLINE' ? 'offline' : 'online' })}${formatMessage({ id: 'success' })}`)
          this.setState({
            isReload: true
          }, () => {
            this.setState({ isReload: false })
          })
        })
      },
      onCancel: () => { }
    })
  }

}

export default injectIntl(PointProduct)