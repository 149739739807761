import React from 'react'
import { injectIntl } from 'react-intl';
import { Button, Divider, Modal, message, Spin } from 'antd';
import TablePage from 'components/TablePage'
import TextMore from 'components/TextMore'
import SearchType from 'utils/searchType'
import service from './service'
import newsCategoryService from '../NewsCategory/service'
import Path from 'routes/path'
import utils from 'utils/util'
import Detail from 'components/TablePage/components/detail'
import './index.css';

/**
 * 商品管理页面
 */

class Product extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isReload: false,
      newsCategoryOptions: [],
      record: {},
      loading: false
    }
    this.id = utils.getQueryString('id')
  }

  componentDidMount() {
    newsCategoryService.getDatas().then(data => {
      this.setState({ newsCategoryOptions: data.data.map(i => ({ value: i.id, name: i.display_name })) })
    })
    this.getDataDetail()
  }

  getDataDetail = () => {
    this.setState({ loading: true })
    service.getDataDetail(this.id).then(data => {
      this.setState({ record: data.data, loading: false })
    })
  }

  render() {
    const { intl } = this.props;
    const { formatMessage } = intl;
    const { isReload, newsCategoryOptions, record, loading } = this.state;
    const options = [
      { value: 'ONLINE', name: formatMessage({ id: 'status_online' }) },
      { value: 'OFFLINE', name: formatMessage({ id: 'status_offline' }) },
      // { value: '', name: '初始' }
    ]

    const fields = [
      { name: 'title', desc: formatMessage({ id: 'title' }) },
      { name: 'sub_title', desc: formatMessage({ id: 'sub_title' }) },
      { name: 'create_time', desc: formatMessage({ id: 'create_time' }), type: SearchType.KEY_DATE_TIME },
      {
        name: 'cover_image', desc: formatMessage({ id: 'cover' }),
        render: (text) => text ? <img style={{ height: 80, width: 80 }} src={text} alt='封面' /> : '暂无封面'
      },
      { name: 'article_category_id', desc: formatMessage({ id: 'news_category' }), type: SearchType.KEY_CUSTOM_OPTION, options: newsCategoryOptions },
      {
        name: 'images', desc: formatMessage({ id: 'images' }), hide: true, render: (text) => {
          return <>
            {
              text && text.length ? text.map((i, d) => {
                return <img key={d} style={{ height: 80, width: 80 }} src={i} alt={formatMessage({ id: 'images' })} />
              }) : '-'
            }
          </>
        }
      },
      { name: 'status', desc: formatMessage({ id: 'status' }), query: true, showRender: true, type: SearchType.KEY_CUSTOM_TABS, options, render: (text) => options.find(i => i.value === text) && options.find(i => i.value === text).name },
      {
        name: 'content_type', desc: formatMessage({ id: 'news_type' }),
        type: SearchType.KEY_CUSTOM_OPTION, options: [{ value: 'LINK', name: '链接' }, { value: 'RAW', name: '内容' }]
      },
      {
        name: 'content', desc: formatMessage({ id: 'content' }),
        render: (text, record) => <>
          <div className="content-html" dangerouslySetInnerHTML={{ __html: text }} />
          {
            record.content_type === 'LINK'
              ? <a className="ant-btn ant-btn-sm" href={text} target="_blank" rel="noopener noreferrer">{formatMessage({ id: 'preview' })}</a>
              : <Button size='small' onClick={() => this.preview(record)}>{formatMessage({ id: 'preview' })}</Button>
          }
        </>
      },
      
    ]

    return (
      <div>
        <div className="content-header">
          <h2>{formatMessage({ id: 'news_detail' })}</h2>
        </div>
        <div className="content-body">
          <Spin spinning={loading}>
            <Detail {...this.props} record={record} fields={fields} />
            <Divider />
            <div>
              <Button onClick={() => this.props.history.goBack()}>{formatMessage({ id: 'back' })}</Button>
            </div>
          </Spin>
        </div>
      </div>
    )
  }

  preview = (record) => {

    if (window.previewWindow) {
      window.previewWindow.close()
    }

    window.previewWindow = window.open()
    window.previewWindow.document.write(this.buildPreviewHtml(record))
    window.previewWindow.document.close()

  }

  buildPreviewHtml(record) {
    const { intl } = this.props;
    const { formatMessage } = intl;

    return `
      <!Doctype html>
      <html>
        <head>
          <title>${formatMessage({ id: 'preview' })}</title>
          <style>
            html,body{
              height: 100%;
              margin: 0;
              padding: 0;
              overflow: auto;
              background-color: #f1f2f3;
            }
            .container{
              box-sizing: border-box;
              width: 1000px;
              max-width: 100%;
              min-height: 100%;
              margin: 0 auto;
              padding: 30px 20px;
              overflow: hidden;
              background-color: #fff;
              border-right: solid 1px #eee;
              border-left: solid 1px #eee;
            }
            .container img,
            .container audio,
            .container video{
              max-width: 100%;
              height: auto;
            }
            .container p{
              white-space: pre-wrap;
              min-height: 1em;
              line-height: 1.5;
            }
            .container pre{
              padding: 15px;
              background-color: #f1f1f1;
              border-radius: 5px;
            }
            .container blockquote{
              margin: 0;
              padding: 15px;
              background-color: #f1f1f1;
              border-left: 3px solid #d1d1d1;
            }
            h1 {
              font-size: 20px;
            }
            h2 {
              font-size: 14px;
              font-weight: normal;
              color: #999;
            }
          </style>
        </head>
        <body>
          <div class="container">
            <h1>${record.title}</h1>
            <h2>${record.sub_title}</h2>
            ${record.content}
          </div>
        </body>
      </html>
    `

  }

  handleData = ({ id, status }) => {
    const { intl } = this.props;
    const { formatMessage } = intl;
    Modal.confirm({
      title: formatMessage({ id: 'tip' }),
      content: formatMessage({ id: 'confirm_tip' }, { status: formatMessage({ id: status === 'ONLINE' ? 'offline' : 'online' }) }),
      onOk: () => {
        service[status === 'ONLINE' ? 'offlineData' : 'onlineData'](id).then(data => {
          message.success(`${formatMessage({ id: status === 'ONLINE' ? 'offline' : 'online' })}${formatMessage({ id: 'success' })}`)
          this.setState({
            isReload: true
          }, () => {
            this.setState({ isReload: false })
          })
        })
      },
      onCancel: () => { }
    })
  }

}

export default injectIntl(Product)