import React from 'react'
import { injectIntl } from 'react-intl';
import { Button, Divider, Modal, message, Form, InputNumber, Input, Radio } from 'antd';
import SelectPartner from './components/SelectPartner'
import SelectUser from './components/SelectUser'

/**
 * 会员管理页面
 */

class CreateForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    return (
      <Form {...formItemLayout}>
        <Form.Item label='输入消费金额'>
          {getFieldDecorator('fee', {
            rules: [{ required: true, message: '请输入消费金额' }]
          })(
            <InputNumber style={{ width: '50%' }} placeholder='请如实输入患者消费记录' />,
          )} 元
        </Form.Item>
        {/* <Form.Item label='选择消费者'>
          {getFieldDecorator('user_id', {
            rules: [{ required: true, message: '请选择消费者' }]
          })(
            <SelectUser {...this.props} />,
          )}
        </Form.Item> */}
        <Form.Item label='真实姓名'>
          {getFieldDecorator('real_name', {
            rules: [{ required: true, message: '请输入真实姓名' }]
          })(
            <Input  />,
          )}
        </Form.Item>
        <Form.Item label='电话'>
          {getFieldDecorator('phone', {
            rules: [{ required: true, message: '请输入电话' }]
          })(
            <Input  />,
          )}
        </Form.Item>
        <Form.Item label='选择推荐志愿者'>
          {getFieldDecorator('recommend_partner_id', {
            rules: [{ required: true, message: '请选择推荐志愿者' }]
          })(
            <SelectPartner {...this.props} />,
          )}
        </Form.Item>
        <Form.Item label='备注'>
          {getFieldDecorator('remark', {
            // rules: [{ required: true, message: '请输入备注' }]
          })(
            <Input.TextArea placeholder='无预约用户必须填写备注信息' />,
          )}
        </Form.Item>
      </Form>
    )
  }

}

const WrappedForm = Form.create({ name: 'form' })(CreateForm);

export default injectIntl(WrappedForm)