import React from 'react'
import { injectIntl } from 'react-intl';
import { Button, Divider, Modal, message } from 'antd';
import TablePage from 'components/TablePage'
import UserLabel from 'components/UserLabel'
import SearchType from 'utils/searchType'
import service from './service'
import Path from 'routes/path'

/**
 * 会员管理页面
 */

class Monitor extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isReload: false,
    }
  }

  render() {
    const { intl } = this.props;
    const { formatMessage } = intl;
    const { isReload } = this.state;

    const fields = [
      { name: 'key', desc: '', render: (text, record, index) => index + 1, width: 50 },
      {
        name: 'user_id', desc: formatMessage({ id: 'username' }), query: true,
        render: (text) => <UserLabel id={text} />
      },
      { name: 'product.title', desc: formatMessage({ id: 'product' }) },
      { name: 'price', desc: formatMessage({ id: 'price' }), render: (text) => `${text || 0}积分`, showRender: true },
      { name: 'create_time', desc: formatMessage({ id: 'exchange_time' }), type: SearchType.KEY_DATE_TIME },
      // {
      //   name: 'action', desc: formatMessage({ id: 'action' }), width: 250,
      //   render: (text, record) => (
      //     <span>
      //       <a>查看详情</a>
      //     </span>
      //   )
      // },
    ]

    const insertBtns = []
    return (
      <div>
        <div className="content-header">
          <h2>{formatMessage({ id: 'point_record' })}</h2>
        </div>
        <div className="content-body">
          <TablePage
            rowKey='id'
            name={formatMessage({ id: 'point_record' })}
            fields={fields}
            {...service}
            insertBtns={insertBtns}
            isReload={isReload}
          />
        </div>
      </div>
    )
  }

}

export default injectIntl(Monitor)