import React from 'react';
import { Chart, Interval, Tooltip, Geom, Axis } from 'bizcharts';

const BizCharts = (props) => {
  const { datas = [] } = props
  const scale = {
    x: {
      type: 'cat',
    },
    y: {
      min: 0,
    },
  };
  const tooltip = [
    'x*y',
    (x, y) => ({
      name: x,
      value: y
    })
  ]
  return (
    <div style={{ padding: 40 }}>
      <Chart
        height={600}
        autoFit
        data={datas}
        interactions={['active-region']}
        padding="auto" >
        <Interval position="name*count" />
        {/* <Axis name="x" title={false} />
        <Axis name="y" min={0} /> */}
        <Tooltip shared />
        {/* <Geom type="interval" position="x*y" tooltip={tooltip} /> */}
      </Chart>
    </div>
  )
}

export default BizCharts 