// exports['zh-CN'] = require('../messages/zh-CN/index.json')
// exports['en-US'] = require('../messages/en-US/index.json')

var zhCNMain = require('../messages/zh-CN/index.json')
var zhCNEnum = require('../messages/zh-CN/enum.json')
var zhCNPrivilege = require('../messages/zh-CN/privilege.json')
var enUSMain = require('../messages/en-US/index.json')
var enUSEnum = require('../messages/en-US/enum.json')
var enUSPrivilege = require('../messages/en-US/privilege.json')

var zhCN = Object.assign({}, zhCNMain, zhCNEnum, zhCNPrivilege)
var enUS = Object.assign({}, enUSMain, enUSEnum, enUSPrivilege)

exports['zh-CN'] = zhCN
exports['en-US'] = enUS
