import React, { forwardRef, useState, useEffect } from 'react';
import { Modal, Button, message, Input } from 'antd';
import DragTitle from 'components/DragTitle'
import List from './components/List'
import userService from 'modules/UserList/service'

const SelectPartner = (props) => {
  const { intl, onChange, record = {}, create, handleRefresh } = props
  const { formatMessage } = intl
  const [visible, setVisible] = useState(false)
  const [value, setValue] = useState({})

  useEffect(() => {
    // if (!record.user_id) {
    //   setValue({})
    // }

    const fetchData = async () => {
      const res = await userService.getDataDetail(record.user_id)
      if (res && res.data && res.data.first_recommend_user_id) {
        const res2 = await userService.getDataDetail(res.data.first_recommend_user_id)
        if (res2 && res2.data) {
          setValue({
            user: res2.data
          })
        }
      }
    }

    if (record.user_id) {
      fetchData()
    }
  }, [record])

  const handleOk = () => {
    if (!(value && value.user && value.user.id)) {
      message.error('请选择志愿者')
      return
    }

    onChange && onChange(value.user.id)
    setVisible(false)

    // create && create({
    //   "recommend_user_id": record && record.user && record.user.id,
    //   "user_id": value.user_id
    // }).then(data => {
    //   message.success('添加分享医疗志愿者成功')
    //   setVisible(false)
    //   handleRefresh && handleRefresh()
    // }).catch(error => {
    //   message.error('添加分享医疗志愿者失败')
    // })
  }

  const onSelect = (vals) => {
    setValue(vals && vals[0])
  }
  return (
    <>
      <Input.Group compact>
        <Input style={{ width: 200 }} value={value.user && (value.user.real_name || value.user.nick_name || value.user.id)} disabled />
        <Button onClick={() => setVisible(true)}>{'选择推荐志愿者' || formatMessage({ id: 'add_partner' })}</Button>
      </Input.Group>
      <Modal
        width={800}
        title={<DragTitle title={'选择推荐志愿者' || formatMessage({ id: 'add_partner' })} className='detail_modal' />}
        visible={visible}
        onOk={handleOk}
        onCancel={() => setVisible(false)}
        footer={[
          <Button key='cancel' onClick={() => setVisible(false)}>{formatMessage({ id: 'cancel' })}</Button>,
          <Button key='ok' onClick={() => handleOk()} type='primary' style={{ marginLeft: 10 }}>{formatMessage({ id: 'ok' })}</Button>,
        ]}
        wrapClassName='detail_modal'
      >
        <List {...props} onSelect={onSelect} />
      </Modal>
    </>
  )
}

export default forwardRef((props, ref) => <SelectPartner {...props} />)