import React, { forwardRef, useState, useEffect } from 'react';
import { Modal, Button, message, Input } from 'antd';
import DragTitle from 'components/DragTitle'
import List from './components/List'
import userService from 'modules/UserList/service'

const SelectPartner = (props) => {
  const { intl, onChange, record = {}, create, handleRefresh } = props
  const { formatMessage } = intl
  const [visible, setVisible] = useState(false)
  const [value, setValue] = useState({})

  const handleOk = () => {
    if (!(value && value.id)) {
      message.error('请选择用户')
      return
    }

    onChange && onChange(value.id)
    setVisible(false)
  }

  const onSelect = (vals) => {
    setValue(vals && vals[0])
  }
  return (
    <>
      <Input.Group compact>
        <Input style={{ width: 200 }} value={value && (value.real_name || value.nick_name || value.id)} disabled />
        <Button onClick={() => setVisible(true)}>{'选择用户' || formatMessage({ id: 'add_partner' })}</Button>
      </Input.Group>
      <Modal
        width={800}
        title={<DragTitle title={'选择用户' || formatMessage({ id: 'add_partner' })} className='detail_modal' />}
        visible={visible}
        onOk={handleOk}
        onCancel={() => setVisible(false)}
        footer={[
          <Button key='cancel' onClick={() => setVisible(false)}>{formatMessage({ id: 'cancel' })}</Button>,
          <Button key='ok' onClick={() => handleOk()} type='primary' style={{ marginLeft: 10 }}>{formatMessage({ id: 'ok' })}</Button>,
        ]}
        wrapClassName='detail_modal'
      >
        <List {...props} onSelect={onSelect} />
      </Modal>
    </>
  )
}

export default forwardRef((props, ref) => <SelectPartner {...props} />)