import React from 'react';
import { injectIntl } from 'react-intl';
import { Result, Button } from 'antd';
import Path from 'routes/path';

const Error403 = (props) => {
  const { intl } = props
  const { formatMessage } = intl
  return (
    <Result
      status="403"
      title="403"
      subTitle={formatMessage({ id: '403_error_tip' })}
      extra={<span>
        <Button type="primary" onClick={() => props.history.goBack()}>{formatMessage({ id: 'back_to_previous_page' })}</Button>
        <Button type="primary" style={{ marginLeft: 10 }} onClick={() => props.history.push(Path.getPathname(props, Path.pathname['HOME']))}>{formatMessage({ id: 'back_home' })}</Button>
      </span>}
    />
  )
}

export default injectIntl(Error403);