import React from 'react'
import { injectIntl } from 'react-intl';
import { Button, Divider, Modal, message, Tabs, Descriptions } from 'antd';
import TablePage from 'components/TablePage'
import SearchType from 'utils/searchType'
import service from './service'
import Path from 'routes/path'
import utils from 'utils/util'
const { TabPane } = Tabs;

/**
 * 会员详情页面
 */

class Monitor extends React.Component {

  constructor(props) {
    super(props);
    const search = this.props.location.search;
    this.id = utils.getStrQuery(search, 'id');
    this.state = {
      values: {}
    }
  }

  componentDidMount() {
    this.getDatas(this.id, this.jobType)
  }

  getDatas = (id) => {

  }

  render() {
    const { intl } = this.props;
    const { formatMessage } = intl;
    const { values } = this.state;

    const fields = [
      { name: 'user_id', desc: 'Id', hide: true },
      { name: 'username', desc: '用户名' },
      { name: 'vip', desc: '级别' },
      { name: 'sex', desc: '性别' },
      { name: 'birthday', desc: '生日' },
      { name: 'tel', desc: '固定电话' },
      { name: 'phone', desc: '手机号' },
      { name: 'email', desc: '邮箱' },
      { name: 'address', desc: '地址' },
      { name: 'status', desc: '状态' },
      { name: 'create_time', desc: formatMessage({ id: 'create_time' }), type: SearchType.KEY_DATE_TIME, sorter: true },
    ]

    const insertBtns = []
    return (
      <div>
        <div className="content-header">
          <h2>会员详情</h2>
        </div>
        <div className="content-body">
          <div style={{ marginBottom: 20 }}>

          </div>
          <Tabs defaultActiveKey="1" animated={false} type='card'>
            <TabPane tab='基本信息' key="1">
              <Descriptions bordered>
                {
                  fields.map((field, index) => {
                    return (<Descriptions.Item key={index} label={field.desc} style={{ marginBottom: 0 }} {...field.config}>
                      {
                        field.render && field.showRender
                          ? field.render(utils.getRecordValue(values, field.name), values)
                          : <span className="ant-form-text">{this._renderText(field, values) || '-'}</span>
                      }
                    </Descriptions.Item>)
                  })}
              </Descriptions>
            </TabPane>
            <TabPane tab='积分信息' key="2">
              456
            </TabPane>
            <TabPane tab='订单信息' key="3">
              789
            </TabPane>
            <TabPane tab='优惠券' key="4">
              52345
            </TabPane>
          </Tabs>
        </div>
      </div>
    )
  }

  _renderText = (field, record) => {
    let type = field.type || ''
    let name = field.name || ''

    if (!utils.getRecordValue(record, name)) return '-'

    if (type === 'running_time') {
      return utils.runtimeFormat(record.runtime_info && record.runtime_info.start_time, record.runtime_info && record.runtime_info.end_time, true)
    }

    if (type === SearchType.KEY_DATE) {
      let text = String(utils.getRecordValue(record, name))
      return utils.timeFormat(text, type);
    }

    if (type === SearchType.KEY_DATE_TIME) {
      let text = String(utils.getRecordValue(record, name))
      return utils.timeFormat(text, type);
    }

    if (name === 'size') {
      return utils.sizeFormat(utils.getRecordValue(record, name))
    }

    if (type === SearchType.KEY_JOB_STATUS) {
      return this._renderStatus(utils.getRecordValue(record, name), type)
    }

    return utils.getRecordValue(record, name)
  }

}

export default injectIntl(Monitor)