import React from 'react';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import { Form, Input, Button, Select, DatePicker, Radio } from 'antd';
import SearchType from 'utils/searchType'
import DictSelect from '../DictSelect'
import utils from 'utils/util';
import './index.css'

const { Option } = Select;
const { RangePicker } = DatePicker;

const format = {
  DATE_FORMAT: 'YYYY-MM-DD',
  DATE_TIME_FORMAT: 'YYYY-MM-DD HH:mm:ss'
}

/**
 * 搜索组件
 */
class SimpleSearch extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selectDuration: utils.getMonths()[0].value,
      searchType: 'less'
    }
  }

  componentDidMount() {
    const { fields, handleSearch, form } = this.props;
    const { getFieldsValue } = form
    if (fields.find(field => field.showCustomDuration)) {
      const options = utils.getMonths()
      this.handleChange(options[0].value, options, 'duration')
    }

    if (fields.find(field => field.type === SearchType.KEY_CUSTOM_TABS)) {
      const field = fields.find(field => field.type === SearchType.KEY_CUSTOM_TABS)
      var values = getFieldsValue()
      handleSearch && handleSearch({ ...values, [field.name]: field.options[0].value })
    }
  }

  handleResetSubmit = () => {
    const { handleSearch, form } = this.props
    const { getFieldsValue } = form
    this.setState({ selectDuration: utils.getMonths()[0].value })
    form.resetFields()
    var values = getFieldsValue()
    for (var i in values) {
      values[i] = undefined
      values['begin_time'] = utils.getMonths()[0].range[0]
      values['end_time'] = utils.getMonths()[0].range[1]
    }
    handleSearch && handleSearch(values)
  }

  handleSubmit = e => {
    const { fields = [] } = this.props
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { handleSearch } = this.props
        fields.forEach(field => {
          if (values[field.name]) {
            const type = field.type
            let value = values[field.name]
            switch (type) {
              case SearchType.KEY_DATE:
                value = value ? value.format(format.DATE_FORMAT) : ''
                break;
              case SearchType.KEY_DATE_TIME:
                value = value ? value.format(format.DATE_TIME_FORMAT) : ''
                break;
              case SearchType.KEY_DATE_RANGE:
                value = value[0] && value[1] ? [value[0].format(format.DATE_FORMAT), value[1].format(format.DATE_FORMAT)] : []
                break;
              case SearchType.KEY_DATE_TIME_RANGE:
                value = value[0] && value[1] ? [value[0].format(format.DATE_TIME_FORMAT), value[1].format(format.DATE_TIME_FORMAT)] : []
                break;
              default:
                break
            }
            if (type === SearchType.KEY_DATE_TIME_RANGE || type === SearchType.KEY_DATE_RANGE) {
              delete values[field.name]
              value[0] && (values['begin_time'] = value[0])
              value[1] && (values['end_time'] = value[1])
            } else {
              values[field.name] = value
            }
          }
        })
        handleSearch && handleSearch(values)
      }
    });
  };

  _buildFilterComponent(field) {
    const { intl } = this.props;
    const { formatMessage } = intl;
    let fc = null;
    let type = field.type;
    switch (type) {
      case SearchType.KEY_DATE:
        fc = <DatePicker 
        // onChange={(date, dateString) => new Date(dateString).getTime()}
         />;
        break;
      case SearchType.KEY_DATE_TIME:
        fc = <DatePicker showTime onChange={(date, dateString) => new Date(dateString).getTime()} />;
        break;
      case SearchType.KEY_DATE_RANGE:
        fc = <RangePicker />;
        break;
      case SearchType.KEY_DATE_TIME_RANGE:
        fc = <RangePicker showTime format="YYYY-MM-DD HH:mm:ss" />;
        break;
      case SearchType.KEY_CUSTOM_OPTION:
        fc = <Select style={{ width: 160 }}>
          {[{ value: '', name: formatMessage({ id: 'all' }) }, ...field.options].map((i, d) => <Option key={d} value={i.value}>{i.name}</Option>)}
        </Select>
        break;
      case SearchType.KEY_CUSTOM_TABS:
        fc = <Radio.Group defaultValue={field.options[0].value} onChange={(e) => this.handleChangeRadioGroup(e, field)}>
          {field.options.map((i, d) => <Radio.Button key={d} value={i.value}>{i.name}</Radio.Button>)}
        </Radio.Group>
        break;
      default:
        if (type && type.indexOf(SearchType.KEY_COMMON_ENMU) !== -1) {
          let dictName = type.split('-')[1];
          fc = <DictSelect {...this.props} dictName={dictName} placeholder={formatMessage({ id: 'message_select' }) + field.desc.toLowerCase()} />
        } else {
          fc = <Input placeholder={formatMessage({ id: 'message_input' }) + field.desc.toLowerCase()} />
        }

    }

    return fc
  }

  render() {
    const { intl, fields = [], values = {} } = this.props;
    const { formatMessage } = intl;
    const { getFieldDecorator } = this.props.form;
    const { selectDuration, searchType } = this.state;
    return (
      <div className='search-bar'>
        <Form layout="inline" onSubmit={this.handleSubmit}>
          {
            fields.filter(i => i.type !== SearchType.KEY_CUSTOM_TABS).map((field, index) => {
              return (
                <Form.Item key={index} label={field.desc}>
                  {/* {getFieldDecorator(field.key || field.name, {
                    initialValue: values[field.key || field.name] || undefined
                  })(
                    this._buildFilterComponent(field)
                  )} */}
                  {
                    field.showCustomDuration
                      ? <Select value={selectDuration} style={{ marginRight: 10, width: 120 }} onChange={(value) => this.handleChange(value, field.customDurationOptions, field.name)}>
                        {
                          field.customDurationOptions
                          // .concat({ label: formatMessage({ id: 'customize' }), value: 'custom' })
                          .map((i, d) => {
                            return (
                              <Option key={d} value={i.value}>{i.label}</Option>
                            )
                          })
                        }
                      </Select>
                      : null
                  }
                  <div style={{ display: field.showCustomDuration && selectDuration !== 'custom' ? 'none' : 'inline-block' }}>
                    {getFieldDecorator(field.key || field.name, {
                      initialValue: (field.customDurationOptions && field.customDurationOptions[0].range.map(i => moment(i))) || undefined
                    })(
                      this._buildFilterComponent(field)
                    )}
                  </div>
                </Form.Item>
              )
            })
          }
          <Form.Item>
            <Button onClick={this.handleSubmit}>{formatMessage({ id: 'search' })}</Button>
            <Button onClick={this.handleResetSubmit} style={{ marginLeft: 10 }}>{formatMessage({ id: 'reset' })}</Button>
          </Form.Item>
        </Form>
        {
          fields.filter(i => i.type === SearchType.KEY_CUSTOM_TABS).map((i, d) => {
            return (
              <div key={d} style={{ marginTop: 10 }}>
                <span>{i.desc}：</span>
                {this._buildFilterComponent(i)}
              </div>
            )
          })
        }
      </div>
    )
  }

  handleChange = (value, options, key) => {
    this.setState({ selectDuration: value })
    const selectOpt = options.find(i => i.value === value)
    if (value === 'custom') {
      return
    }
    this.props.form.setFieldsValue({
      [key]: selectOpt.range.map(i => moment(i))
    })
  }

  handleChangeRadioGroup = (e, field) => {
    const { handleSearch, form } = this.props
    const { getFieldsValue } = form
    const { value } = e.target;
    var values = getFieldsValue()
    handleSearch && handleSearch({ ...values, [field.name]: value })

  }

}

const WrappedSimpleSearch = Form.create({ name: 'simple_search_form' })(injectIntl(SimpleSearch));

export default WrappedSimpleSearch

