import React from 'react'
import { injectIntl } from 'react-intl';
import { Button, Divider, Modal, message } from 'antd';
import TablePage from 'components/TablePage'
import TextMore from 'components/TextMore'
import SearchType from 'utils/searchType'
import service from './service'
import Path from 'routes/path'

/**
 * 商品管理页面
 */

class Monitor extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isReload: false,
    }
  }

  render() {
    const { intl } = this.props;
    const { formatMessage } = intl;
    const { isReload } = this.state;

    const fields = [
      { name: 'key', desc: '', render: (text, record, index) => index + 1, width: 50 },
      { name: 'name', desc: formatMessage({ id: 'name' }), showDetail: true, query: true, form: { type: 'input' } },
      { name: 'display_name', desc: formatMessage({ id: 'display_name' }), form: { type: 'input' } },
      // { name: 'description', desc: '描述', render: (text) => <TextMore text={text} maxLength={10} /> },
      {
        name: 'action', desc: formatMessage({ id: 'action' }), width: 250, listActions: true,
        render: (text, record) => {
          return []
        }
      },
    ]

    const insertBtns = []
    return (
      <div>
        <div className="content-header">
          <h2>{formatMessage({ id: 'product_category' })}</h2>
        </div>
        <div className="content-body">
          <TablePage
            rowKey='id'
            name={formatMessage({ id: 'product_category' })}
            fields={fields}
            insertBtns={insertBtns}
            {...service}
            // loadData={service.getDatas}
            // createData={service.createData}
            // updateData={service.updateData}
            // delData={service.deleteData}
            isReload={isReload}
            showCreate
            showEdit
            showDelete
          />
        </div>
      </div>
    )
  }

}

export default injectIntl(Monitor)