import React from 'react'
import { injectIntl } from 'react-intl';
import { Button, Divider, Modal, message } from 'antd';
import TablePage from 'components/TablePage'
import SearchType from 'utils/searchType'
import service from './service'
import Path from 'routes/path'

import CreateModal from 'modules/Reservations/components/CreateModal'

/**
 * 已预约列表页面
 */

class Reservations extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isReload: false,
      visible: false,
    }
  }

  render() {
    const { intl } = this.props;
    const { formatMessage } = intl;
    const { isReload } = this.state;

    const fields = [
      { name: 'key', desc: '', render: (text, record, index) => index + 1, width: 50 },
      // { name: 'user_name', desc: formatMessage({ id: 'real_name' }), showDetail: true, key: 'keyword', query: true },
      {
        name: 'customer.real_name', desc: '消费人', key: 'keyword', query: true,
        render: (text, record) => text || (record.customer && (record.customer.nick_name || record.customer.id)), showRender: true
      },
      { name: 'fee', desc: '消费（元）' },
      { name: 'remark', desc: '备注' },
      { name: 'create_time', desc: '记录时间', type: SearchType.KEY_DATE_RANGE, query: true, sorter: true },
      // { name: 'recorder.nick_name', desc: '记录员' },
      // {
      //   name: 'action', desc: formatMessage({ id: 'action' }), width: 250, listActions: true,
      //   render: (text, record) => {
      //     return [
      //       // <a onClick={() => this.handleShow(record)}>记录消费</a>,
      //     ]
      //   }
      // },
    ]

    const insertBtns = [
      <CreateModal key='create' callback={this.callback} />
    ]
    return (
      <div>
        <div className="content-header">
          <h2>{formatMessage({ id: 'incomes' })}</h2>
        </div>
        <div className="content-body">
          <TablePage
            rowKey='id'
            name={formatMessage({ id: 'reservation' })}
            fields={fields}
            insertBtns={insertBtns}
            {...service}
            // loadData={service.getDatas}
            isReload={isReload}
          />
        </div>
        {/* {this.renderModal()} */}
      </div>
    )
  }

  callback = () => {
    this.setState({
      isReload: true
    })
  }

  handleShow = (record) => {
    this.setState({ visible: true, record })
  }

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  handleOk = () => {
    const { record } = this.state
    this.formRef.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ visible: false })
        service.saveIncomes(record.id, values).then(data => {
          message.success('增加收益成功')
          this.setState({ isReload: true })
          this.formRef.props.form.resetFields()
        })
      }
    })
  }

  handleCancel = () => {
    this.setState({ visible: false })
  }
}

export default injectIntl(Reservations)