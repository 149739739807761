
import UserList from './index'
import UserDetail from './detail'
import Path from 'routes/path'

const routes = [
    {
        id: 'user_list',
        name: 'user_list',
        component: UserList,
        path: Path.pathname['USER_LIST'],
        key: 'user_list',
    },
    // {
    //     id: 'user_detail',
    //     name: 'user_detail',
    //     component: UserDetail,
    //     path: Path.pathname['USER_DETAIL'],
    //     key: 'user_detail',
    //     parentKey: 'user_list'
    // },
]

export default {
    routes
}