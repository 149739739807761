import axios from 'axios'
import moment from 'moment'

/**
 * 获取数据列表
 * @param {*} params 
 */

const getDatas = async (params) => {
    const res = await axios.get('/v1/sys/partners/incomes', {
        params 
    })
    const section = moment(params.begin_time).format('YYYY-MM')
    if (!res.data.total) {
        return res
    }
    const ids = res.data.items.map(i => i.user.id)
    const res2 = await getCashouts(ids, {
        section
    })
    return {
        ...res,
        data: {
            ...res.data,
            items: res.data.items.map(i => {
                return {
                    ...i,
                    cashouts: (res2 && res2.data && res2.data.find(j => i.user.id === j.user_id)) || {},
                    section
                }
            })
        }
    }
}

/**
 * 创建数据
 * @param {*} body 
 */

const createData = (body) => {
    return axios.post('/v1/sys/reservations', body)
}

/**
 * 获取数据详情
 * @param {*} id 
 */

const getDataDetail = (id) => {
    return axios.get(`/v1/sys/reservations/${id}`)
}

/**
 * 更新数据
 * @param {*} id 
 * @param {*} body 
 */

const updateData = (id, body) => {
    return axios.put(`/v1/sys/reservations/${id}`, body)
}

/**
 * 删除数据
 * @param {*} id 
 */

const deleteData = (id) => {
    return axios.delete(`/v1/sys/reservations/${id}`)
}

/**
 * 保存收益
 * @param {*} id 
 * @param {*} body 
 */

const saveIncomes = (id, body) => {
    return axios.post(`/v1/sys/reservations/${id}/charges`, body)
}

/**
 * 收费列表
 * @param {*} id 
 */

const getIncomes = (id) => {
    return axios.get(`/v1/sys/reservations/${id}/charges`)
}

/**
 * 合伙人收益支付
 * @param {*} id 
 * @param {*} body 
 */

const postCashouts = (id, body, params) => {
    return axios.post(`/v1/sys/partners/${id}/cashouts`, body, {
        params
    })
}

/**
 * 获取合伙人支付记录
 * @param {*} id 
 */

const getCashouts = (body, params) => {
    return axios.post(`/v1/sys/partners/cashouts`, body, { params })
}

export default {
    getDatas,
    createData,
    getDataDetail,
    updateData,
    deleteData,
    saveIncomes,
    getIncomes,
    postCashouts,
    getCashouts,
}