import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Button, message, Divider } from 'antd';
import utils from 'utils/util'
import service from './service';
import productService from '../ProductList/service'
import FormPage from 'components/TablePage/components/form'
import SearchType from 'utils/searchType'
import SelectProduct from 'components/SelectProduct'

const Create = (props) => {
  let form = null;
  const { intl } = props;
  const { formatMessage } = intl;
  const id = utils.getQueryString('id')
  const [detail, setDetail] = useState({})

  useEffect(() => {
    const fetchData = async (id) => {
      const data = await service.getDataDetail(id)
      setDetail(data.data)
    }
    if (!id) return
    fetchData(id)
  }, [id])

  const handleCreate = () => {
    form && form.validateFields((errs, values) => {
      if (!errs) {
        if (id) {
          service.updateData(id, values).then(data => {
            message.success('修改成功')
            handleCancel()
          })
        } else {
          service.createData(values).then(data => {
            message.success('创建成功')
            handleCancel()
          })
        }
      }
    })
  }

  const handleCancel = () => {
    props.history.goBack()
  }

  const options = [
    { value: 'ONLINE', name: formatMessage({ id: 'status_online' }) },
    { value: 'OFFLINE', name: formatMessage({ id: 'status_offline' }) },
    // { value: 3, name: '初始' }
  ]
  const fields = [
    { name: 'key', desc: '', render: (text, record, index) => index + 1, width: 50 },
    { name: 'product.cover_image', desc: formatMessage({ id: 'cover' }), hide: true, render: (text) => <img style={{ width: 300 }} src={text} alt='封面' />, showRender: true, cardCover: true },
    { name: 'title', desc: formatMessage({ id: 'name' }), showDetail: true, query: true, cardTitle: true, form: { type: 'input' } },
    { name: 'price', desc: formatMessage({ id: 'price' }), form: { type: 'inputNumber' } },
    { name: 'display_price', desc: formatMessage({ id: 'display_price' }), cardContent: true, form: { type: 'inputNumber' } },
    { name: 'product.id', desc: formatMessage({ id: 'select_product' }), hide: true, hideInDetail: true, key: 'product_id', form: { type: 'custom' }, customRender: <SelectProduct {...props} record={detail} /> },
    {
      name: 'status', desc: formatMessage({ id: 'status' }), query: true, type: SearchType.KEY_CUSTOM_TABS, options, form: { type: 'select' },
      render: (text) => options.find(i => i.value === text) && options.find(i => i.value === text).name
    },
    { name: 'create_time', desc: formatMessage({ id: 'create_time' }), type: SearchType.KEY_DATE_TIME },
  ]

  return (
    <div>
      <div className="content-header">
        <h2>{formatMessage({ id: id ? 'title_edit' : 'title_create' })}{formatMessage({ id: 'point_product' })}</h2>
      </div>
      <div className="content-body">
        <FormPage {...props} ref={(forms) => form = forms} fields={fields} record={detail} />
        <Divider />
        <div style={{ textAlign: 'center' }}>
          <Button size='large' type="primary" onClick={handleCreate}>{formatMessage({ id: 'submit' })}</Button>
          <Button size='large' onClick={handleCancel} style={{ marginLeft: 10 }}>{formatMessage({ id: 'cancel' })}</Button>
        </div>
      </div>
    </div>
  )
}

export default injectIntl(Create);