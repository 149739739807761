import React from 'react'
import { injectIntl } from 'react-intl';
import { Button, Divider, Modal, message, Form, Input, InputNumber, Select } from 'antd';
import SearchType from 'utils/searchType'
import Path from 'routes/path'
import UploadCover from './UploadCover/index';
import UploadImages from './UploadImages/index';
import Editor from '../../Editor'
import utils from 'utils/util'
const { Option } = Select

/**
 * 表单页面
 */

class CreateForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    const { form, fields, record = {}, intl } = this.props;
    const { formatMessage } = intl;
    const { getFieldDecorator } = form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    return (
      <Form onSubmit={this.handleSubmit} {...formItemLayout}>
        {
          fields.filter(i => i.form).map((field, index) => {
            const { form = {} } = field
            const { required, rules, initialValue, formKey } = form
            return (
              <Form.Item label={field.desc} key={index}>
                {getFieldDecorator(formKey || field.key || field.name, {
                  rules: rules || [{ required: true, message: formatMessage({ id: 'message_input' }) + `${field.desc}` }],
                  initialValue: initialValue || (record[field.name] === 0 ? 0 : (utils.getRecordValue(record, field.name) || ''))
                })(
                  this.getFormItemDom(field),
                )}
              </Form.Item>
            )
          })
        }
      </Form>
    )
  }

  getFormItemDom = (field) => {
    const { form, options = [], customRender } = field
    if (!form) return
    const { type = 'input' } = form
    let dom = null
    switch (type) {
      case 'input':
        dom = <Input />;
        break;
      case 'inputNumber':
        dom = <InputNumber style={{ width: '100%' }} />;
        break;
      case 'select':
        dom = <Select>
          {options.map((i, d) => <Option key={d} value={i.value}>{i.name}</Option>)}
        </Select>;
        break;
      case 'upload':
        dom = <UploadCover {...this.props} />;
        break;
      case 'uploadImages':
        dom = <UploadImages {...this.props} />;
        break;
      case 'custom':
        dom = customRender
        break;
      case 'textarea':
        dom = <Input.TextArea />;
        break;
      case 'editor':
        dom = <Editor {...this.props} />;
        break;
      default:
        dom = <Input />;
        break;
    }
    return dom
  }

}

const WrappedForm = Form.create({ 
  name: 'form',
  onValuesChange: (props, changedValues, allValues) => {
    props.handleChange && props.handleChange(allValues)
  }
 })(injectIntl(CreateForm));

export default WrappedForm