
import List from './index'
import Path from 'routes/path'

const routes = [
    {
        id: 'news_category',
        name: 'news_category',
        component: List,
        path: Path.pathname['NEWS_CATEGORY'],
        key: 'news_category',
    },
]

export default {
    routes
}