import React from 'react'
import { injectIntl } from 'react-intl';
import { Button, Divider, Modal, message, Alert } from 'antd';
import TablePage from 'components/TablePage'
import UserLabel from 'components/UserLabel'
import SearchType from 'utils/searchType'
import service from './service'
import Path from 'routes/path'
import utils from 'utils/util'
import SimpleSearch from 'components/SimpleSearch';

/**
 * 订单管理页面
 */

class Monitor extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isReload: false,
    }
  }

  render() {
    const { intl } = this.props;
    const { formatMessage } = intl;
    const { isReload, productGroupOptions, productTypeOptions, sum, totalSum } = this.state;
    const customDurationOptions = utils.getMonths()

    const fields = [
      { name: 'key', desc: '', render: (text, record, index) => index + 1, width: 50 },
      { name: 'order_id', desc: 'Id', hide: true },
      { name: 'order_title', desc: formatMessage({ id: 'product' }), showDetail: true },
      { name: 'price', desc: formatMessage({ id: 'price' }), showRender: true, render: (text) => text ? `￥${text}` : '-' },
      { name: 'status', desc: formatMessage({ id: 'status' }), showRender: true, render: (text) => ({ 'PAID': '已支付' }[text] || '-') },
      { name: 'order_number', desc: formatMessage({ id: 'order_number' }) },
      {
        name: 'order_group', desc: formatMessage({ id: 'order_type' }), showRender: true, render: (text) => ({
          'product': formatMessage({ id: 'order_type_product' }),
          'member_plan': formatMessage({ id: 'order_type_member_plan' }),
          'reservation': formatMessage({ id: 'order_type_reservation' }),
        }[text]
          || '-')
      },
      { name: 'target_id', desc: formatMessage({ id: 'target_id' }), hide: true },
      {
        name: 'user_id', desc: formatMessage({ id: 'user' }),
        render: (text) => <UserLabel id={text} />
      },
      { name: 'amount', desc: formatMessage({ id: 'amount' }) },
      { name: 'create_time', desc: formatMessage({ id: 'create_time' }), type: SearchType.KEY_DATE_TIME },
      { name: 'complete_time', desc: formatMessage({ id: 'complete_time' }), type: SearchType.KEY_DATE_TIME },
    ]

    const searchFields = [
      { name: 'time', desc: formatMessage({ id: 'time_period' }), query: true, type: SearchType.KEY_DATE_TIME_RANGE }
    ]

    const insertBtns = []
    return (
      <div>
        <div className="content-header">
          <h2>{formatMessage({ id: 'order_record' })}</h2>
        </div>
        <div className="content-body">
          <div>
            <span>{formatMessage({ id: 'total_amount' })}￥ 0.00 </span>
            <div style={{ float: 'right' }}>
              <SimpleSearch {...this.props} fields={searchFields} handleSearch={this.getDatas} />
            </div>
          </div>
          {/* <Alert message={
            <span>
              消费总额 : {this._renderPrice(totalSum)}，
              当前消费额 : {this._renderPrice(sum)}
            </span>
          } type="info" style={{ marginBottom: 20 }} /> */}
          <TablePage
            rowKey='order_id'
            name={formatMessage({ id: 'order_record' })}
            fields={fields}
            insertBtns={insertBtns}
            {...service}
            // loadData={service.getDatas}
            isReload={isReload}
          />
        </div>
      </div>
    )
  }

  _renderPrice = (priceArray) => {
    if (!(priceArray && priceArray.length)) return <span style={{ fontSize: 20, color: 'red' }}>￥0.00</span>
    return priceArray.map((i, d) => {
      const price_mark = {
        'CNY': '￥',
        'USD': '$'
      }[i.currency_id || 'CNY']
      return (
        <span key={d} style={{ marginRight: 10 }}>
          <span style={{ fontSize: 20, color: 'red' }}>{price_mark}{i.cost ? (i.cost * 1).toFixed(2) : '0.00'}</span>
          {!price_mark ? <span>(<span>{i.currency_id}</span>)</span> : null}
        </span>
      )
    })
  }

}

export default injectIntl(Monitor)