
import Index from './index'
import Path from 'routes/path'

const routes = [
    {
        id: 'reservation',
        name: 'reservation',
        component: Index,
        path: Path.pathname['RESERVATION'],
        key: 'reservation',
    },
]

export default {
    routes
}