import React from 'react';
import Path from 'routes/path'

class Frame extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: false
    };
  }

  componentWillMount() {
    const { routeRegistry, location } = this.props
    if (!routeRegistry.find(i => i.path === location.pathname)) {
      this.props.history.push(Path.getPathname(this.props, Path.pathname['Error404']))
    }
  }

  componentDidMount() {
  }

  componentWillReceiveProps(nextProps) {
    const { routeRegistry, location } = nextProps
    if (location.pathname !== this.props.location.pathname) {
      if (!routeRegistry.find(i => i.path === location.pathname)) {
        nextProps.history.push(Path.getPathname(nextProps, Path.pathname['Error404']))
      }
    }
  }

  render() {
    const { location } = this.props;
    const { isChecked } = this.state;
    return (
      <div className={`page ${location.pathname.indexOf('/home') !== -1 ? 'no-back' : ''}`} id="content">{this.props.children}</div>
    )
  }
}

export default Frame
