
import Home from './index'

const routes = [
    {
        name: '首页',
        component : Home,
        path: '/home',
        key: 'home_index'
    }
]

export default {
    routes
}