import React from 'react'
import { injectIntl } from 'react-intl';
import { Button, Divider, Modal, message } from 'antd';
import TablePage from 'components/TablePage'
import TextMore from 'components/TextMore'
import SearchType from 'utils/searchType'
import service from './service'
import Path from 'routes/path'

/**
 * 会员管理页面
 */

class Monitor extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isReload: false,
    }
  }

  render() {
    const { intl } = this.props;
    const { formatMessage } = intl;
    const { isReload } = this.state;

    const fields = [
      { name: 'key', desc: '', render: (text, record, index) => index + 1, width: 50 },
      // { name: 'name', desc: formatMessage({ id: 'username' }), showDetail: true, query: true },
      { name: 'user.real_name', desc: formatMessage({ id: 'real_name' }), showDetail: true, query: true },
      { name: 'points', desc: formatMessage({ id: 'point' }), render: (text) => `${text || 0}积分`, showRender: true },
      // { name: 'wechat_openid', desc: 'Open ID', hide: true },
      // { name: 'register_time', desc: formatMessage({ id: 'register_time' }), type: SearchType.KEY_DATE_TIME },
      {
        name: 'action', desc: formatMessage({ id: 'action' }), width: 250,
        render: (text, record) => (
          <span>
            <a>查看积分细则</a>
          </span>
        )
      },
    ]

    const insertBtns = []
    return (
      <div>
        <div className="content-header">
          <h2>{formatMessage({ id: 'user_point' })}</h2>
        </div>
        <div className="content-body">
          <TablePage
            rowKey='user_id'
            name={formatMessage({ id: 'user_point' })}
            fields={fields}
            insertBtns={insertBtns}
            {...service}
            // loadData={service.getDatas}
            isReload={isReload}
          />
        </div>
      </div>
    )
  }

}

export default injectIntl(Monitor)