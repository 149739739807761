
import User from './index'
import Path from 'routes/path'

const routes = [
    {
        id: 'user',
        name: 'user',
        component: User,
        path: Path.pathname['MEMBER_PLAN'],
        key: 'member_plan',
    },
]

export default {
    routes
}