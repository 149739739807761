import React from 'react';
import { Menu, Dropdown, Icon } from 'antd';
import utils from 'utils/util'

class QuickLink extends React.Component {

  render() {
    const { intl } = this.props
    const { formatMessage } = intl

    let quickLinks = []
    quickLinks.push({ url: utils.getDomain('endUser'), titleId: 'omai_end_user' })
    if (utils.hasPermission('BUSINESS')) quickLinks.push({ url: utils.getDomain('business'), titleId: 'omai_business' })

    const menu = (
      <Menu>
        {quickLinks.map((i, k) => {
          return (
            <Menu.Item key={k}>
              <a target="_blank" rel="noopener noreferrer" href={i.url}>
                {formatMessage({ id: i.titleId })}
              </a>
            </Menu.Item>
          )
        })}
      </Menu>
    );
    if (!quickLinks.length) return ''
    return (
      <div>
        <Dropdown overlay={menu} getPopupContainer={() => document.getElementById('content')}>
          <a className="ant-dropdown-link">
            {formatMessage({ id: 'quick_links' })} <Icon type="down" />
          </a>
        </Dropdown>
      </div>
    )
  }

}

export default QuickLink;