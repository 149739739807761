
import MemberBenefits from './index'
import Path from 'routes/path'

const routes = [
    {
        id: 'member_benefits',
        name: 'member_benefits',
        component: MemberBenefits,
        path: Path.pathname['MEMBER_BENEFITS'],
        key: 'member_benefits',
    },
]

export default {
    routes
}