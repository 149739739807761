import React from 'react';
import { injectIntl } from 'react-intl';
import { Select } from 'antd';
import LocalStorageUtil from 'utils/localStorageUtil'
import Util from 'utils/util'
import './index.css'

const { Option } = Select;

class LocaleSelect extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      value: (props.intl && props.intl.locale) || new LocalStorageUtil().getLocale() || ''
    }
  }
  
  onChange = (value) => {
    this.setState({ value })
    new LocalStorageUtil().setLocale(value)
    Util.setParam('search', { locale: value })
  }

  render() {
    const { intl } = this.props;
    const { formatMessage } = intl;
    const { value } = this.state
    const options = [
      { id: 'zh-CN', name: formatMessage({ id: 'zh-CN' }) },
      { id: 'en-US', name: formatMessage({ id: 'en-US' }) },
    ]
    return (
      <Select size='small' className="locale-select" onChange={this.onChange} value={value} getPopupContainer={() => document.getElementById('content')}>
        {options.map((opt, index) => {
          return <Option key={index} value={opt.id}>{opt.name}</Option>
        })}
      </Select>
    )
  }

}

export default injectIntl(LocaleSelect)