import axios from 'axios'

/**
 * 获取数据列表
 * @param {*} params 
 */

const getDatas = (params) => {
    return axios.get('/v1/sys/charges', {
        params 
    })
}

/**
 * 创建数据
 * @param {*} body 
 */

const createData = (body) => {
    return axios.post('/v1/sys/reservations', body)
}

/**
 * 获取数据详情
 * @param {*} id 
 */

const getDataDetail = (id) => {
    return axios.get(`/v1/sys/reservations/${id}`)
}

/**
 * 更新数据
 * @param {*} id 
 * @param {*} body 
 */

const updateData = (id, body) => {
    return axios.put(`/v1/sys/reservations/${id}`, body)
}

/**
 * 删除数据
 * @param {*} id 
 */

const deleteData = (id) => {
    return axios.delete(`/v1/sys/reservations/${id}`)
}

/**
 * 保存收益
 * @param {*} id 
 * @param {*} body 
 */

const saveIncomes = (id, body) => {
    return axios.post(`/v1/sys/reservations/${id}/charges`, body)
}

/**
 * 收费列表
 * @param {*} id 
 */

const getIncomes = (id) => {
    return axios.get(`/v1/sys/reservations/${id}/charges`)
}

export default {
    getDatas,
    createData,
    getDataDetail,
    updateData,
    deleteData,
    saveIncomes,
    getIncomes,
}