
import OrderStatistics from './index'
import Path from 'routes/path'

const routes = [
    {
        id: 'order_statistics',
        name: 'order_statistics',
        component: OrderStatistics,
        path: Path.pathname['ORDER_STATISTICS'],
        key: 'order_statistics',
    },
]

export default {
    routes
}