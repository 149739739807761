import React from 'react'
import { injectIntl } from 'react-intl';
import { Button, Divider, Modal, message, Popconfirm } from 'antd';
import TablePage from 'components/TablePage'
import SearchType from 'utils/searchType'
import service from './service'
import Path from 'routes/path'
import utils from 'utils/util'
import moment from 'moment'

/**
 * 已预约列表页面
 */

class Reservations extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isReload: false,
      visible: false,
    }
  }

  postCashouts = (record) => {
    service.postCashouts(record.user.id, {}, {
      section: record.section,
      amount: record.income
    }).then(data => {
      message.success('确认支付成功')
      this.setState({
        isReload: true
      }, () => {
        setTimeout(() => {
          this.setState({
            isReload: false
          })
        }, 50)
      })
    }).catch(err => {
      message.error('确认支付失败')
    })
  }

  render() {
    const { intl } = this.props;
    const { formatMessage } = intl;
    const { isReload } = this.state;
    const customDurationOptions = utils.getMonths()

    const fields = [
      { name: 'key', desc: '', render: (text, record, index) => index + 1, width: 50 },
      // { name: 'user_name', desc: formatMessage({ id: 'real_name' }), showDetail: true, key: 'keyword', query: true },
      {
        name: 'user.real_name', desc: '姓名', key: 'keyword', query: true,
        render: (text, record) => text || record.user.nick_name || record.user.id, showRender: true
      },
      { name: 'user.phone', desc: '电话' },
      { name: 'user.sn', desc: '身份证号' },
      { name: 'income', desc: '收益（元）' },
      // { name: 'section', desc: '备注' },
      {
        name: 'duration', desc: '消费时间', hide: true, type: SearchType.KEY_DATE_RANGE, query: true, sorter: true,
        customDurationOptions: customDurationOptions, showCustomDuration: true
      },
      {
        name: 'action', desc: formatMessage({ id: 'action' }), width: 150, listActions: true,
        render: (text, record) => {
          return [
            moment().format('MM') === moment(record.section).format('MM')
              ? null
              : record.cashouts && record.cashouts.id
                ? <span style={{ color: '#999' }}>已确认支付</span>
                : <Popconfirm
                  title='是否确认已支付？'
                  onConfirm={() => this.postCashouts(record)}
                >
                  <a onClick={() => this.handleShow(record)}>确认已支付</a>
                </Popconfirm>,
          ]
        }
      },
    ]

    const insertBtns = []
    return (
      <div>
        <div className="content-header">
          <h2>{formatMessage({ id: 'partner_incomes' })}</h2>
        </div>
        <div className="content-body">
          <TablePage
            rowKey={(record) => record.user.id}
            name={formatMessage({ id: 'reservation' })}
            fields={fields}
            insertBtns={insertBtns}
            {...service}
            // loadData={service.getDatas}
            isReload={isReload}
            addFilter={{
              begin_time: customDurationOptions[0].range[0],
              end_time: customDurationOptions[0].range[1]
            }}
          />
        </div>
        {/* {this.renderModal()} */}
      </div>
    )
  }

  handleShow = (record) => {
    this.setState({ visible: true, record })
  }

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  handleOk = () => {
    const { record } = this.state
    this.formRef.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ visible: false })
        service.saveIncomes(record.id, values).then(data => {
          message.success('增加收益成功')
          this.setState({ isReload: true })
          this.formRef.props.form.resetFields()
        })
      }
    })
  }

  handleCancel = () => {
    this.setState({ visible: false })
  }
}

export default injectIntl(Reservations)