import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Table, Modal, message, Button, Badge, Tag, Tabs, Radio, Card, Row, Col, Icon, Pagination, Spin, Divider, Popconfirm, Empty } from 'antd';
import SimpleSearch from '../SimpleSearch'
import LocalStorageUtil from '../../utils/localStorageUtil';
import SearchType from '../../utils/searchType';
import DictUtil from 'utils/dictUtil'
import renderUtils from 'utils/renderUtils'
import Detail from './components/detail'
import DataForm from './components/form'
import DragTitle from 'components/DragTitle'
import utils from 'utils/util'
import './index.css'
import imgSrc from 'theme/img/timg.jpg'

const ButtonGroup = Button.Group;
const { TabPane } = Tabs;
const { Meta } = Card;

class TablePage extends Component {

  constructor(props) {
    super(props)
    this.state = {
      pageSize: new LocalStorageUtil().getPageSize(),
      pageNumber: props.pageNumber ? props.pageNumber : 1,
      filter: props.filter ? props.filter : '',
      sorter: props.sorter ? props.sorter : {},
      loading: true,
      initTable: false,
      isReload: props.isReload || false,
      pagination: {},
      pageSizeChanged: false,
      columns: props.columns,
      dataSource: [],
      total: 0,
      visible: false,
      currentTab: new LocalStorageUtil().getStorage('tabType') || 'card',
      formVisible: false,
      type: 'create',
      record: {}
    }
    this.isUnmount = false
    this.timer = 0
  }

  componentDidMount() {
    const { pageNumber, pageSize, filter, sorter } = this.state
    const { autoRefresh } = this.props
    this.loadData(pageNumber, pageSize, filter, sorter)
    this.setState({ columns: this._rebuildColumns(this.props.fields) })
    if (autoRefresh) {
      clearInterval(this.timer)
      this.timer = setInterval(() => {
        this.loadData(pageNumber, pageSize, filter, sorter)
        this.setState({ columns: this._rebuildColumns(this.props.fields) })
      }, 5000)
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ columns: this._rebuildColumns(nextProps.fields) })
    const { pageNumber, pageSize, filter, sorter } = this.state
    nextProps.isReload && this.loadData(pageNumber, pageSize, filter, sorter)
  }

  loadData = (pageNumber, pageSize, filter, sorter) => {
    const { intl, getDatas, dataSource = [], addFilter } = this.props;
    const { formatMessage } = intl;
    this.setState({ loading: true })
    getDatas && getDatas({
      page: pageNumber,
      page_size: pageSize,
      ...addFilter,
      ...filter,
      ...sorter
    }).then(data => {
      if (dataSource && dataSource.length) {
        this.setState({
          dataSource: dataSource.map(i => {
            return {
              ...i,
              ...data.data.find(j => j.channel === i.channel)
            }
          })
        })
      } else {
        let newDatas = data.data.items ? data.data.items : data.data
        if (this.props.module === 'member_plan')
          newDatas = newDatas.sort((a, b) => a.human_price * 1 - b.human_price * 1)
        this.setState({ dataSource: newDatas, })
      }
      !this.isUnmount && this.setState({
        loading: false,
        // dataSource: data.data.items ? data.data.items : data.data,
        total: data.data.total,
        pageNumber, pageSize, filter, sorter
      }, () => {
        this.setState({ pagination: this._createPagination(this.state) })
      })
      if (data.data.items && data.data.items.length === 0 && data.data.total) {
        this.loadData(pageNumber - 1, pageSize, filter, sorter)
      }
    }).catch(error => {
      this.setState({ loading: false })
      message.error((error.response && error.response.data.message) || formatMessage({ id: 'unknown_error' }))
    })
  }

  _handleShowDetail = (e, record) => {
    e.preventDefault();
    this.setState({ record, visible: true })
  }

  _handleDelete = (e, record) => {
    const { intl, name = '', deleteData } = this.props;
    const { formatMessage } = intl;
    Modal.confirm({
      title: formatMessage({ id: 'delete' }) + ' ' + name.toLocaleLowerCase(),
      content: formatMessage({ id: 'confirm_delete' }),
      onOk: () => {
        deleteData && deleteData(record.id).then(data => {
          message.success(formatMessage({ id: 'delete_success' }))
          this.loadData()
        })
      },
      onCancel: () => { }
    })
  }

  _renderDetailModal = (record, fields) => {
    const { intl, name } = this.props;
    const { formatMessage } = intl;

    return (
      <Modal
        width={700}
        title={<DragTitle title={name + formatMessage({ id: 'title_detail' })} className='detail_modal' />}
        visible={this.state.visible}
        onOk={() => this.handleOk()}
        onCancel={() => this.setState({ visible: false })}
        footer={false}
        wrapClassName='detail_modal'
      >
        <Detail {...this.props} record={record} fields={fields} />
      </Modal>
    )
  }

  _renderFormModal = (record, fields) => {
    const { intl, name } = this.props;
    const { formatMessage } = intl;
    const { type } = this.state

    return (
      <Modal
        width={650}
        title={<DragTitle title={type === 'create' ? `${formatMessage({ id: 'title_create' })}${name}` : `${formatMessage({ id: 'title_edit' })}${name}`} className='form_modal' />}
        visible={this.state.formVisible}
        onOk={() => this.handleOk()}
        onCancel={() => this.handleCancel()}
        footer={[
          <Button key='cancel' onClick={() => this.handleCancel()}>{formatMessage({ id: 'cancel' })}</Button>,
          <Button key='ok' onClick={() => this.handleSubmit(record)} type='primary' style={{ marginLeft: 10 }}>{formatMessage({ id: 'ok' })}</Button>,
        ]}
        wrapClassName='form_modal'
      >
        <DataForm {...this.props} ref={form => this.form = form} record={record} fields={fields} />
      </Modal>
    )
  }

  handleSubmit = (record) => {
    const { intl } = this.props
    const { formatMessage } = intl
    const { type } = this.state
    const { pageNumber, pageSize, filter, sorter } = this.state
    const { createData, updateData, name } = this.props
    this.form.validateFields((err, values) => {
      if (!err) {
        (type === 'create' ? createData(values) : updateData(record.id, values)).then(data => {
          this.loadData(pageNumber, pageSize, filter, sorter)
          message.success(`${name}${type === 'create' ? formatMessage({ id: 'title_create' }) : formatMessage({ id: 'title_edit' })}${formatMessage({ id: 'success' })}`)
          this.handleCancel()
        })
      }
    })
  }

  handleCancel = () => {
    this.setState({ formVisible: false })
    this.form.resetFields()
  }

  _onTableChange(params) {
    this.setState({ pageNumber: params.pageNumber, pageSize: params.pageSize })
    this.loadData(params.pageNumber, params.pageSize, params.filter, params.sorter)
  }

  _createPagination(props) {
    const { intl } = this.props
    const { formatMessage } = intl
    return props.total ? {
      showQuickJumper: true,
      showSizeChanger: true,
      pageSize: props.pageSize,
      total: props.total,
      current: props.pageNumber || this.state.pagination.current || 1,
      showTotal: (total, range) => formatMessage({ id: 'page_message' }, { range_0: range[0], range_1: range[1], total: total }),
      pageSizeOptions: ['10', '20', '50'],
      onShowSizeChange: this._onShowSizeChange
    } : false
  }

  _onShowSizeChange = (current, pageSize) => {
    this.setState({
      pagination: {
        ...this.state.pagination,
        current: 1,
        pageSize: pageSize
      },
      pageSizeChanged: true
    })

    new LocalStorageUtil().setPageSize(pageSize);

    this.setState({
      pagination: this.state.pagination,
      pageSizeChanged: true,
    });
  }

  _onChange = (pagination, filter, sorter) => {
    let pager = this.state.pagination;
    if (!pager) pager = {}
    if (!this.state.pageSizeChanged) {
      pager.current = pagination.current || 1;
    }

    let _sorter = sorter && sorter.field ? {
      sort_by: sorter.field,
      order: sorter['order'].substring(0, sorter['order'].length - 3)
    } : {};

    this._onTableChange({
      pageNumber: pager.current,
      pageSize: pagination.pageSize,
      sorter: _sorter,
      filter: Object.assign({}, this.state.filter, filter)
    });

    this.setState({
      pagination: pager,
      sorter: _sorter,
      filter: Object.assign({}, this.state.filter, filter),
      pageSizeChanged: false
    });
  }

  _rebuildColumns = (propsColumns) => {
    let columns = propsColumns || [];
    let newColumns = [];
    for (let i = 0; i < columns.length; i++) {
      let column = columns[i];
      if (!column.hide) {
        let _column = Object.assign({}, column);
        _column['title'] = column.desc;
        _column['dataIndex'] = column.name;
        _column['key'] = column.name;
        var __setColumn = this._setColumnRender(_column)

        _column = { ..._column, ...__setColumn };
        if (__setColumn['__render']) {
          _column = { ..._column, render: __setColumn['__render'] }
        }
        newColumns.push(_column);
      }
    }

    return newColumns;
  }

  _getColumnRender = (column, text, record) => {
    return column.render(text, record)
  }

  _setColumnRender = (column) => {
    const { showEdit, showDelete, intl } = this.props
    const { formatMessage } = intl
    const { type, name, actions = [], listActions, options } = column
    if (column.showDetail) {
      column.render = (text, record) => {
        if (name === 'real_name') {
          text = text || record.nick_name || record.id
        }
        if (name === 'user.real_name') {
          text = text || record.user.nick_name || record.user.id
        }
        // return text
        return <div>
          <a onClick={(e) => this._handleShowDetail(e, record)}>{text || '-'}</a>
          {/* {record.name ? <p style={{ fontSize: 12, color: '#999', margin: 0 }}>{record.name}</p> : null} */}
        </div>
      }
      return column
    }

    // if (column.render && column.showRender) {
    //   return column;
    // }

    if (type === SearchType.KEY_DATE) {
      column.render = text => utils.timeFormat(text, type)
    }

    if (type === SearchType.KEY_DATE_TIME || type === SearchType.KEY_DATE_RANGE) {
      column.render = text => utils.timeFormat(text, type)
    }

    if (type === SearchType.KEY_CUSTOM_OPTION) {
      column.render = text => options.find(i => i.value === text) ? options.find(i => i.value === text).name : '-'
    }

    if (type && type.indexOf(SearchType.KEY_COMMON_ENMU) !== -1) {
      const Dict = new DictUtil().getDict(this.props)
      let dictName = type.split('-')[1];
      let dict = Dict[dictName]
      column.render = text => {
        let tmpValues = [];
        let tempText = String(text);
        if (tempText) {
          let values = tempText.split(',');
          values.forEach(value => {
            for (let index in dict) {
              if (value === String(dict[index].id)) {
                tmpValues.push(dict[index].name);
                break;
              }
            }
          })
        }
        return tmpValues.length === 0 ? '-' : <span>
          {column.showTag ? tmpValues.map((val, index) => <Tag color='blue' key={index}>{val}</Tag>) : tmpValues.join(',')}
        </span>;
      }
    }

    if (name === 'action') {
      if (listActions) {
        column.__render = (text, record) => {
          let acts = column.render(text, record)
          if (showEdit) {
            acts = [<a onClick={() => this.setState({ formVisible: true, type: 'edit', record })}>{formatMessage({ id: 'edit' })}</a>, ...acts]
          }
          if (showDelete) {
            acts = [...acts, <Popconfirm
              title={formatMessage({ id: 'confirm_delete' })}
              onConfirm={() => this.handleDelete(record)}
              // onCancel={cancel}
              okText={formatMessage({ id: 'ok' })}
              cancelText={formatMessage({ id: 'cancel' })}
            >
              <a>{formatMessage({ id: 'delete' })}</a>
            </Popconfirm>]
          }
          return acts.map((i, d) => {
            return <span key={d}>
              {i}
              {d !== acts.length - 1 ? <Divider type="vertical" /> : null}
            </span>
          })
        }
      }
    }

    return column;

  }

  handleDelete = (record) => {
    const { deleteData, intl } = this.props
    const { formatMessage } = intl
    deleteData && deleteData(record.id).then(data => {
      message.success(formatMessage({ id: 'delete_success' }))
      this.loadData()
    })
  }

  _buildSimpleSearch = (fields) => {
    const { filter } = this.state
    if (!fields || !fields.length) return
    let searchFields = []
    fields.forEach(field => {
      if (field.query) {
        searchFields.push(field)
      }
    })

    if (searchFields.length) {
      return <SimpleSearch fields={searchFields} handleSearch={this.handleSearch} values={filter} />
    }
  }

  handleSearch = (values) => {
    const { pageNumber, pageSize, filter, sorter } = this.state
    const newFilter = Object.assign({}, filter, values)
    this.loadData(1, pageSize, newFilter, sorter)
  }

  onSelectChange(selectedRowKeys, selectedRows) {
    if (this.props.onSelect) {
      this.props.onSelect(selectedRows);
    }
    this.setState({
      selectedItems: selectedRows,
      selectedRowKeys: selectedRowKeys,
      delBtnDisabled: false
    });
  }

  handleRefresh = () => {
    const { pageNumber, pageSize, filter, sorter } = this.state
    this.loadData(pageNumber, pageSize, filter, sorter)
  }

  componentWillUnmount() {
    this.isUnmount = true
    clearInterval(this.timer)
  }

  render() {
    let { fields = [], insertBtns = [], size = 'default', rowSelection, rowKey, intl, needRefresh, showTabs, showCreate } = this.props
    const { formatMessage } = intl
    const {
      loading = false,
      pagination,
      columns,
      dataSource,
      total,
      record,
      selectedRowKeys, selectedRows
    } = this.state

    const newRowSelection = rowSelection ? Object.assign({}, rowSelection, {
      selectedRowKeys,
      selectedRows,
      onChange: this.onSelectChange.bind(this),
    }) : null;
    const expandedRowRender = (record, index, indent, expanded) => {
      return <Detail {...this.props} record={record} fields={fields} />
    }

    if (showCreate) {
      insertBtns = [<Button key='create' type='primary' icon='plus' onClick={() => this.setState({ formVisible: true, type: 'create', record: {} })}>{formatMessage({ id: 'create' })}</Button>, ...insertBtns]
    }
    return (
      <div>
        <div className='insert-btns'>
          {needRefresh ? <Button style={{ marginRight: 10 }} onClick={this.handleRefresh}>{formatMessage({ id: 'refresh' })}</Button> : null}
          {insertBtns}
        </div>
        {/* <div className='clear'></div> */}
        {this._buildSimpleSearch(fields)}
        {
          showTabs
            ? this.renderTabs()
            : this.renderTable()
        }
        {this._renderDetailModal(record, fields)}
        {this._renderFormModal(record, fields)}
      </div>
    )
  }

  renderTable = () => {
    const { fields = [], insertBtns = [], size = 'default', rowSelection, rowKey, needRefresh, autoRefresh } = this.props
    const {
      loading = false,
      pagination,
      columns,
      dataSource,
      total,
      record,
      selectedRowKeys, selectedRows
    } = this.state

    const newRowSelection = rowSelection ? Object.assign({}, rowSelection, {
      selectedRowKeys,
      selectedRows,
      onChange: this.onSelectChange.bind(this),
    }) : null;
    return (
      <Table
        size={size}
        loading={!autoRefresh && loading}
        rowKey={rowKey || 'id'}
        columns={columns}
        total={total}
        pagination={pagination}
        dataSource={dataSource}
        onChange={this._onChange}
        rowSelection={newRowSelection}
      // expandedRowRender={expandedRowRender}
      // scroll={{ x: 1500 }}
      />
    )
  }

  handleChangeRadio = (e) => {
    const { dataSource } = this.state
    const value = e.target.value
    const selectedRowKeys = [value]
    const selectedRows = dataSource.filter(i => i.id === value)
    this.onSelectChange(selectedRowKeys, selectedRows)

  }

  renderCard = () => {
    const { cardActions, fields, showEdit, showDelete, rowSelection } = this.props
    const { dataSource, pagination, loading, selectedRowKeys = [] } = this.state
    return (
      <>
        <Spin spinning={loading}>
          <Row gutter={20}>
            {
              rowSelection
                ? dataSource.length
                  ? <Radio.Group onChange={this.handleChangeRadio} value={selectedRowKeys[0]}>
                    {this.renderCardDataSource()}
                  </Radio.Group>
                  : <Empty />
                : this.renderCardDataSource()
            }
          </Row>
          {
            dataSource.length
              ? <Pagination style={{ textAlign: 'right' }} {...pagination} onChange={this._onChangePage} onShowSizeChange={this._onChangePage} />
              : null
          }
        </Spin>
      </>
    )
  }

  renderCardDataSource = () => {
    const { intl } = this.props
    const { formatMessage } = intl
    const { cardActions, fields, showEdit, showDelete, rowSelection, cardType } = this.props
    const { dataSource, pagination, loading } = this.state
    return (
      <>
        {
          dataSource.length
            ? dataSource.map((i, d) => {
              const cardTitle = fields.find(field => field.cardTitle) ? i[fields.find(field => field.cardTitle).name] : i.name
              const cardContentField = fields.find(field => field.cardContent)
              const cardContent = cardContentField
                ? (cardContentField.render(i[cardContentField.name]) || i[cardContentField.name])
                : i.price
              const cardDeleteContentField = fields.find(field => field.cardDeleteContent)
              const cardDeleteContent = cardDeleteContentField
                ? (cardDeleteContentField.render(i[cardDeleteContentField.name]) || i[cardDeleteContentField.name])
                : i.display_price
              const cardCover = fields.find(field => field.cardCover) ? utils.getRecordValue(i, fields.find(field => field.cardCover).name) : i.image
              let actions = []
              const cardActions = fields.find(field => field.name === 'action') ? fields.find(field => field.name === 'action').render(null, i) : []
              actions = [...cardActions, ...actions]
              if (showEdit) {
                actions = [<a onClick={() => this.setState({ formVisible: true, type: 'edit', record: i })}>{formatMessage({ id: 'edit' })}</a>, ...actions]
              }
              if (showDelete) {
                actions = [...actions, <Popconfirm
                  title={formatMessage({ id: 'confirm_delete' })}
                  onConfirm={() => this.handleDelete(i)}
                  // onCancel={cancel}
                  okText={formatMessage({ id: 'ok' })}
                  cancelText={formatMessage({ id: 'cancel' })}
                >
                  <a>{formatMessage({ id: 'delete' })}</a>
                </Popconfirm>]
              }
              return (
                <Col key={d} span={6}>
                  <Card
                    loading={loading}
                    hoverable
                    style={{ width: '100%', marginBottom: 20 }}
                    cover={<img alt={cardTitle} src={cardCover} style={{ height: 200 }} />}
                    actions={actions || cardActions || [
                      <a>{Math.random() > 0.5 ? '上架' : '下架'}</a>,
                      <a>{formatMessage({ id: 'edit' })}</a>,
                      <a>{formatMessage({ id: 'delete' })}</a>,
                    ]}
                  >
                    <Meta title={<a onClick={(e) => this._handleShowDetail(e, i)}>{cardTitle}</a>} description={
                      <>
                        <span style={{ color: 'red', fontSize: 20 }}>{cardContent}</span>
                        <span style={{ textDecoration: 'line-through', marginLeft: 10 }}>{cardDeleteContent}</span>
                      </>
                    } />
                    {
                      rowSelection
                        ? <div style={{ position: 'absolute', top: 0, left: 0 }}>
                          <Radio value={i.id} />
                        </div>
                        : null
                    }
                  </Card>
                </Col>
              )
            })
            : <Empty />
        }
      </>
    )

  }

  _onChangePage = (page, pageSize) => {
    let { pagination: pager, pageSizeChanged } = this.state;
    if (!pager) pager = {}
    if (!pageSizeChanged) {
      pager.current = page || 1;
    }

    this._onTableChange({
      pageNumber: pager.current,
      pageSize: pageSize
    });

    this.setState({
      pagination: pager,
      pageSizeChanged: false
    });
  }

  renderTabs = () => {
    const { tabsStyle } = this.props
    const { currentTab } = this.state;
    const tabs = [
      { id: 'table', name: '列表' },
      { id: 'card', name: '卡片' },
    ]
    return (
      <>
        <div style={Object.assign({}, { textAlign: 'right', marginTop: '-120px', marginBottom: '80px' }, tabsStyle)}>
          <Radio.Group onChange={this.handleChangeTab} value={currentTab} style={{ marginBottom: 8 }}>
            <Radio.Button value="table"><Icon type='bars' style={{ fontSize: 16 }} /></Radio.Button>
            <Radio.Button value="card"><Icon type='appstore' style={{ fontSize: 16 }} /></Radio.Button>
          </Radio.Group>
        </div>
        {
          currentTab === 'table'
            ? this.renderTable()
            : this.renderCard()
        }
      </>
    )
  }

  handleChangeTab = (e) => {
    const tabType = e.target.value
    new LocalStorageUtil().setStorage('tabType', tabType)
    this.setState({ currentTab: tabType })
  }
}

export default injectIntl(TablePage)
