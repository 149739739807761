import axios from 'axios'

/**
 * 获取上传token
 */

const getToken = () => {
    return axios.get('/v1/uptoken')
}

export default {
    getToken,
}