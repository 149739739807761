import React from 'react'
import { injectIntl } from 'react-intl';
import { Button, Divider, Modal, message, Spin } from 'antd';
import TablePage from 'components/TablePage'
import SearchType from 'utils/searchType'
import service from './service'
import Path from 'routes/path'
import ReactEcharts from './components/ReactEcharts'
import SimpleSearch from 'components/SimpleSearch';
import BizCharts from './components/BizCharts'

/**
 * 会员管理页面
 */

class Monitor extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      datas: [],
      loading: false
    }
  }

  componentDidMount() {
    this.getDatas()
  }

  getDatas = (params) => {
    this.setState({ loading: true })
    service.getDatas().then(data => {
      this.setState({ datas: data.data.sort((a, b) => a.group_name > b.group_name ? 1 : -1), loading: false })
    })
  }

  render() {
    const { intl } = this.props;
    const { formatMessage } = intl;
    const { datas, loading } = this.state;

    const fields = [
      { name: 'time', desc: '时间', query: true, type: SearchType.KEY_DATE_TIME_RANGE }
    ]

    return (
      <div>
        <div className="content-header">
          <h2>{formatMessage({ id: 'order_statistics' })}</h2>
        </div>
        <div className="content-body">
          <Spin spinning={loading}>
            <div style={{ overflow: 'hidden' }}>
              <div style={{ float: 'right' }}>
                <SimpleSearch {...this.props} fields={fields} handleSearch={this.getDatas} />
              </div>
            </div>
            <BizCharts datas={datas} />
          </Spin>
        </div>
      </div>
    )
  }

  renderOld = () => {
    const { datas, loading } = this.state;
    const options = {
      color: ['#3398DB'],
      tooltip: {
        trigger: 'axis',
        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
          type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
        }
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        data: datas.map(i => i.group_name), // ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
      },
      yAxis: {
        type: 'value'
      },
      series: [{
        data: datas.map(i => i.value),// [820, 932, 901, 934, 1290, 1330, 1320],
        type: 'bar',
        barWidth: '100px',
        itemStyle: {
          normal: {
            label: {
              show: true,  //开启显示
              position: 'top',  //在上方显示
              textStyle: {  //数值样式
                color: 'black',
                fontSize: 16
              }
            }
          }
        },
      }]
    };
    return (
      <ReactEcharts options={options} />
    )
  }

}

export default injectIntl(Monitor)