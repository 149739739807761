import React, { useState, useRef, useEffect } from 'react';
import { Typography, Icon, Button, Modal, message } from 'antd';
import ProfitForm from '../ProfitForm';
import DragTitle from 'components/DragTitle'
import service from './service'

const { Paragraph } = Typography;

const MemberPlanProfits = (props) => {
  let ref = useRef(null);
  const { profits = [], record = {} } = props
  const [datas, setDatas] = useState(profits);
  const [visible, setVisible] = useState(false);
  const [type, setType] = useState('create');

  useEffect(() => {
    setDatas(profits)
  }, [profits])

  const handleCancel = () => {
    setVisible(false)
  }

  const handleOk = async () => {
    ref.current.validateFields(async (errors, values) => {
      if (errors) return
      const data = await service.createData({
        member_plan_id: record.id,
        amount: values.amount,
        product_id: values.product_id
      })
      if (data.status === 200) {
        setDatas(datas.concat([{
          product_id: values.product_id,
          product_name: values.product_name,
          amount: values.amount,
        }]))
        setVisible(false)
        message.success('添加权益成功')
      }
    })
  }

  const handleDel = async (record) => {
    if (!record.id) return
    Modal.confirm({
      title: '删除提示',
      content: '确定删除此权益吗？',
      onOk: async () => {
        const data = await service.deleteData(record.id)
        if (data.status === 200) {
          setDatas(datas.filter(i => i.id !== (record.id)))
          message.success('删除权益成功')
        }
      },
      onCancel: () => { }
    })

  }

  return (
    <>
      {
        datas.map((i, d) => {
          return <Paragraph key={d}>
            {`${i.product_name} × ${i.amount}`}
            <Button style={{ marginLeft: 10 }} size="small" type='danger' icon='delete' onClick={() => handleDel(i)} />
          </Paragraph>
        })
      }
      <Button size="small" icon='plus' onClick={() => setVisible(true)} />
      <Modal
        width={650}
        title={<DragTitle title={type === 'create' ? `创建权益` : `编辑权益`} className='form_modal' />}
        visible={visible}
        onOk={() => handleOk()}
        onCancel={() => handleCancel()}
        footer={[
          <Button key='cancel' onClick={() => handleCancel()}>取消</Button>,
          <Button key='ok' onClick={() => handleOk()} type='primary' style={{ marginLeft: 10 }}>确定</Button>,
        ]}
        wrapClassName='form_modal'
        id="form_modal"
      >
        <ProfitForm {...props} ref={ref} />
      </Modal>
    </>
  )
}

export default MemberPlanProfits