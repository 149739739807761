/**
 * route regist
 */
import homeRoutes from 'modules/Home/routes'
import memberListRoutes from 'modules/MemberList/routes'
import memberLevelRoutes from 'modules/MemberLevel/routes'
import memberBenefitsRoutes from 'modules/MemberBenefits/routes'
import memberStatisticsRoutes from 'modules/MemberStatistics/routes'
import productRoutes from 'modules/ProductList/routes'
import productCategoryRoutes from 'modules/ProductCategory/routes'
import integralRoutes from 'modules/PointList/routes'
import orderRoutes from 'modules/Order/routes'
import orderStatisticsRoutes from 'modules/OrderStatistics/routes'
import couponRoutes from 'modules/Coupon/routes'
import errorRoutes from 'modules/ErrorPage/routes'
import exchangeRecordRoutes from 'modules/PointRecord/routes'
import exchangeStatisticsRoutes from 'modules/PointStatistics/routes'
import userIntegralRoutes from 'modules/UserPoint/routes'
import spreadStatisticsRoutes from 'modules/SpreadStatistics/routes'
import pointRulesRoutes from 'modules/PointRules/routes'
import newsRoutes from 'modules/News/routes'
import usersRoutes from 'modules/UserList/routes'
import preReservationsRoutes from 'modules/PreReservations/routes'
import newsCategoryRoutes from 'modules/NewsCategory/routes'
import reservationsRoutes from 'modules/Reservations/routes'
import reservationsLatestRoutes from 'modules/ReservationLatest/routes'
import partnerRoutes from 'modules/Partner/routes'
import incomesRoutes from 'modules/Incomes/routes'
import partnerIncomesRoutes from 'modules/PartnerIncomes/routes'

const routeRegistry = flatten([
  homeRoutes,

  memberListRoutes,
  memberLevelRoutes,
  memberBenefitsRoutes,
  memberStatisticsRoutes,

  productRoutes,
  productCategoryRoutes,

  integralRoutes,
  exchangeRecordRoutes,
  exchangeStatisticsRoutes,
  userIntegralRoutes,
  pointRulesRoutes,

  orderRoutes,
  orderStatisticsRoutes,

  spreadStatisticsRoutes,

  couponRoutes,
  newsRoutes,
  newsCategoryRoutes,
  usersRoutes,
  preReservationsRoutes,
  reservationsRoutes,
  reservationsLatestRoutes,
  partnerRoutes,
  incomesRoutes,
  partnerIncomesRoutes,

  errorRoutes,
].map(i => i.routes))

function flatten(ary) {
  return ary.reduce((pre, cur) => {
    return pre.concat(Array.isArray(cur) ? flatten(cur) : cur);
  }, [])
}

export default {
  routeRegistry
}