import React from 'react'
import { injectIntl } from 'react-intl';
import { Button, Divider, Modal, message } from 'antd';
import TablePage from 'components/TablePage'
import TextMore from 'components/TextMore'
import SearchType from 'utils/searchType'
import service from './service'
import newsCategoryService from '../NewsCategory/service'
import Path from 'routes/path'

import './index.css';

/**
 * 商品管理页面
 */

class Product extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isReload: false,
      newsCategoryOptions: []
    }
  }

  componentDidMount() {
    newsCategoryService.getDatas().then(data => {
      this.setState({ newsCategoryOptions: data.data.map(i => ({ value: i.id, name: i.display_name })) })
    })
  }

  render() {
    const { intl } = this.props;
    const { formatMessage } = intl;
    const { isReload, newsCategoryOptions } = this.state;
    const options = [
      { value: 'ONLINE', name: formatMessage({ id: 'status_online' }) },
      { value: 'OFFLINE', name: formatMessage({ id: 'status_offline' }) },
      // { value: '', name: '初始' }
    ]

    const fields = [
      { name: 'key', desc: '', render: (text, record, index) => index + 1, width: 50 },
      { name: 'cover_image', desc: formatMessage({ id: 'cover' }), hide: true, render: (text) => <img style={{ height: 80, width: 80 }} src={text} alt='封面' />, showRender: true, cardCover: true, form: { type: 'upload' } },
      {
        name: 'title', desc: formatMessage({ id: 'title' }),
        width: '30%',
        render: (text, record) => {
          return (
            <>
              <a className='title' title={text} onClick={() => this.props.history.push(`/mkt/news_detail?id=${record.id}`)}>{text}</a>
              <br />
              <span className='sub-title' title={record.sub_title}>{record.sub_title}</span>
            </>
          )
        },
        query: true, cardTitle: true, form: { type: 'input' }
      },
      {
        name: 'article_category_id', desc: formatMessage({ id: 'news_category' }), query: true,
        type: SearchType.KEY_CUSTOM_OPTION, options: newsCategoryOptions, form: { type: 'select' }
      },
      { name: 'content_type', desc: formatMessage({ id: 'news_type' }), type: SearchType.KEY_CUSTOM_OPTION, options: [{ value: 'LINK', name: '链接' }, { value: 'RAW', name: '内容' }], form: { type: 'select' } },
      { name: 'status', desc: formatMessage({ id: 'status' }), query: true, showRender: true, type: SearchType.KEY_CUSTOM_TABS, options, render: (text) => options.find(i => i.value === text) && options.find(i => i.value === text).name },
      { name: 'create_time', desc: formatMessage({ id: 'create_time' }), type: SearchType.KEY_DATE_TIME },
      {
        name: 'action', desc: formatMessage({ id: 'action' }), width: 160, listActions: true, cardActions: true,
        render: (text, record) => {
          return [
            <a onClick={() => this.props.history.push(`/mkt/news_edit?id=${record.id}`)}>{formatMessage({ id: 'edit' })}</a>,
            <a onClick={() => this.handleData(record)}>{record.status === 'ONLINE' ? formatMessage({ id: 'offline' }) : formatMessage({ id: 'online' })}</a>
          ]
        }
      },
    ]

    const insertBtns = [<Button key='create' type='primary' icon='plus' onClick={() => this.props.history.push('/mkt/news_create')}>{formatMessage({ id: 'create' })}</Button>]
    return (
      <div>
        <div className="content-header">
          <h2>{formatMessage({ id: 'news_management' })}</h2>
        </div>
        <div className="content-body">
          <TablePage
            rowKey='id'
            name={formatMessage({ id: 'news' })}
            fields={fields}
            insertBtns={insertBtns}
            {...service}
            isReload={isReload}
            showDelete
          />
        </div>
      </div>
    )
  }

  preview = (record) => {
    if (window.previewWindow) {
      window.previewWindow.close()
    }
    window.previewWindow = window.open()
    window.previewWindow.document.write(this.buildPreviewHtml(record))
    window.previewWindow.document.close()

  }

  buildPreviewHtml(record) {

    return `
      <!Doctype html>
      <html>
        <head>
          <title>Preview Content</title>
          <style>
            html,body{
              height: 100%;
              margin: 0;
              padding: 0;
              overflow: auto;
              background-color: #f1f2f3;
            }
            .container{
              box-sizing: border-box;
              width: 1000px;
              max-width: 100%;
              min-height: 100%;
              margin: 0 auto;
              padding: 30px 20px;
              overflow: hidden;
              background-color: #fff;
              border-right: solid 1px #eee;
              border-left: solid 1px #eee;
            }
            .container img,
            .container audio,
            .container video{
              max-width: 100%;
              height: auto;
            }
            .container p{
              white-space: pre-wrap;
              min-height: 1em;
              line-height: 1.5;
            }
            .container pre{
              padding: 15px;
              background-color: #f1f1f1;
              border-radius: 5px;
            }
            .container blockquote{
              margin: 0;
              padding: 15px;
              background-color: #f1f1f1;
              border-left: 3px solid #d1d1d1;
            }
          </style>
        </head>
        <body>
          <div class="container">
            ${record.content}
          </div>
        </body>
      </html>
    `

  }

  handleData = ({ id, status }) => {
    const { intl } = this.props;
    const { formatMessage } = intl;
    Modal.confirm({
      title: formatMessage({ id: 'tip' }),
      content: formatMessage({ id: 'confirm_tip' }, { status: formatMessage({ id: status === 'ONLINE' ? 'offline' : 'online' }) }),
      onOk: () => {
        service[status === 'ONLINE' ? 'offlineData' : 'onlineData'](id).then(data => {
          message.success(`${formatMessage({ id: status === 'ONLINE' ? 'offline' : 'online' })}${formatMessage({ id: 'success' })}`)
          this.setState({
            isReload: true
          }, () => {
            this.setState({ isReload: false })
          })
        })
      },
      onCancel: () => { }
    })
  }

}

export default injectIntl(Product)