import axios from 'axios'
import config from 'config'

const baseURL = config.resource_url

/**
 * 获取数据列表
 * @param {*} params 
 */

const getDatas = (params) => {
    return axios.get('/v1/sys/point_products/exchanges/count', {
        params 
    })
}

/**
 * 创建数据
 * @param {*} body 
 */

const createData = (body) => {
    return axios.post('/v1/clusters', body, { baseURL })
}

/**
 * 获取数据详情
 * @param {*} id 
 */

const getDataDetail = (id) => {
    return axios.get(`/v1/clusters/${id}`, { baseURL })
}

/**
 * 更新数据
 * @param {*} id 
 * @param {*} body 
 */

const updateData = (id, body) => {
    return axios.put(`/v1/clusters/${id}`, body, { baseURL })
}

/**
 * 删除数据
 * @param {*} id 
 */

const deleteData = (id) => {
    return axios.delete(`/v1/clusters/${id}`, { baseURL })
}

export default {
    getDatas,
    createData,
    getDataDetail,
    updateData,
    deleteData
}