import React from 'react'
import { injectIntl } from 'react-intl';
import { Button, Divider, Modal, message } from 'antd';
import TablePage from 'components/TablePage'
import TextMore from 'components/TextMore'
import SearchType from 'utils/searchType'
import service from './service'
import productCategoryService from '../ProductCategory/service'
import Path from 'routes/path'

/**
 * 商品管理页面
 */

class Product extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isReload: false,
      productCategoryOptions: []
    }
  }

  componentDidMount() {
    productCategoryService.getDatas().then(data => {
      this.setState({ productCategoryOptions: data.data.map(i => ({ value: i.id, name: i.name })) })
    })
  }

  render() {
    const { intl } = this.props;
    const { formatMessage } = intl;
    const { isReload, productCategoryOptions } = this.state;
    const options = [
      { value: 'ONLINE', name: formatMessage({ id: 'status_online' }) },
      { value: 'OFFLINE', name: formatMessage({ id: 'status_offline' }) },
      // { value: '', name: '初始' }
    ]

    const fields = [
      { name: 'key', desc: '', render: (text, record, index) => index + 1, width: 50 },
      { name: 'cover_image', desc: formatMessage({ id: 'cover' }), hide: true, render: (text) => <img style={{ height: 80, width: 80 }} src={text} alt='封面' />, showRender: true, cardCover: true, form: { type: 'upload' } },
      { name: 'title', desc: formatMessage({ id: 'name' }), showDetail: true, query: true, cardTitle: true, form: { type: 'input' } },
      { name: 'sub_title', desc: formatMessage({ id: 'sub_title' }), form: { type: 'input' } },
      { name: 'human_display_price', desc: formatMessage({ id: 'display_price' }), render: (text) => `￥${text || 0}`, showRender: true, cardDeleteContent: true, form: { type: 'inputNumber' } },
      { name: 'human_price', desc: formatMessage({ id: 'price' }), render: (text) => `￥${text || 0}`, showRender: true, cardContent: true, form: { type: 'inputNumber' } },
      { name: 'product_category_id', desc: formatMessage({ id: 'category' }), query: true, hide: true, hideInDetail: true, type: SearchType.KEY_CUSTOM_OPTION, options: productCategoryOptions, form: { type: 'select' } },
      {
        name: 'images', desc: formatMessage({ id: 'images' }), hide: true, render: (text) => {
          return <>
            {
              text.map((i, d) => {
                return <img key={d} style={{ height: 80, width: 80 }} src={i} alt={formatMessage({ id: 'images' })} />
              })
            }
          </>
        }, showRender: true, form: { type: 'uploadImages' }
      },
      { name: 'description', desc: formatMessage({ id: 'description' }), render: (text) => <TextMore text={text} maxLength={10} />, form: { type: 'textarea' } },
      { name: 'status', desc: formatMessage({ id: 'status' }), query: true, showRender: true, type: SearchType.KEY_CUSTOM_TABS, options, render: (text) => options.find(i => i.value === text) && options.find(i => i.value === text).name },
      { name: 'create_time', desc: formatMessage({ id: 'create_time' }), type: SearchType.KEY_DATE_TIME },
      {
        name: 'action', desc: formatMessage({ id: 'action' }), width: 250, listActions: true, cardActions: true,
        render: (text, record) => {
          return [
            <a onClick={() => this.props.history.push(`/mkt/product_edit?id=${record.id}`)}>{formatMessage({ id: 'edit' })}</a>,
            <a onClick={() => this.handleProduct(record)}>{record.status === 'ONLINE' ? formatMessage({ id: 'offline' }) : formatMessage({ id: 'online' })}</a>
          ]
        }
      },
    ]

    const insertBtns = [<Button key='create' type='primary' icon='plus' onClick={() => this.props.history.push('/mkt/product_create')}>{formatMessage({ id: 'create' })}</Button>]
    return (
      <div>
        <div className="content-header">
          <h2>{formatMessage({ id: 'product_list' })}</h2>
        </div>
        <div className="content-body">
          <TablePage
            rowKey='id'
            name={formatMessage({ id: 'product' })}
            fields={fields}
            insertBtns={insertBtns}
            {...service}
            // loadData={service.getDatas}
            // delData={service.deleteData}
            isReload={isReload}
            showTabs
            // showCreate
            // showEdit
            showDelete
            // showTabs
            tabsStyle={{
              marginTop: '-165px',
              marginBottom: '125px'
            }}
          />
        </div>
      </div>
    )
  }

  handleProduct = ({ id, status }) => {
    const { intl } = this.props;
    const { formatMessage } = intl;
    Modal.confirm({
      title: formatMessage({ id: 'tip' }),
      content: formatMessage({ id: 'confirm_tip' }, { status: formatMessage({ id: status === 'ONLINE' ? 'offline' : 'online' }) }),
      onOk: () => {
        service[status === 'ONLINE' ? 'offlineData' : 'onlineData'](id).then(data => {
          message.success(`${formatMessage({ id: status === 'ONLINE' ? 'offline' : 'online' })}${formatMessage({ id: 'success' })}`)
          this.setState({
            isReload: true
          }, () => {
            this.setState({ isReload: false })
          })
        })
      },
      onCancel: () => { }
    })
  }

}

export default injectIntl(Product)