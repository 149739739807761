
import Index from './index'
import Path from 'routes/path'

const routes = [
    {
        id: 'partner_incomes',
        name: 'partner_incomes',
        component: Index,
        path: Path.pathname['PARTNER_INCOMES'],
        key: 'partner_incomes',
    },
]

export default {
    routes
}