
import Integral from './index'
import Path from 'routes/path'

const routes = [
    {
        id: 'integral',
        name: 'integral',
        component: Integral,
        path: Path.pathname['POINT_STATISTICS'],
        key: 'exchange_statistics',
    },
]

export default {
    routes
}