import React, { useState, useEffect } from 'react';
import { Table, Modal, Button, Popconfirm, message } from 'antd';
import service from '../../service'
import moment from 'moment'

const IncomesList = (props) => {
  const { record, intl } = props
  const { formatMessage } = intl
  const { id } = record || {}
  const [visible, setVisible] = useState(false)
  const [dataSource, setDataSource] = useState([])

  const fetchData = async () => {
    const res = await service.getIncomes(id)
    setDataSource(res.data.filter(i => !i.canceled).map((i, k) => ({ ...i, key: k })))
  }

  const handleShow = () => {
    setVisible(true)
    fetchData(id)
  }

  const handleOk = () => {

  }

  const handleCancel = () => {
    setVisible(false)

  }

  const handleDelete = (record) => {
    if (!record.id) return
    service.deleteCharges(record.id).then(data => {
      message.success('消费删除成功')
      fetchData(id)
    }).catch(err => {
      message.error('消费删除失败')
    })
  }

  const columns = [
    { title: '消费', key: 'fee', dataIndex: 'fee' },
    // { title: '记录员', key: 'recorder.nick_name', dataIndex: 'recorder.nick_name' },
    { title: '记录时间', key: 'create_time', dataIndex: 'create_time', render: (text) => moment(text).format('YYYY-MM-DD HH:mm:ss') },
    {
      title: '操作', key: 'action', dataIndex: 'action', render: (text, record) => {
        return [
          <Popconfirm
            key='delete'
            title={formatMessage({ id: 'confirm_delete' })}
            onConfirm={() => handleDelete(record)}
            // onCancel={cancel}
            okText={formatMessage({ id: 'ok' })}
            cancelText={formatMessage({ id: 'cancel' })}
          >
            <a>{formatMessage({ id: 'delete' })}</a>
          </Popconfirm>
        ]
      }
    }
  ]

  return (
    <>
      <a onClick={() => handleShow()}>最近消费记录</a>
      <Modal
        title='最近消费记录'
        visible={visible}
        onOk={handleOk}
        footer={[
          <Button key='close' onClick={handleCancel}>关闭</Button>
        ]}
        onCancel={handleCancel}
      >
        <Table size='small' dataSource={dataSource} columns={columns} />
      </Modal>
    </>
  )
}

export default IncomesList;