
import ProductList from './index'
import Create from './create'
import Path from 'routes/path'

const routes = [
    {
        id: 'product_list',
        name: 'product_list',
        component: ProductList,
        path: Path.pathname['PRODUCT_LIST'],
        key: 'product_list',
    },
    {
        id: 'product_create',
        name: 'product_create',
        component: Create,
        path: Path.pathname['PRODUCT_CREATE'],
        key: 'product_create',
        parentKey: 'product_list'
    },
    {
        id: 'product_edit',
        name: 'product_edit',
        component: Create,
        path: Path.pathname['PRODUCT_EDIT'],
        key: 'product_edit',
        parentKey: 'product_list'
    },
]

export default {
    routes
}