import axios from 'axios'

const login = (body) => {
  return axios.post('/v1/sys/auth', body)
}

const check = () => {
  return axios.post('/v1/auth/qrcode/check')
}

const getUserInfo = () => {
  return axios.get('/v1/user/profile')
}

export default {
  login,
  check,
  getUserInfo
}