
import PointList from './index'
import Path from 'routes/path'

const routes = [
    {
        id: 'point_rules',
        name: 'point_rules',
        component: PointList,
        path: Path.pathname['POINT_RULES'],
        key: 'point_rules',
    },
]

export default {
    routes
}