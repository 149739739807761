import React from 'react'
import { injectIntl } from 'react-intl';
import { Button, Divider, Modal, message, Form, Input } from 'antd';
import TablePage from 'components/TablePage'
import SearchType from 'utils/searchType'
import service from './service'
import Path from 'routes/path'

/**
 * 会员管理页面
 */

class CreateForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;

    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Item label='名称'>
          {getFieldDecorator('vip', {
            rules: [{ required: true, message: 'Please input your username!' }],
          })(
            <Input
            />,
          )}
        </Form.Item>
        <Form.Item label='价格'>
          {getFieldDecorator('price', {
            rules: [{ required: true, message: 'Please input your username!' }],
          })(
            <Input
            />,
          )}
        </Form.Item>
      </Form>
    )
  }

}

const WrappedForm = Form.create({ name: 'form' })(CreateForm);

export default injectIntl(WrappedForm)