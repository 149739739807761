import React from 'react'
import { injectIntl } from 'react-intl';
import { Menu, Icon } from 'antd'
import { Link } from 'react-router-dom'
import Path from 'routes/path'
import utils from 'utils/util'

const SubMenu = Menu.SubMenu

class Nav extends React.Component {

  getPath = (item) => {
    const { children = {} } = this.props
    return (children.find(itm => itm.key === item.key) && children.find(itm => itm.key === item.key).props.path) || Path.getPathname(this.props, Path.pathname['HOME'])
  }

  getDefaultSelectedKeys = () => {
    const { children = [], location = {} } = this.props
    const selectPathObj = children.find(i => i.props.path === location.pathname) || {}
    return (selectPathObj && ((selectPathObj.props && selectPathObj.props.parentKey) || selectPathObj.key)) || 'home_index'
  }

  getDefaultOpenKeys = () => {
    const menus = this.menus()
    let selectedKeys = ''
    menus.forEach(i => {
      if (i.children && i.children.find(j => j.key === this.getDefaultSelectedKeys())) {
        selectedKeys = i.key
      }
    })
    return selectedKeys
  }

  render() {
    return (
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={[this.getDefaultSelectedKeys()]}
        defaultOpenKeys={[this.getDefaultOpenKeys()]}
        // openKeys={[this.getDefaultOpenKeys()]}
        selectedKeys={[this.getDefaultSelectedKeys()]}
      >
        {this.menus().filter(i => i.permission ? utils.hasPermission(i.permission) : true).map((item, index) => {
          if (item.children) {
            return (
              <SubMenu key={item.key} title={<span><Icon type={item.icon || "setting"} /><span title={item.name}>{item.name}</span></span>}>
                {
                  item.children.filter(i => i.permission ? utils.hasPermission(i.permission) : true).map((itm, idx) => {
                    return <Menu.Item key={itm.key}>
                      {
                        itm.path
                          ? <a href={itm.path} title={itm.name} target='_blank' rel="noopener noreferrer">{itm.name}</a>
                          : <Link to={this.getPath(itm)} title={itm.name}>{itm.name}</Link>
                      }
                    </Menu.Item>
                  })
                }
              </SubMenu>
            )
          }
          return (
            <Menu.Item key={item.key}>
              <Link to={this.getPath(item)} title={item.name}>
                <Icon type={item.icon || 'home'} />
                <span>{item.name}</span>
              </Link>
            </Menu.Item>
          )
        })}
      </Menu>
    )
  }

  menus = () => {
    const { intl } = this.props;
    const { formatMessage } = intl;
    return [
      { key: 'home_index', name: formatMessage({ id: 'home' }) },
      // {
      //   key: 'account', name: formatMessage({ id: 'account' }), icon: 'appstore', children: [
      //     { key: 'user_index', name: formatMessage({ id: 'account_edit' }) }
      //   ]
      // },
      {
        key: 'user', name: formatMessage({ id: 'user_management' }), icon: 'appstore', children: [
          { key: 'member_plan', name: formatMessage({ id: 'member_plan' }) },
          // { key: 'member_benefits', name: formatMessage({ id: 'member_benefits' }) },
          { key: 'member_list', name: formatMessage({ id: 'member_list' }) },
          // { key: 'pre_service', name: formatMessage({ id: 'pre_service' }) },
          { key: 'member_statistics', name: formatMessage({ id: 'member_statistics' }) },
        ]
      },
      {
        key: 'product', name: formatMessage({ id: 'product_management' }), icon: 'appstore', children: [
          { key: 'product_category', name: formatMessage({ id: 'product_category' }) },
          { key: 'product_list', name: formatMessage({ id: 'product_list' }) },
        ]
      },
      {
        key: 'integral', name: formatMessage({ id: 'point_management' }), icon: 'appstore', children: [
          { key: 'point_list', name: formatMessage({ id: 'point_list' }) },
          { key: 'point_record', name: formatMessage({ id: 'point_record' }) },
          { key: 'exchange_statistics', name: formatMessage({ id: 'exchange_statistics' }) },
          { key: 'user_point', name: formatMessage({ id: 'user_point' }) },
          { key: 'point_rules', name: formatMessage({ id: 'point_rules' }) },
        ]
      },
      {
        key: 'order', name: formatMessage({ id: 'order_management' }), icon: 'appstore', children: [
          { key: 'order', name: formatMessage({ id: 'order_record' }) },
          { key: 'order_statistics', name: formatMessage({ id: 'order_statistics' }) },
        ]
      },
      {
        key: 'news', name: formatMessage({ id: 'news_management' }), icon: 'appstore', children: [
          { key: 'news_list', name: formatMessage({ id: 'news_management' }) },
          { key: 'news_category', name: formatMessage({ id: 'news_category' }) },
        ]
      },
      {
        key: 'spread', name: formatMessage({ id: 'spread_management' }), icon: 'appstore', children: [
          { key: 'spread_statistics', name: formatMessage({ id: 'spread_statistics' }) }
        ]
      },
      {
        key: 'user_list', name: formatMessage({ id: 'user_list' }), icon: 'appstore', children: [
          { key: 'user_list', name: formatMessage({ id: 'user_list' }) }
        ]
      },
      {
        key: 'reservation', name: "就诊消费管理", icon: 'appstore', children: [
          { key: 'reservation_latest', name: formatMessage({ id: 'reservaction_today' }) },
          { key: 'reservation', name: "就诊预约" },
          { key: 'pre_reservation', name: "患者登记" },
          { key: 'incomes', name: "消费记录" },
        ]
      },
      {
        key: 'partner', name: formatMessage({ id: 'partner_management' }), icon: 'appstore', children: [
          { key: 'partner', name: formatMessage({ id: 'partner_list' }) },
          { key: 'partner_incomes', name: formatMessage({ id: 'partner_incomes' }) },
        ]
      },
      // {
      //   key: 'coupon', name: '优惠券', icon: 'appstore', children: [
      //     { key: 'coupon', name: '优惠券管理' }
      //   ]
      // },
    ]
  }
}

export default injectIntl(Nav)
