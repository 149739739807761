import React from 'react';
import { injectIntl } from 'react-intl';
import { Layout, Breadcrumb, Divider, Icon } from 'antd';
import { Link } from 'react-router-dom'
import './index.css'
import Nav from './nav'
import LocaleSelect from './components/LocaleSelect'
import LocalStorageUtil from 'utils/localStorageUtil'
import logo from 'theme/img/logo.png'
import Path from 'routes/path'
import QuickLink from './components/QuickLink'
import utils from 'utils/util'

const { Header, Content, Footer, Sider } = Layout;

class FrameMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      isChecked: false
    };
  }

  componentWillMount() {
    const { routeRegistry, location, match } = this.props
    if (!routeRegistry.find(i => i.path === location.pathname.replace(match.path, ''))) {
      this.props.history.push(Path.getPathname(this.props, Path.pathname['Error404']))
    }
  }

  componentDidMount() {
  }

  componentWillReceiveProps(nextProps) {
    const { routeRegistry, location, match } = nextProps
    if (location.pathname !== this.props.location.pathname) {
      if (!routeRegistry.find(i => i.path === location.pathname.replace(match.path, ''))) {
        nextProps.history.push(Path.getPathname(nextProps, Path.pathname['Error404']))
      }
    }
  }

  onCollapse = collapsed => {
    this.setState({ collapsed });
  };

  handleLogout = (e) => {
    e.preventDefault();
    ['auth', 'userInfo', 'privileges'].forEach(i => {
      new LocalStorageUtil().clearStorage(i)
    })
    new LocalStorageUtil().clearAllCookie()
    // const url = `${utils.getDomain('ucCenter') || ''}/logout?return_url=${encodeURIComponent(window.location.href)}`
    window.location.href = '#/login'
  }

  render() {
    const { intl, location, websiteInfo } = this.props;
    const { formatMessage } = intl;
    const { copyright, client_name, client_email } = websiteInfo || {}
    const { isChecked } = this.state;
    let userInfo = {}
    try {
      userInfo = JSON.parse(new LocalStorageUtil().getUserInfo())
    } catch (e) {

    }
    const { user_name, nick_name, real_name } = userInfo || {}
    const username = user_name || nick_name || real_name || formatMessage({ id: 'manager' })
    return (
      <div className="main">
        <Sider
          // collapsible
          breakpoint='xl'
          className="sidebar"
          collapsed={this.state.collapsed}
          onCollapse={this.onCollapse}>
          <div className="logo">
            {/* <img className="logo-img" src={logo} alt="" /> */}
            <span>HDK</span>
          </div>
          <Nav {...this.props} />
        </Sider>
        <div className="content-wrap">
          <div className="main-content-wrap">
            <Header className="header">
              <div className="header-right">
                {/* <div style={{ display: 'inline-block' }}>
                  <QuickLink {...this.props} />
                </div> */}
                {/* <LocaleSelect /> */}
                {/* {formatMessage({ id: 'hello' })} */}
                <a>{username}</a>
                <Divider key='divider' type="vertical" />
                <a onClick={this.handleLogout}>{formatMessage({ id: 'logout' })}</a>
              </div>
              <h1 className='header-title'>{formatMessage({ id: 'app_title' })}</h1>
            </Header>
            <Content className="main-content">
              {/* <Breadcrumb className="breadcrumb">
                <Breadcrumb.Item>{formatMessage({ id: 'home' })}</Breadcrumb.Item>
              </Breadcrumb> */}
              <div className={`content ${location.pathname.indexOf('/mkt/home') !== -1 ? 'no-back' : ''}`} id="content">{this.props.children}</div>
            </Content>
            <div className="footer">
              <p>{copyright}</p>
              {
                client_name || client_email
                  ? <p>
                    <span>{formatMessage({ id: 'licensed_to' })}</span>
                    <span>
                      {client_name}
                      {client_email ? <span>（{client_email}）</span> : null}
                    </span>
                  </p>
                  : null
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default injectIntl(FrameMenu)
