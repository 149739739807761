import React from 'react'
import { injectIntl } from 'react-intl';
import { Button, Divider, Modal, message, Typography } from 'antd';
import TablePage from 'components/TablePage'
import SearchType from 'utils/searchType'
import service from './service'
import Path from 'routes/path'
import CreateForm from './form'
import MemberPlanProfits from './components/MemberPlanProfits'
const { Paragraph } = Typography;

/**
 * 会员管理页面
 */

class Monitor extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isReload: false,
      visible: false
    }
  }

  onChange = (value) => {
  }

  render() {
    const { intl } = this.props;
    const { formatMessage } = intl;
    const { isReload } = this.state;

    const fields = [
      { name: 'key', desc: '', render: (text, record, index) => index + 1, width: 50 },
      { name: 'name', desc: formatMessage({ id: 'name' }), showDetail: true, query: true, form: { type: 'input' } },
      { name: 'human_price', desc: formatMessage({ id: 'price' }), form: { type: 'inputNumber' } },
      { name: 'human_display_price', desc: formatMessage({ id: 'display_price' }), form: { type: 'inputNumber' } },
      // { name: 'weight', desc: formatMessage({ id: 'weight' }), hide: true, hideInDetail: true, form: { type: 'inputNumber' } },
      {
        name: 'profits', desc: formatMessage({ id: 'profits' }), width: '30%', showRender: true, render: (text, record) => {
          return <MemberPlanProfits profits={text} record={record} />
        }
      },
      // { name: 'create_time', desc: '创建时间', type: SearchType.KEY_DATE_TIME, sorter: true },
      {
        name: 'action', desc: formatMessage({ id: 'action' }), width: 250, listActions: true,
        render: (text, record) => {
          return [
            <a key='list' href='#/mkt/member_list'>{formatMessage({ id: 'member_list' })}</a>
          ]
        }
      },
    ]

    const insertBtns = []
    return (
      <div>
        <div className="content-header">
          <h2>{formatMessage({ id: 'member_plan' })}</h2>
        </div>
        <div className="content-body">
          <TablePage
            rowKey='id'
            name={formatMessage({ id: 'member_plan' })}
            fields={fields}
            insertBtns={insertBtns}
            {...service}
            // loadData={service.getDatas}
            isReload={isReload}
            showCreate
            showEdit
            showDelete
            module='member_plan'
          />
        </div>
        {this.renderModal()}
      </div>
    )
  }

  renderModal = () => {
    return (
      <Modal
        title="弹框"
        visible={this.state.visible}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
      >
        <CreateForm {...this.props} />
      </Modal>
    )
  }

  handleOk = () => {
    this.setState({ visible: false })
  }

  handleCancel = () => {
    this.setState({ visible: false })
  }

}

export default injectIntl(Monitor)