import React, { useState, useEffect } from 'react';
import { Form, Input, Select, InputNumber } from 'antd';
import productService from 'modules/ProductList/service';

const { Option } = Select;

const ProfitForm = (props) => {
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };
  const { profits = [], record = {}, form } = props;
  const { getFieldDecorator, setFieldsValue } = form;
  const [datas, setDatas] = useState(profits)
  const [products, setProducts] = useState([])

  useEffect(() => {
    async function fetchData() {
      const data = await productService.getDatas()
      setProducts(data.data.items || [])
    }
    fetchData()
  }, [])

  const onChange = (id) => {
    setFieldsValue({
      'product_name': products.find(i => i.id === id).title
    })
  }

  return (
    <Form {...formItemLayout}>
      <Form.Item label='产品'>
        {getFieldDecorator('product_id', {
          rules: [{ required: true, message: '请选择产品' }],
        })(
          <Select
            onChange={onChange}
          // getPopupContainer={() => document.getElementById('form_modal')}
          >
            {products.map((i, d) => <Option key={d} value={i.id}>{i.title}</Option>)}
          </Select>
        )}
      </Form.Item>
      <Form.Item label='次数'>
        {getFieldDecorator('amount', {
          rules: [{ required: true, message: '请输入次数' }],
        })(<InputNumber min={1} />)}
      </Form.Item>
      <Form.Item style={{ display: 'none' }} label='产品名称'>
        {getFieldDecorator('product_name', {
          // rules: [{ required: true, message: '请输入次数' }],
        })(<Input />)}
      </Form.Item>
    </Form>
  )
}

export default Form.create({ name: 'profit_form' })(ProfitForm)